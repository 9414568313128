import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, Stack, Tabs, Tab, Link, Typography, Divider, TextField, Button, Modal } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { toast } from 'react-toastify';

import ColumnTree from '../components/ColumnTree';
import SetBudget from '../components/SetBudget';
import CocoField from 'components/CocoField';
import colorPalette from 'theme/colorPalette';
import iconIncome from '../assets/icons/icon_income.svg';
import iconExpense from '../assets/icons/icon_expense.svg';
import iconMerchant from '../assets/icons/icon_merchant.svg';
import { TreeDataItem } from '../interfaces/category';
import {
  getAllCategoriesAsTree,
  handleUpdateCategory,
  moveToCategory,
  deleteCategory,
  addNewCategory
} from '../api-routers/CategoriesRouter';

interface TabInfo {
  label: string;
  icon: string;
  categoryType: string;
}

const tabInfo: TabInfo[] = [
  { label: 'Incomes', icon: iconIncome, categoryType: 'income' },
  { label: 'Expense categories', icon: iconExpense, categoryType: 'expense' },
  { label: 'Merchants', icon: iconMerchant, categoryType: 'merchant' },
];

const BudgetingPage: React.FC = () => {
  const [value, setValue] = useState<number>(0);
  const [categories, setCategories] = useState<TreeDataItem[]>([]); // Updated type annotation
  const [activeCategoryCardId, setActiveCategoryCardId] = useState<string | null>(null);
  const [activeSubCategoryCardId, setActiveSubCategoryCardId] = useState<string | null>(null);
  const [activeItemCardId, setActiveItemCardId] = useState<string | null>(null);
  const [subCategories, setSubCategories] = useState<TreeDataItem[]>([])
  const [items, setItems] = useState<TreeDataItem[]>([]);
  const [lastSelectedNode, setLastSelectedNode] = useState<TreeDataItem | null>(null);
  const [copiedNode, setCopiedNode] = useState<TreeDataItem | null>(null); // Updated type annotation
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string | null>(null);

  const allowedMoveDestinationTypes = ['expense-section', 'expense-shelf', 'income-section', 'income-shelf', 'merchant-section', 'merchant-shelf'];

  const handleMoveToCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedCategoryId(event.target.value as string);
  };

  const handleMoveToCategory = async () => {
    try {
      if (copiedNode && lastSelectedNode) {
        await moveToCategory(copiedNode?._id, lastSelectedNode?._id);
        fetchCategories();
        setCopiedNode(null);
      }
    } catch (error) {
      console.error("Error moving node to category:", error);
      // Handle the error as needed
    }
  };
  const handleAddNewCategory = async () => {
    try {
      await addNewCategory(newCategoryName, parentCategoryId, '');
      fetchCategories(); // refresh the categories after adding
      setNewCategoryName(''); // reset the input
      setOpenModal(false); // close the modal
    } catch (error) {
      console.error('Error adding new category:', error);
    }
  };

  const handleMainTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setSubCategories([]);
    setItems([]);
    setActiveCategoryCardId(null);
    setActiveSubCategoryCardId(null);
    setActiveItemCardId(null);
    setLastSelectedNode(null); // Reset the lastSelectedNode when switching tabs
  };

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setSubCategories([]);
    setItems([]);
    setActiveCategoryCardId(null);
    setActiveSubCategoryCardId(null);
    setActiveItemCardId(null);
    setLastSelectedNode(null); // Reset the lastSelectedNode when switching tabs
  };
  const fetchCategories = async () => {
    try {
      const result = await getAllCategoriesAsTree();
      setCategories(result);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleBudgetUpdateSuccess = () => {
    fetchCategories();
  };
  const handleSetCopiedNode = async (lastSelectedNodeElement: TreeDataItem) => {
    if (lastSelectedNodeElement?._id && lastSelectedNodeElement?._id !== '0') {
      setCopiedNode(lastSelectedNodeElement);
    }
    else
      setCopiedNode(null);
  };

  const handleDeleteCategory = async () => {
    if (lastSelectedNode && lastSelectedNode._id) {
      const categoryId = lastSelectedNode._id;

      try {
        await deleteCategory(categoryId); // Assuming you have the deleteCategory function implemented

        // After deleting the category, refresh the categories
        const updatedCategories = await getAllCategoriesAsTree();
        setCategories(updatedCategories);

        // Also reset the active node details
        setLastSelectedNode(null);
      } catch (error) {
        console.log('handleDeleteCategory error:', error);
        // Handle the error as needed
      }
    }
  };

  const handleAddButtonPress = (type: string) => {
    let parentCategoryId: string | null = null;

    switch (type) {
      case 'Category':
        setModalContent("Add new Category");
        break;
      case 'Sub-Category':
        parentCategoryId = activeCategoryCardId?.toString() || null; // Convert to string
        setModalContent("Add new Sub-Category");
        break;
      case 'Item':
        parentCategoryId = activeSubCategoryCardId?.toString() || null; // Convert to string
        setModalContent("Add new Item");
        break;
      default:
        setModalContent('');
    }

    setOpenModal(true);
    setParentCategoryId(parentCategoryId);
  };

  const editCategoryField = async (fieldType: string, category: TreeDataItem, newValue: any) => {
    let existingValue;

    // Determine the existing value based on the fieldType
    switch (fieldType) {
      case 'name':
        existingValue = `${category.name}`;
        break;
      case 'budgetAmount':
        existingValue = category.budgetAmount ? `${category.budgetAmount}` : "0";
        break;
    }

    // Check if newValue is different from the existing value
    if (existingValue !== newValue) {
      try {
        await handleUpdateCategory(category._id, fieldType, newValue);
        toast.success(`Updated ${fieldType}`);
      } catch (error) {
        toast.error(`Error updating ${fieldType}`);
      }
    } else {
      console.log(`${fieldType} is unchanged.`);
    }

  };

  return (
    <Box padding={2} sx={{ display: 'flex', height: '100vh', background: colorPalette.white }}>
      <Stack>
        <Stack direction="row" spacing={2} sx={{ marginBottom: '24px' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Budgeting Tabs">
            {tabInfo.map((tab, index) => (
              <Tab key={index} label={tab.label} icon={<img src={tab.icon} alt={tab.label} />} iconPosition="start" />
            ))}
          </Tabs>
        </Stack>
        <Stack direction={"row"} gap={2} sx={{ height: '100%' }}>
          <ColumnTree
            categoryType={tabInfo[value].categoryType}
            categoryDepth='Section'
            data={categories?.[value]?.children ?? []}
            onAddButtonClick={() => handleAddButtonPress('Section')}
            activeNode={activeCategoryCardId}
            onNodeClick={(id) => {
              if (id) {
                setActiveCategoryCardId(id);
                const selectedCategory = categories[value]?.children?.find((cat: any) => cat._id === id);
                setSubCategories(selectedCategory?.children || []);
                setLastSelectedNode(selectedCategory ?? null);
                setActiveSubCategoryCardId(null);
                setItems([]);
              }
            }}
          />
          <ColumnTree
            categoryType={tabInfo[value].categoryType}
            categoryDepth='Shelf'
            onAddButtonClick={() => handleAddButtonPress('Shelf')}
            data={subCategories}
            activeNode={activeSubCategoryCardId !== null ? activeSubCategoryCardId.toString() : null}
            onNodeClick={(id) => {
              setActiveSubCategoryCardId(id);
              const selectedItem = subCategories.find(subCat => subCat._id === id);

              setItems(selectedItem?.children || []);
              setLastSelectedNode(selectedItem ?? null);
              setActiveItemCardId(null);
            }}
          />
          <ColumnTree
            categoryType={tabInfo[value].categoryType}
            onAddButtonClick={() => handleAddButtonPress('Box')}
            categoryDepth='Box'
            data={items}
            activeNode={activeItemCardId !== null ? activeItemCardId.toString() : null}
            onNodeClick={(id) => {
              setActiveItemCardId(id);
              const selectedItem = items.find(item => item._id === id);

              // Ensure selectedItem is of the correct type (TreeDataItem)
              if (selectedItem) {
                setLastSelectedNode(selectedItem);
              } else {
                // If selectedItem is undefined, setLastSelectedNode to null or handle it as needed
                setLastSelectedNode(null);
              }
            }}
          />
        </Stack>
      </Stack>
      {lastSelectedNode && (
        <Modal
          sx={{
            width: '24rem', backgroundColor: colorPalette.white,
            align: "right",
            padding: "24px",
            left: 'unset',
            right: '0',
            borderLeft: `0.15rem solid ${colorPalette.gray_200}`
          }}
          open={true}
          hideBackdrop
        >
          <Stack
            id="RightPanel"
            direction="column"
            spacing={4}
            sx={{ maxWidth: '100%' }}
          >
            {!copiedNode ? (<>
              <Stack direction={"column"} gap={2}>
                <Stack direction="column" gap={0.5}>
                  <Typography variant="h4">Change name</Typography>
                  <Typography variant="body2">
                    Give a unique name to your category. This will be the name of the category on your budget and transactions.
                  </Typography>
                </Stack>
                <CocoField
                  variant='h3'
                  placeholder={'Add name'}
                  value={lastSelectedNode?.name}
                  handleSave={(newValue) => editCategoryField('name', lastSelectedNode, newValue)}
                />
              </Stack>
              <Divider></Divider>
            </>) : null}

            {
              lastSelectedNode?._id && !copiedNode &&
              (lastSelectedNode.type === 'income-section' ||
                lastSelectedNode.type === 'expense-section' ||
                lastSelectedNode.type === 'merchant-section') &&
              (
                <>
                  <SetBudget
                    name={lastSelectedNode?.name}
                    categoryId={lastSelectedNode?._id}
                    currentBudget={lastSelectedNode?.budgetAmount}
                    onBudgetUpdateSuccess={handleBudgetUpdateSuccess}
                  />
                  <Divider></Divider>
                </>
              )
            }

            {lastSelectedNode?.type !== 'income-main' && lastSelectedNode?.type !== 'expense-main' && lastSelectedNode?.type !== 'merchant-main' && (
              <Stack id="move-section" direction="column" gap={4}>
                <Stack direction="column" gap={0.5}>
                  <Typography variant="h4">Move</Typography>
                  <Typography variant="body2">
                    Move feature will help you to organise your Categorization structure. You can move a selected category between sections, shelves given that the category does not have any children.
                  </Typography>
                </Stack>

                {copiedNode ? (
                  <Stack direction={'column'} gap={2}>
                    <Stack direction={'column'} gap={1}>
                      <Typography variant='h6'>Moving category</Typography>
                      <Stack p={1}
                        border={1}
                        borderColor={colorPalette.gray_400}
                        borderRadius={'4px'}
                        sx={{ background: colorPalette.gray_100 }}>
                        <Typography variant='body1'>{copiedNode?.name}</Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      justifyContent={'left'}
                      direction={'column'} gap={1}>
                      <Typography variant='h6'>To</Typography>
                      {(lastSelectedNode._id !== copiedNode._id && allowedMoveDestinationTypes.includes(lastSelectedNode.type)) ? (<>
                        <Stack p={1}
                          border={1}
                          borderColor={colorPalette.gray_400}
                          borderRadius={'4px'}
                          sx={{ background: colorPalette.gray_100 }}>
                          <Typography variant='body1'>{lastSelectedNode?.name}</Typography>
                        </Stack>
                      </>) : (<>
                        <Stack p={1}
                          border={1}
                          borderColor={colorPalette.gray_400}
                          borderRadius={'4px'}
                          sx={{ background: colorPalette.gray_100 }}>
                          <Typography variant='body2' color={colorPalette.gray_600}>Select a Section or Shelf to Move</Typography>
                        </Stack></>)}

                      <Stack direction={'row'} gap={2} mt={2}>
                        <Button variant="contained"
                          disabled={!(lastSelectedNode._id !== copiedNode._id && allowedMoveDestinationTypes.includes(lastSelectedNode.type))}
                          sx={{ alignSelf: 'flex-start' }}
                          onClick={() => handleMoveToCategory()}>
                          Move
                        </Button>
                        <Button variant="outlined"
                          sx={{ alignSelf: 'flex-start' }}
                          onClick={() => setCopiedNode(null)}>
                          Cancel
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                ) : <>
                  {lastSelectedNode.children?.length === 0 ? (<>
                    <Link
                      href="#"
                      underline="none"
                      onClick={() => handleSetCopiedNode(lastSelectedNode)}
                      style={{ width: '100%', display: 'flex', alignItems: 'left' }}
                    >
                      <Typography variant="body1" color={colorPalette.Blue_A400}>Move {lastSelectedNode.name}</Typography>
                      <ArrowForwardIcon sx={{ color: colorPalette.Blue_A400, ml: '8px' }} />
                    </Link>
                  </>) : (<>
                    <Typography variant='body2' color={colorPalette.Amber_800}>You cannot move this category as it has children</Typography>
                  </>)}
                </>}

              </Stack>
            )}
          </Stack>
        </Modal>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="simple-modal-title">{modalContent}</Typography>
          <TextField
            fullWidth
            margin="normal"
            label={`${modalContent} Name`}
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={() => setOpenModal(false)}>Discard</Button>
            <Button variant="contained" onClick={handleAddNewCategory}>Save</Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default BudgetingPage;
