import { Typography, Box, Stack } from '@mui/material';
import React from 'react';
import colorPalette from 'theme/colorPalette';
import Avatar from '@mui/material/Avatar';
import { Transaction } from 'interfaces/transaction';
import CocoChip from './CocoChip';
import DateDisplay from './common/DateDisplay';
import { hardParseFloatReturnString } from 'services/handyFunctions';
interface TransactionFrameProps {
  transaction: Transaction;
}

const TransactionFrame = ({ transaction }: TransactionFrameProps) => {


  const { category, amountBreakdown, transactionStatus, createdDate, transactionDate, transactionType, transactionName, id } = transaction;

  return (
    <Box sx={{ background: colorPalette.gray_100 }} border={`2px solid ${colorPalette.gray_200}`} borderRadius={1} p={1}>
      <Stack direction="row" spacing={1} width="100%" justifyContent="space-between" flexWrap="wrap">
        <Box flexGrow={1} minWidth={0}>
          <Stack direction="row" spacing={1} pl={0.5} alignItems="center">
            <CocoChip
              label={category?.[0]?.categoryName ? category[0].categoryName : ""}
              className={category?.[0]?.categoryClass ? category[0].categoryClass : ""}
            />
          </Stack>
        </Box>
        <Stack
          style={{
            background: transactionType === 'income' ? colorPalette.Light_Green_300 : colorPalette.Amber_200,
            padding: '0 0.5em',
            borderRadius: '20px',
            height: '28px'
          }}
        >
          <Typography variant="body1"
           
            style={{
              color: transactionType === 'income' ? colorPalette.gray_800 : colorPalette.gray_800
            }}
          >
            {transactionType === 'income' ? `+ $${hardParseFloatReturnString(amountBreakdown.totalCost)}` : `- $${hardParseFloatReturnString(amountBreakdown.totalCost)}`}
          </Typography>
        </Stack>
      </Stack>
      <Typography pl={0.5} variant="body1">
        {`${transactionName ? transactionName : ""}`}
      </Typography>
      {transactionDate && (
        <DateDisplay dateInput={transactionDate} />
      )}

    </Box>
  );

};

export default TransactionFrame;





