import React, { useState, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { validateUser } from '../../services/validateUser';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface PlaidAddAccountProps {
    onSuccessProp?: (publicToken: string, metadata: any) => void;
    onExitProp?: (error: any, metadata: any) => void;
    state?: 'new' | 'connected'; // New prop to determine the state
}


const PlaidAddAccount: React.FC<PlaidAddAccountProps> = ({ onSuccessProp, onExitProp, state = 'new' }) => {
    const [linkToken, setLinkToken] = useState(null);

    // Fetch a link token from your server
    useEffect(() => {
        const fetchLinkToken = async () => {
            const validated_user = await validateUser();
            const userId = validated_user?.user_pack.id;
            const url = `${process.env.REACT_APP_SERVER_URL}/api/account/create_link_token/${userId}`;
            const response = await fetch(url);
            const data = await response.json();
            setLinkToken(data.link_token);
        };

        fetchLinkToken();
    }, []);

    const onSuccess = async (publicToken: any, metadata: any) => {
        // Send the publicToken to your backend server
        try {
            const validated_user = await validateUser();
            const userId = validated_user?.user_pack.id;
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/account/exchange_public_token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ publicToken, userId }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            await fetchAccounts();
            // Handle the response as needed
            const responseData = await response.json();
            // Call the optional onSuccessProp if it was provided
            if (onSuccessProp) {
                onSuccessProp(publicToken, metadata);
            }
            // Optionally call onSuccess from props if you have additional logic there
            // onSuccess(publicToken, metadata);
        } catch (error) {
            console.error('Error exchanging public token:', error);
        }
    };

    const fetchAccounts = async () => {

        try {
            const validated_user = await validateUser();
            const userId = validated_user?.user_pack.id;
            const url = `${process.env.REACT_APP_SERVER_URL}/api/account/fetchaccounts/${userId}`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const onExit = (error: any, metadata: any) => {
        // Handle the exit process here
        if (onExitProp) {
            onExitProp(error, metadata);
        }
        console.log('User exited Plaid Link flow');
        // Optionally call onExit from props if you have additional logic there
        // onExit(error, metadata);
    };

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess,
        onExit,
        // Add other configuration options here
    });


    return (
        <>
            {state === 'new' && (
                <Button variant='contained' onClick={() => open()} disabled={!ready || !linkToken}>
                    Connect Accounts
                </Button>
            )}
            {['connected', 'disconnected'].includes(state) && (
                <Button
                    variant='text'
                    onClick={() => open()}
                    disabled={!ready || !linkToken}
                    startIcon={<AddIcon />}
                >
                    {state === 'connected' ? 'Connect more accounts' : 'Resync accounts'}
                </Button>
            )}
        </>
    );
};



export default PlaidAddAccount;
