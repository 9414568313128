import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colorPalette from "theme/colorPalette";
import { hardParseFloatReturnString } from 'services/handyFunctions';

interface CategoryAmount {
  id: string;
  category_name: string;
  amount: number;
  children?: CategoryAmount[];
}

interface ExpenseProgressBarProps {
  categoryAmount: CategoryAmount[];
}

const ExpenseProgressBar: React.FC<ExpenseProgressBarProps> = ({ categoryAmount }) => {

  // Modified renderCategory to accept a level parameter, defaulting to 0 for top level
  const renderCategory = (category: CategoryAmount, level = 0) => (
    <Accordion
      key={category.id}
      disableGutters
      elevation={0}
      square
      sx={{
        '&:before': {
          display: 'none',
        },
        // Apply borderBottom only if level is 0 (top-level accordion)
        marginBottom: level === 0 ? '4px' : '0px',
        borderBottom: level === 0 ? `1px solid ${colorPalette.gray_200}` : 'none',
        '& .MuiButtonBase-root': { // Applies to all levels but is necessary for spacing
          minHeight: 'unset !important',
          height: '32px',
        },
      }}
    >
      <AccordionSummary
        sx={{
          margin: 0,
          justifyContent: 'revert',
          alignItems: 'center',
        }}
        expandIcon={category.children && category.children.length > 0 ? <ExpandMoreIcon sx={{ color: colorPalette.gray_400 }} /> : null}
        aria-controls="panel1a-content"
        id={`panel1a-header-${category.id}`}
      >
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography variant="subtitle1">{category.category_name}</Typography>
          {category.amount !== 0 && (
            <Typography variant="body1"
              style={{
                color: colorPalette.gray_800,
              }}>
              {`$${hardParseFloatReturnString(category.amount)}`}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      {category.children && category.children.length > 0 && (
        <AccordionDetails>
          {category.children.map(child => renderCategory(child, level + 1))} {/* Increment level for children */}
        </AccordionDetails>
      )}
    </Accordion>
  );

  return (
    <Stack gap={0} marginTop={2}>
      {categoryAmount.length > 0 ? (
        categoryAmount.map(category => renderCategory(category)) // Initial call with default level (0)
      ) : (
        <Typography variant="h6" marginTop={2}>No categories found</Typography>
      )}
    </Stack>
  );
}

export default ExpenseProgressBar;
