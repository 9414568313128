import axios from 'axios';
import { validateUser } from '../services/validateUser';

export const getAllBudgetsAndActuals = async (userId: string) => {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/dashboard/budget/${userId}`);
      const budgetsAndActuals = response.data;
      return budgetsAndActuals;
    
  };

 export const getAllTopCategories = async (userId: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/dashboard/topcategories/${userId}`);
    const topCategories = response.data;
    // Check if the response has an 'expenses' property
    if (topCategories && topCategories.expenses) {        
      return topCategories.expenses[0].children;
    } else {
        return topCategories;
      // If 'expenses' is not present, throw an error
      throw new Error('Expenses data not found');
    }
  } catch (error) {
    // Handle the error (either from Axios request or the thrown error)
    console.error('Error fetching top categories:', error);
    throw error; // Re-throw the error to handle it in the caller function
  }
};


  export const getCHIData  = async (userId: string) => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/dashboard/getchi/${userId}`);
    const topCategories = response.data;
    return topCategories;
};