import React, { useState } from "react";
import {
  Stack,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import Logo from "../assets/logo/Logo";
import { Link } from "react-router-dom";
// import Link from '@mui/material/Link';
import axios from "axios";
import { useLogin } from "@refinedev/core";
import { Box } from "@mui/material";
import GoogleLoginSingup from '../components/GoogleLoginSignup'
import colorPalette from "theme/colorPalette";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import English_Locale from "../locales/english/english.json";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LoginOtp from '../components/LoginOtp'
import { blue } from "@mui/material/colors";
// TODO remove, this demo shouldn't need to reset the theme.

export const Login = () => {
  const [email, setEmail] = useState("");
  const [otpPrompt, setOtpPrompt] = useState(false); // New state variable for OTP prompt
  const navigate = useNavigate();
  const handleVerifyOtpSubmit = () => {
    handleSubmit()
  }
  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      // Send a request to check if the user exists
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/check`, { email });

      if (response.data.exists) {
        // User already exists, send OTP
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/otp/sendotp`, { email });
        toast.success("OTP sent successfully"); // Display success message
        setOtpPrompt(true); // Display the OTP prompt
      } else {
        // New user
        toast.info("User does not exist. Please sign up."); // Display info message
        navigate("/register");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to check user existence"); // Display error message
    }
  };


  const handleOtpPrompt = () => {
    setOtpPrompt(false)
    // handleOtpSubmit()
  }
  const handleOtpSubmit = async (otp: any) => {
    try {
      // Send the entered OTP to the server for verification
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/otp/validateotp`, {
        email,
        otp,
      });

      if (response.status === 200) {
        toast.success("OTP verified successfully"); // Display success message
        localStorage.setItem(
          "user",
          JSON.stringify({
            username: response.data.user.name,
            email: response.data.user.email,
            userid: response.data.user._id,
          })
        );
        localStorage.setItem("token", `${response.data.token}`);

        // Perform login operation
        check({});

        // Redirect user to homepage after successful login
        navigate("/Homepage"); // Adjust the route as per your application's routing

      } else if (response.status === 201) {
        toast.success("OTP verified; account setup is pending."); // Display success message for pending account
        localStorage.setItem(
          "user",
          JSON.stringify({
            username: response.data.user.name,
            email: response.data.user.email,
            userid: response.data.user._id,
          })
        );
        localStorage.setItem("token", `${response.data.token}`);

        // Redirect user to setup profile page
        navigate("/setupprofile");
      } else {
        // Handle other unexpected status codes
        toast.error("Unexpected response status"); // Display error message
      }

      handleOtpPrompt(); // Reset OTP prompt state
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during OTP verification"); // Display error message
    }
  };


  const { mutate: check } = useLogin();

  return (
    <Grid container component="main" sx={{ height: '100vh', overflow: 'auto' }}>
      <Grid item xs={12} sm={12} md={12} sx={{ background: colorPalette.gray_100}} component={Paper} elevation={24} square>
        <Stack
          gap={0}
          maxWidth={'480px'}
          sx={{
            display: 'flex',
            background: colorPalette.white,
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            marginTop: '80px',
            border: `2px solid ${colorPalette.gray_200}`,
            borderRadius: '8px',
            boxShadow: '0px 10px 15px rgba(0,0,0,0.06)', 
          }}
        >
          <Stack padding={2} width={'100%'} borderBottom={`2px solid ${colorPalette.gray_200}`}>
            <Logo />
          </Stack>
          <Stack padding={4} alignItems={'center'} width={'100%'}>
            <Typography variant="h3">
              {English_Locale.Welcome}
            </Typography>
            <Typography variant="body2">
              Please sign in to your account.
            </Typography>
            {!otpPrompt &&
              <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={3}
                >
                  <TextField
                    margin="normal"
                    required
                    sx={{ maxWidth: '320px', marginTop: '1rem !important' }}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    variant="outlined"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={otpPrompt}
                  />
                  {!otpPrompt && <Button variant="contained" onClick={handleSubmit} fullWidth disabled={otpPrompt}>
                    {English_Locale.Login_button_text}
                  </Button>}
                </Stack>
              </Box>}
            {otpPrompt &&
              <Box component="form" noValidate sx={{ mt: 5, width: '100%' }}>
                <Stack
                  direction="column"
                  alignItems="left"
                  spacing={3}
                >
                  <Typography variant="h4" marginTop="15px">
                    We sent you a code
                  </Typography>
                  <Typography variant="body2" marginTop="15px !important" >
                    Please, enter it below to verify your email
                  </Typography>
                  <Typography variant="body1" color={blue} style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
                    <MarkEmailReadIcon style={{ marginRight: '10px' }} />
                    {email}
                  </Typography>
                  {otpPrompt &&
                    <Typography variant="body1" marginBottom="25px !important">
                      <LoginOtp email={email} otpPrompt={otpPrompt} handleOtpPrompt={handleOtpPrompt} handleOtpSubmit={handleOtpSubmit} handleVerifyOtpSubmit={handleVerifyOtpSubmit} />
                    </Typography>}
                  <Stack
                    direction="column"
                    alignItems="left"
                    spacing={3}
                    maxWidth="540px"
                  >
                    {otpPrompt &&
                      <Typography variant="body2" marginTop="45px !important" >
                        {otpPrompt && <Link to="/register" style={{ textAlign: "left" }}>
                          {English_Locale.New_to_cocowah} {English_Locale.Signup_for_free}
                        </Link>}
                      </Typography>}
                  </Stack>
                </Stack>
              </Box>}
            {!otpPrompt &&
              <Stack
                direction="column"
                alignItems="left"
                spacing={3}
                width={'100%'}
                sx={{ alignItems: 'center', mt: '1rem' }}
              >

                <Typography variant="body2" align="center">OR</Typography>
                <GoogleLoginSingup />

              </Stack>}
            {!otpPrompt && <Typography variant="body2" align="center" mt={2}><Link to="/register" style={{ textAlign: "center" }} >
              {English_Locale.New_to_cocowah} {English_Locale.Signup_for_free}
            </Link></Typography>}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}





