import React, { useState, useEffect } from "react";
import {
  Stack,
  Button,
  Grid,
  Box,
} from "@mui/material";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import Step1ProfileSetup from './Step1ProfileSetup';
import colorPalette from "../../theme/colorPalette";
import PageToPageSmileyLoader from "../../components/loaders/PageToPageSmileyLoader";

const UserProfileSetup = () => {
  const navigate = useNavigate();



  const handleLaunchApp = () => {
    // Replace with actual logic to launch the app
    navigate("/homepage");
  };

  return (
    <Grid container component="main" sx={{ height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
      {/* Content grid */}
      <Grid item
        xs={12}
        sm={12}
        md={12}
        sx={{
          background: colorPalette.gray_100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}>

        <Box
          component="div"
          className='shadowStylePaper animate__animated animate__fadeIn animate__faster'
          p={1}
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            margin: 'auto',
            paddingTop: '12px',
            width: '100%',
            maxWidth: '480px',
            maxHeight: '640px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colorPalette.white,
            alignItems: 'center',
            position: 'relative' // create a positioning context
          }}
        >
          <Stack direction={'row'} gap={5} height={'100%'}>
            <Box>
              <Box sx={{ width: '100%' }}>
                <Step1ProfileSetup />
              </Box>
              <Box sx={{
                width: '100%',
                borderTop: `2px solid ${colorPalette.gray_200}`,
                padding: 3,
                textAlign: 'center',
                alignSelf: 'baseline'
              }}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  color="primary"
                  onClick={handleLaunchApp}
                >
                  Next: Launch app
                </Button>
              </Box>
            </Box>
          </Stack>
          <ToastContainer />
        </Box>
      </Grid>
    </Grid>
  );
}

export default UserProfileSetup;
