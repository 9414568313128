import React from 'react';
import { Stack, Typography, LinearProgress, Box, Tooltip, Button } from '@mui/material';
import { MonthlyData } from '../../interfaces/dashboard';
import colorPalette from 'theme/colorPalette';
import { hardParseFloat, hardParseFloatReturnString } from 'services/handyFunctions';
import { useNavigate } from "react-router-dom";
interface ChartDataItem {
    category: string;
    actual: number;
    budget: number;
    percentageOfActuals: number;
    percentageOfOverflow: number;
}

interface BudgetsVsActualsProps {
    monthlyData: MonthlyData;
}

const BudgetsVsActuals2 = ({ monthlyData }: BudgetsVsActualsProps) => {
    const navigate = useNavigate();

    const transformDataForMonth = (actuals: any, budgets: any, section: any): ChartDataItem[] => {
        return Object.keys(actuals).map(categoryId => {
            const actual = actuals[categoryId];
            const budget = budgets[categoryId];
            const percentageOfActuals = Math.max(0, Math.min(100, ((actual / budget) * 100)));

            return {
                category: section[categoryId],
                actual,
                budget,
                percentageOfActuals,
                percentageOfOverflow: 0 // updated for simplicity
            };
        });
    };

    const calculateTotals = (data: any) => {
        return Object.keys(data.actuals).reduce(
            (acc: any, categoryId: string) => {
                acc.actualsTotal += hardParseFloat(data.actuals[categoryId]);
                acc.budgetsTotal += hardParseFloat(data.budgets[categoryId]);
                acc.actualsTotal = hardParseFloat(acc.actualsTotal);
                acc.budgetsTotal = hardParseFloat(acc.budgetsTotal);
                return acc;
            },
            { actualsTotal: 0, budgetsTotal: 0 }
        );
    };

    const months = Object.keys(monthlyData).reverse();
    const firstMonthData = monthlyData[months[0]];
    const chartData = transformDataForMonth(firstMonthData.actuals, firstMonthData.budgets, firstMonthData.section);

    const renderEmptyPlaceholder = () => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={'https://onedrive.live.com/embed?resid=1656BB0C87C6BA8%21254044&authkey=%21AP1pkbOYYBd05B4&width=501&height=501'} alt="Pink piggy bank with a shining gold coin with a dollar sign on top, suggesting the concept of adding to a budget or savings" style={{ maxWidth: '240px', height: 'auto' }} />
            <Stack gap={2} mt={2} width={'100%'} alignItems={'center'}>
                <Typography variant="body1">You haven't added any budgets yet</Typography>
                <Button onClick={() => navigate("/budgeting")} variant='outlined'>
                    Start adding budgets
                </Button>
            </Stack>
        </Box>
    );


    // Render empty placeholder if chartData is empty
    if (!chartData.length) {
        return renderEmptyPlaceholder();
    }

    return (
        <Stack direction="row" gap={2} width="100%">
            {/* First Column: Section Names */}
            <Stack gap={1.5} pt={1} pb={1} sx={{ minWidth: '140px', maxWidth: '180px' }}>
                <Box className='budget-actual-row'>
                    <Typography variant='h6' sx={{ height: '28px' }}>
                        Month
                    </Typography>
                </Box>
                <Box className='budget-actual-row'>
                    <Typography variant='h6' sx={{ height: '28px' }}>
                        Budget Total
                    </Typography>
                </Box>
                <Box className='budget-actual-row'>
                    <Typography variant='h6' sx={{ height: '28px' }}>
                        Actuals Total
                    </Typography>
                </Box>
                {chartData.map((item, index) => (
                    <Box className='budget-actual-row'>
                        <Typography key={index} sx={{ height: '28px', overflow: 'hidden' }}>
                            {item.category}
                        </Typography>
                    </Box>
                ))}

            </Stack>

            {/* Remaining Columns: Linear Progress Bars and Totals for Each Month */}
            {months.map((month, monthIndex) => {
                const { actuals, budgets } = monthlyData[month];
                const monthChartData = transformDataForMonth(actuals, budgets, firstMonthData.section);
                const totals = calculateTotals(monthlyData[month]);

                return (
                    <Stack
                        key={month}
                        gap={1.5}
                        direction={'column'}
                        p={1}
                        height={'100%'}
                        borderRadius={'10px'}
                        sx={{
                            alignItems: 'left',
                            width: '100%',
                            maxWidth: monthIndex === 0 ? '240px' : '180px',
                            background: monthIndex === 0 ? colorPalette.gray_050 : (monthIndex % 2 === 0 ? colorPalette.gray_050 : 'transparent')
                        }}>

                        <Typography
                            variant={
                                monthIndex === 0 ? `h6` : 'h6'}
                            ml={1}
                            color={monthIndex === 0 ? colorPalette.gray_900 : colorPalette.gray_400}
                            width={'100%'}
                            height={'28px'}
                            overflow={'hidden'}>
                            {month}
                        </Typography>

                        {/* Budget Total */}
                        <Box style={{
                            background: `${totals.budgetsTotal && monthIndex === 0 ? colorPalette.Amber_200 : 'transparent'}`,
                            padding: '0 0.5em',
                            borderRadius: '20px',
                            alignSelf: 'start',
                            height: '28px'
                        }}>
                            <Typography variant="body1"
                                style={{
                                    color: colorPalette.gray_800
                                }}>
                                {`$${hardParseFloatReturnString(totals.budgetsTotal)}`}
                            </Typography>
                        </Box>

                        {/* Actuals Total */}
                        <Box style={{
                            background: `${totals.actualsTotal && monthIndex === 0 ? colorPalette.Light_Blue_100 : 'transparent'}`,
                            padding: '0 0.5em',
                            borderRadius: '20px',
                            alignSelf: 'start',
                            height: '28px'
                        }}>
                            <Typography variant="body1"
                                style={{
                                    color: colorPalette.gray_800
                                }}>
                                {`$${hardParseFloatReturnString(totals.actualsTotal)}`}
                            </Typography>
                        </Box>

                        {monthChartData.map((item, index) => {
                            // Log the percentageOfActuals

                            return (
                                <Tooltip
                                    key={index}
                                    title={
                                        <React.Fragment>
                                            <Box>
                                                <Typography variant='body1' color={colorPalette.white}>{item.category}</Typography>
                                                <Typography variant='body2' color={colorPalette.gray_100}>Actual: ${item.actual}</Typography>
                                                <Typography variant='body2' color={colorPalette.gray_100}>Budget: ${item.budget}</Typography>
                                            </Box>
                                        </React.Fragment>
                                    }
                                    arrow
                                    sx={{ tooltip: { fontSize: '2em', padding: '10px' } }}
                                >
                                    <Box sx={{ width: '100%', height: '28px' }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={item.percentageOfActuals}
                                            sx={{
                                                height: '20px',
                                                mb: 0,
                                                borderRadius: '20px',
                                                backgroundColor: monthIndex === 0 ? colorPalette.gray_200 : colorPalette.gray_300,
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: item.percentageOfActuals >= 100 && monthIndex === 0
                                                        ? colorPalette.Red_A100
                                                        : monthIndex === 0
                                                            ? colorPalette.Amber_A400
                                                            : colorPalette.gray_400
                                                }
                                            }}
                                        />
                                    </Box>

                                </Tooltip>
                            );
                        })}

                    </Stack>
                );
            })}
        </Stack>
    );
};

export default BudgetsVsActuals2;