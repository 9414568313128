import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import colorPalette from 'theme/colorPalette';
import BudgetLabel from './micro-components/BudgetLabel';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { TreeDataItem } from '../interfaces/category';



interface ColumnTreeProps {
  categoryType: string;
  categoryDepth: string;
  data: TreeDataItem[];
  activeNode: string | null;
  onNodeClick: (id: string | null) => void;
  onAddButtonClick: () => void; // Added prop here
}

type BudgetStatus = 'budgeted' | 'unbudgeted' | 'not applicable';

const ColumnTree: React.FC<ColumnTreeProps> = ({ categoryType, categoryDepth, data, activeNode, onNodeClick, onAddButtonClick }) => {

  const handleCardClick = (id: string | null) => {
    onNodeClick(id === activeNode ? null : id);
  };

  let treeData: TreeDataItem[] = [];
  switch (categoryDepth) {
    case 'Section':
    case 'Shelf':
    case 'Box':
      treeData = data;
      break;
    default:
      treeData = [];
      break;
  }
  const getIconForCategoryType = (type: string) => {
    switch (type) {
      case 'income-section':
      case 'expense-section':
        return <FolderIcon sx={{ color: colorPalette.gray_400 }} />;
      case 'income-shelf':
      case 'expense-shelf':
        return <FolderOpenIcon sx={{ color: colorPalette.gray_400 }} />;
      case 'income-box':
      case 'expense-box':
        return <InsertDriveFileIcon sx={{ color: colorPalette.gray_400 }} />;
      default:
        return null;
    }
  };


  return (
    <Box
      sx={{ flex: 1, height: '85%', overflowY: 'auto', background: colorPalette.gray_100, padding: '0.5em', borderRadius: '8px', minWidth: '320px' }}>
      <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', padding: '0 1em', }}>
        <Typography variant="h6" component="span" sx={{ display: 'flex', alignItems: 'center' }}>
          {categoryDepth}
        </Typography>
        <IconButton onClick={onAddButtonClick}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction={'column'} >
        {treeData?.map((category) => (
          <Stack
            key={category._id}
            onClick={() => handleCardClick(category._id)}
            gap={1}
            sx={{
              flexDirection: 'column',
              background: activeNode === category._id ? colorPalette.gray_050 : 'white',
              boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
              p: '1em',
              borderRadius: '4px',
              marginTop: '16px',
              border: `2px solid ${activeNode === category._id ? 'blue' : 'transparent'}`,
              cursor: 'pointer',
              transition: 'border 0.3s, background 0.3s',
              '&:hover': {
                background: colorPalette.blue_gray_050,
              }
            }}
          ><Stack direction={"row"} gap={1} alignItems={"left"}>
              {getIconForCategoryType(category.type)}
              <Typography variant="body1" fontWeight={800} component="span">
                {category.name}
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
              <BudgetLabel budgetStatus={category.budgetStatus} budgetAmount={category.budgetAmount} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {category.children && category.children.length > 0 && (
                  <AccountTreeIcon sx={{ color: colorPalette.gray_400, fontSize: '1.2em' }} />
                )}
                {category.children && category.children.length > 0 && (
                  <Typography variant="body2" component="span" sx={{ marginLeft: '8px' }}>
                    {category?.children?.length}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default ColumnTree;
