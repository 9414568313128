import React, { useRef, useEffect } from "react";
import { CredentialResponse } from "../interfaces/google";
import { useLogin } from "@refinedev/core";

const GoogleLoginSignup = () => {
    const { mutate: login } = useLogin<CredentialResponse>();
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (typeof window === "undefined" || !window.google || !divRef.current) {
            return;
        }

        try {
            window.google.accounts.id.initialize({
                ux_mode: "popup",
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: async (res: CredentialResponse) => {
                    if (res.credential) {
                        login(res);
                    }
                },
            });
            window.google.accounts.id.renderButton(divRef.current, {
                theme: "outline",
                size: "large",
                type: "standard",
            });

            // Initially hide the original Google button container
            // divRef.current.style.visibility = 'hidden';
        } catch (error) {
            console.log(error);
        }
    }, []);


    return (
        <div>
            <div ref={divRef} style={{ height: '48px' }} />            
        </div>
    );
};

export default GoogleLoginSignup;
