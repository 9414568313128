import React from 'react';
import {
  AuthBindings,
  Authenticated,
  Refine,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
} from "@refinedev/mui";
import { ThemeProvider, Drawer, List, ListItem, ListItemText, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { CssBaseline, GlobalStyles, Box } from "@mui/material";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import axios, { AxiosRequestConfig } from "axios";
import { CredentialResponse } from "./interfaces/google";
import { useEffect, useState } from "react";
import { validateUser } from "./services/validateUser";
import {
  Login,
  Registration,
  Homepage,
  TransactionsPage,
  BudgetingPage,
  ChatWithGPT,
  Settings
} from "./pages";

import 'animate.css';


import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/ChatBubbleOutline'; // Example icon for Transactions
import SettingsIcon from '@mui/icons-material/Settings'; // Example icon for Categories
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'; // Example icon for Transactions
import CategoryIcon from '@mui/icons-material/Category'; // Example icon for Categories

import { ToastContainer } from 'react-toastify';
import CategoriesSetup from "./pages/OnboardingScreens/CategoriesSetup";
import UserProfileSetup from "./pages/OnboardingScreens/UserProfileSetup";

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { parseJwt } from "./utils/parse-jwt";
import { ColorModeContextProvider } from "./contexts/color-mode";
import cocowahTheme from "./theme/cocowahMuiTheme";
import Avatar from '@mui/material/Avatar';
import CoinsLogo from './assets/logo_coins.svg';
import { LogoutButton } from "./services/logout";
import colorPalette from "./theme/colorPalette";
import "./App.css";
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return request;
});

type AuthActionResponse = {
  success: boolean;
  redirectTo?: string;
  error?: Error;
  [key: string]: unknown;
};
const authProvider: AuthBindings = {
  login: async ({ credential }: CredentialResponse): Promise<AuthActionResponse> => {
    const profileObj = credential ? parseJwt(credential) : null;

    // Save user to MongoDB

    if (profileObj) {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: profileObj.name,
            email: profileObj.email,
            avatar: profileObj.picture,
          }),
        });

        const data = await response.json();
        if (response.status === 200) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...profileObj,
              avatar: profileObj.picture,
              userid: data.user._id,
              email: data.user.email,
            })
          );
          localStorage.setItem("token", `${credential}`);

          return {
            success: true,
            redirectTo: "/homepage",
          };
        }
        if (response.status === 201) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...profileObj,
              avatar: profileObj.picture,
              userid: data.user._id,
              email: data.user.email,
            })
          );
          localStorage.setItem("token", `${credential}`);

          return {
            success: true,
            redirectTo: "/setupprofile",
          };
        } else {
          return {
            success: false,
          };
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      success: false,
    };
  },
  logout: async () => {
    const token = localStorage.getItem("token");

    if (token && typeof window !== "undefined") {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      axios.defaults.headers.common = {};
      window.google?.accounts.id.revoke(token, () => {
        return {};
      });
    }

    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    const token = localStorage.getItem("token");
    if (token) {
      return {
        success: true,
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Check failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    }

    return null;
  },
};
function App() {

  const [validatedUser, setValidatedUser] = useState<{ user_pack: { id: any; email: any, name: any, avatar: any } } | undefined>(undefined);
  const [activeNavigationRoute, setActiveNavigationRoute] = useState('homepage');
  window.localStorage.setItem("colorMode", "light");

  useEffect(() => {
    (async () => {
      const validated_user = await validateUser();
      if (validated_user) {
        setValidatedUser(validated_user);

      }
    })();
  }, []);
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />

          <ThemeProvider theme={cocowahTheme}>
            <RefineSnackbarProvider>
              <Refine
                dataProvider={dataProvider(`${process.env.REACT_APP_SERVER_URL}/api`)}
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                resources={[
                  {
                    name: "Homepage",
                    list: Homepage,
                    options: { label: "Homepage" },
                  },
                  {
                    name: "transactions",
                    list: TransactionsPage,
                  },
                  {
                    name: "categories",
                    list: BudgetingPage,
                  },
                  {
                    name: "chat",
                    list: "/chat",
                    // icon: <SettingsIcon  />
                  },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                }}
              >
                <Routes>

                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Registration />} />

                  <Route element={<Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Sider={() => null}
                      Header={() => null}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                  }>
                    <Route path="/setupprofile" element={<UserProfileSetup />} />
                    <Route path="/setupcategories" element={<CategoriesSetup />} />
                  </Route>

                  <Route
                    element={
                      <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                        <ThemedLayoutV2
                          Sider={() => (
                            <Drawer className="drawer280" sx={{ "& .MuiPaper-root": { width: '17.5rem' } }} variant="permanent" anchor="left">
                              <Stack direction={'column'} justifyContent="space-between" height="100%">
                                <Stack direction={'column'} gap={4} height="100%">
                                  <Box ml={'8px'}>
                                    <img alt="Coins Logo" src={CoinsLogo} />
                                  </Box>
                                  <List>
                                    {[
                                      { text: 'Dashboard', icon: <DashboardIcon />, to: '/homepage' },
                                      { text: 'Transactions', icon: <ReceiptLongIcon />, to: '/transactions' },
                                      { text: 'Categories', icon: <CategoryIcon />, to: '/budgeting' },
                                      { text: 'Chat', icon: <ChatIcon />, to: '/chat' },
                                      { text: 'Settings', icon: <SettingsIcon />, to: '/settings' },
                                    ].map((item) => (
                                      <ListItem
                                        button
                                        key={item.text}
                                        component={Link}
                                        to={item.to}
                                        className={activeNavigationRoute === item.to.substring(1) ? 'nav-item active' : 'nav-item'}
                                        onClick={() => setActiveNavigationRoute(item.to.substring(1))}
                                      >
                                        <ListItemIcon sx={{ minWidth: '42px', '& .MuiSvgIcon-root': { fontSize: '20px' } }}>{item.icon}</ListItemIcon>
                                        <Typography variant='h5'>{item.text}</Typography>
                                      </ListItem>
                                    ))}
                                  </List>
                                </Stack>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  gap: '16px',
                                  p: 2,
                                  borderRadius: '8px',
                                  backgroundImage: `linear-gradient(109.6deg, ${colorPalette.Purple_050} 11.2%, ${colorPalette.Cyan_050} 100.2%)`,
                                  height: '320px'
                                }}>
                                  <Stack
                                    direction="column"
                                    padding={2}
                                    borderRadius="8px"
                                    gap={2}
                                    alignItems={'center'}
                                    flexWrap="wrap"
                                    sx={{
                                      border: '2px solid rgba(255, 255, 255, 0.2)',
                                      background: 'rgba(255, 255, 255, 0.4)',
                                      backdropFilter: 'blur(5px)',
                                      WebkitBackdropFilter: 'blur(5px)',
                                      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <Avatar
                                      alt={validatedUser?.user_pack.name}
                                      src={validatedUser?.user_pack.avatar}
                                      sx={{
                                        width: 64,
                                        height: 64,
                                        mb: 0
                                      }}
                                      className="avatar-lg"
                                      sizes="large"
                                    />
                                    <Stack
                                      direction="column"
                                      gap={0.2}
                                      width="100%"
                                      overflow="hidden"
                                    >
                                      <Typography textAlign='center' color={colorPalette.gray_800} variant="h5" >
                                        {validatedUser?.user_pack.name}
                                      </Typography>
                                      <Typography textAlign='center' variant="body2" color={colorPalette.gray_700
                                      }>
                                        {validatedUser?.user_pack.email}
                                      </Typography>

                                    </Stack>
                                  </Stack>
                                  <LogoutButton></LogoutButton>
                                </Box>
                              </Stack>
                            </Drawer>
                          )}
                          Header={() => null}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route path="/" element={<NavigateToResource resource="Homepage" />} />
                    <Route path="/setupcategories" element={<CategoriesSetup />} />
                    <Route path="/setupprofile" element={<UserProfileSetup />} />
                    <Route path="/homepage">
                      <Route index element={<Homepage />} />
                    </Route>
                    <Route path="/transactions">
                      <Route index element={<TransactionsPage />} />
                    </Route>
                    <Route path="/budgeting">
                      <Route index element={<BudgetingPage />} />
                    </Route>
                    <Route path="/chat">
                      <Route index element={<ChatWithGPT />} />
                    </Route>
                    <Route path="/settings">
                      <Route index element={<Settings />} />
                      <Route path="subscription">
                        <Route index element={<Settings />} />
                        {/* Correct handling of an optional sessionId parameter */}
                        <Route path="return">
                          <Route index element={<Settings />} />
                          <Route path=":sessionId" element={<Settings />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
              </Refine>
            </RefineSnackbarProvider>
          </ThemeProvider>

          <ToastContainer />

        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;