import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import colorPalette from '../theme/colorPalette';

type TextStyle = {
    fontFamily: string;
    fontSize: string;
    fontWeight: string | number;
    color: string;
    textAlign: 'left';
    textTransform?: 'uppercase';
    // ... and any other properties
};

interface CocoFieldProps {
    variant?: 'outlined' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
    type?: TextFieldProps['type'];
    value?: string | Date;
    placeholder?: string;
    onClick?: () => void;
    onHover?: () => void;
    handleSave?: (value: string) => void; // New prop to handle saving
    pretext?: string; // New prop for the non-deletable prefix   
    background?: string; // Add the background property here
}


const styles = {
    textField: {
        outlined: {
            borderColor: 'transparent', // Setting the border color to transparent
            '&:hover:not($disabled):not($focused):not($error) .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Ensure hover state also has a transparent border
            },
            '&$focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Ensure focus state has a transparent border
            },
        },
        h1: {
            fontFamily: 'Kadwa, sans-serif',
            fontSize: '4rem',
            fontWeight: '400',
            color: colorPalette.black,
            textAlign: 'left',
        },
        h2: {
            fontFamily: 'Kadwa, sans-serif',
            fontSize: '2rem',
            fontWeight: '400',
            color: colorPalette.black,
            textAlign: 'left',
        },
        h3: {
            fontFamily: 'Kadwa, sans-serif',
            fontSize: '1.5rem',
            fontWeight: '400',
            color: colorPalette.black,
            textAlign: 'left',
        },
        h4: {
            fontFamily: 'Kadwa, sans-serif',
            fontSize: '1.2rem',
            fontWeight: '400',
            color: colorPalette.black,
            textAlign: 'left',
        },
        h5: {
            fontFamily: 'Manrope',
            fontSize: '0.8rem',
            fontWeight: '800',
            color: colorPalette.black,
            textAlign: 'left',
        },
        h6: {
            fontFamily: 'Manrope',
            fontSize: '0.8rem',
            fontWeight: '600',
            textTransform: 'uppercase',
            color: colorPalette.gray_500,
            textAlign: 'left',
        },
        body1: {
            fontFamily: 'Manrope',
            fontSize: '0.875rem',
            fontWeight: '400',
            color: colorPalette.black,
            textAlign: 'left',
        },
        body2: {
            fontFamily: 'Manrope',
            fontSize: '0.75rem',
            fontWeight: '400',
            color: colorPalette.black,
            textAlign: 'left',
            // Add other styles for body2 as needed
        },
    } as Record<string, TextStyle | { [key: string]: any }>

};

const overlay_styles: { [key: string]: React.CSSProperties } = {
    overlay: {
        zIndex: '1000',
        position: 'absolute',
        top: '72px',
        transform: 'translateY(-50%)',
        right: '5px',
        display: 'flex',
        gap: '5px',
        backgroundColor: 'white',  // White background for overlay
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)'  // Add a shadow to the overlay
    },
    button: {
        backgroundColor: 'white',  // White background for buttons
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)'  // Add a shadow to the buttons
    }
}


const CocoField: React.FC<CocoFieldProps> = ({
    variant = 'body1',
    pretext,
    type = 'text',
    value = '',
    placeholder = '',
    onClick = () => { },
    onHover = () => { },
    handleSave = () => { }, // Default empty function
    background = colorPalette.gray_100,
    ...props
}) => {

    const formatDate = (date : Date) => {
        if (date instanceof Date) {
            // Convert to EST (UTC-5)
            const estOffset = 5 * 60 * 60 * 1000; // 5 hours in milliseconds
            const estDate = new Date(date.getTime() - estOffset);
    
            // Format to YYYY-MM-DD
            const formattedDate = estDate.toISOString().substring(0, 10);
            return formattedDate;
        }
        return date;
    };
    
    const initialValue = value instanceof Date ? formatDate(value) : value;

    const [inputValue, setInputValue] = useState(initialValue);

    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);



    // Update local state when the input changes
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    // Save and cancel handlers
    const handleCancel = () => {
        setInputValue(value instanceof Date ? formatDate(value) : value); // Revert to initial value
        setIsEditing(false);
    };

    // Sync state when the external value changes, for example when the parent state changes
    useEffect(() => {
        setInputValue(value instanceof Date ? formatDate(value) : value);
    }, [value]);

    useEffect(() => {
        const currentRef = inputRef.current;

        const handleFocus = () => setIsEditing(true);
        const handleBlur = () => {
            setIsEditing(false);
            // Invoke handleSave when the input field loses focus
            if (currentRef) {
                handleSave(currentRef.value || ''); // Provide a default empty string if the value is undefined
            }
        };

        if (currentRef) {
            currentRef.addEventListener('focus', handleFocus);
            currentRef.addEventListener('blur', handleBlur);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('focus', handleFocus);
                currentRef.removeEventListener('blur', handleBlur);
            }
        };
    }, [handleSave]);

    const containerStyle: React.CSSProperties = {
        position: 'relative',
    };

    const textFieldStyle = isEditing
        ? { ...styles.textField[variant], border: 'none', boxShadow: 'none' }
        : styles.textField[variant];

    return (
        <div onMouseOver={onHover} onClick={onClick} style={containerStyle}>
            <TextField
                variant="outlined"
                type={type}

                placeholder={placeholder}
                value={inputValue}
                onChange={handleChange}
                InputProps={{
                    startAdornment: pretext ? <span style={{ color: colorPalette.gray_600, marginRight: '4px' }}>{pretext}</span> : null,
                    style: textFieldStyle,
                    inputRef: inputRef,
                }}
                sx={{
                    backgroundColor: background,
                    padding: '0px',
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        transition: 'background-color 0.3s ease',
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover': {
                            backgroundColor: background,
                            boxShadow: `inset 0 0 0 2px ${colorPalette.Blue_A400}`,
                        },
                        '&:hover:not(.Mui-focused) fieldset': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: colorPalette.Blue_A400,
                        },
                    },
                }}
            />

            {isEditing && (
                <div style={overlay_styles.overlay}>
                    <IconButton size="small" color="primary" onClick={() => handleSave(inputRef.current?.value || '')}>
                        <CheckIcon />
                    </IconButton>
                    <IconButton size="small" color="secondary" onClick={() => handleCancel()}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default CocoField;