import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colorPalette from 'theme/colorPalette';

interface BudgetLabelProps {
  budgetStatus: 'budgeted' | 'unbudgeted' | 'not applicable';
  budgetAmount?: number; // Making budgetAmount optional
}

const BudgetLabel: React.FC<BudgetLabelProps> = ({ budgetStatus, budgetAmount }) => {
  let label = '';

  switch (budgetStatus) {
    case 'budgeted':
      label = `$${budgetAmount}`;
      break;
    case 'unbudgeted':
      label = 'Unbudgeted';
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        background:
          budgetStatus === 'unbudgeted'
            ? colorPalette.gray_300
            : colorPalette.Amber_300,        
        fontSize: '24px',
        height: '24px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        borderRadius: '32px',
        display: budgetStatus === 'not applicable' ? 'none' : 'flex',
      }}
    >
      <Typography variant="body2" color={budgetStatus === 'unbudgeted' ? colorPalette.gray_700 : colorPalette.black}>
        {label}
      </Typography>
    </Box>
  );
};

export default BudgetLabel;
