import React, { useState, useEffect } from 'react';
import { Typography, TextField, Stack, Box } from '@mui/material';
import Button from '@mui/material/Button';
import English_Locale from '../locales/english/english.json';
import { saveBudgetToCategory } from '../api-routers/CategoriesRouter';
import CocoField from './CocoField';
import { hardParseFloat } from 'services/handyFunctions';

const SetBudget = ({ name, categoryId, currentBudget = "0", onBudgetUpdateSuccess }: {
  name: string;
  categoryId: string;
  currentBudget?: string | number;
  onBudgetUpdateSuccess: () => void; // Add this line for the new prop
}) => {

  const initialBudgetAmount = String(currentBudget);

  const [budgetAmount, setBudgetAmount] = useState(initialBudgetAmount);
  useEffect(() => {
    setBudgetAmount(initialBudgetAmount);
  }, [currentBudget]);

  const handleSaveBudget = async (newValue: string) => {
    if (newValue.length === 0) return;
    else if (newValue !== currentBudget) {
      try {
        await saveBudgetToCategory(categoryId, newValue);

        if (onBudgetUpdateSuccess) {
          onBudgetUpdateSuccess();
        }
        // You can add additional logic here like showing a success message or updating state
      } catch (error) {
        console.error('Error updating budget:', error);
        // Handle the error, show an error message, etc.
      }
    }
  };

  const handleResetBudget = () => {
    const initialBudgetAmount = String(currentBudget);
  };

  return (
    <Box>
      <Stack direction="column" gap={2}>
        <Stack direction="column" gap={0.5}>
          <Typography variant="h4">Set Budget</Typography>
          <Typography variant="body2">
            This budgeting will start over at the beginning of every month.
          </Typography>
        </Stack>
        <CocoField
          variant='body1'
          type='number'
          placeholder={'Add budget'}
          value={budgetAmount}
          pretext='$ '
          handleSave={(newValue) => handleSaveBudget(newValue)}
        />
      </Stack>
    </Box>
  );
};

export default SetBudget;
