import React from 'react';
import { Box, Stack, Typography, Tooltip } from '@mui/material';
import { getRelationalDateTime, hardParseFloatReturnString } from '../services/handyFunctions';
import DateDisplay from './common/DateDisplay'
import CocoChip from './CocoChip'
import colorPalette from 'theme/colorPalette';
import { color } from '@pankod/refine-mui';
import { ArrowOutwardOutlined, AccountBalance, SouthWest, CheckCircle } from '@mui/icons-material';

interface TransactionListItemProps {
  transactionName: string;
  transactionAmount: number;
  isReceiptAdded: boolean;
  transactionType?: "income" | "expense"; // <-- Make this optional
  category: Array<any>;
  accountName: string;
  transactionDate: Date;
  onClick: () => void;
  isActive: boolean;
}



const TransactionListItem: React.FC<TransactionListItemProps> = ({
  transactionName,
  transactionAmount,
  transactionType = 'expense',
  isReceiptAdded,
  category,
  accountName,
  transactionDate,
  onClick,
  isActive,
}) => {

  const accountDisplayName = accountName || 'Cash';
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Stack
        className={`TransactionListItem ${isActive ? 'active' : ''}`}
        gap={0.5}
        paddingLeft={2}
        paddingRight={2}
        paddingTop={1.2}
        paddingBottom={1.2}
        sx={{
          '&:hover': {
            background: colorPalette.gray_100,
            cursor: 'pointer',
          },
          '&.active': {
            background: transactionType === 'expense' ? colorPalette.white : colorPalette.gray_300,
            borderBottom: transactionType === 'expense' ? `2px solid ${colorPalette.Orange_300}` : `2px solid ${colorPalette.Green_300}`,
          },
          borderRadius: '0',
          borderBottom: isActive ? `2px solid ${colorPalette.white}` : `2px solid ${colorPalette.gray_200}`,
          background: colorPalette.white,
        }}
        onClick={onClick}
      >
        <Stack direction="row" gap={1} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Tooltip title={accountDisplayName}>
            <Stack
              direction="row"
              gap={1}
              height={24}
              alignItems="center"
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <AccountBalance
                style={{
                  color: colorPalette.gray_600,
                  fontSize: 16,
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: colorPalette.gray_800,
                }}
              >
                {accountDisplayName}
              </Typography>
            </Stack>
          </Tooltip>
          <Box style={{
            background: transactionType === 'income' ? colorPalette.Light_Green_100 : colorPalette.Orange_050,
            border: `1px solid ${transactionType === 'income' ? colorPalette.Light_Green_200 : colorPalette.Orange_100}`,
            padding: '0 0.5em',
            borderRadius: '20px',
            width: 'fit-content',
            height: '24px',
          }}>
            <Typography variant="body1"
              style={{
                color: transactionType === 'income' ? colorPalette.black : colorPalette.black
              }}>
              {transactionType === 'income' ? `+$${hardParseFloatReturnString(transactionAmount)}` : `-$${hardParseFloatReturnString(transactionAmount)}`}
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" gap={0.2} alignItems={'start'} justifyContent={'space-between'}>
          <Tooltip title={transactionName}>
            <Stack direction='row' alignItems={'center'} >
              <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', height: '24px' }}>
                {transactionName}
              </Typography>
              {isReceiptAdded && (
                <CheckCircle sx={{ fontSize: 16, marginLeft: '4px', color: colorPalette.Light_Green_400 }} />
              )}
            </Stack>
          </Tooltip>
          <Box sx={{ flexShrink: 0, whiteSpace: 'nowrap' }}>
            {transactionDate && (
              <DateDisplay dateInput={transactionDate} />
            )}
          </Box>

        </Stack>
        {category && (
          <Stack direction="row" justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              maxWidth="100%"
              gap={1}
            >
              {category.map((category, index) => {
                return (
                  <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                    {category.categoryClass.includes('expense') ? (<><ArrowOutwardOutlined
                      style={{
                        color: colorPalette.Orange_500,
                        fontSize: 14
                      }}>
                    </ArrowOutwardOutlined></>) : (<>
                      <SouthWest
                        style={{
                          color: colorPalette.Light_Green_600,
                          fontSize: 14
                        }}>
                      </SouthWest> </>)}

                    <CocoChip
                      label={category.categoryName}
                      className={category.categoryClass}
                      key={index}
                    />
                  </Stack>

                );
              })}
            </Box>
          </Stack>
        )}

      </Stack>
    </Box>
  );
};

export default TransactionListItem;
