import React, { useState, useRef, useEffect } from 'react';
import { Popover, List, ListItem, Stack, Button, Typography, TextField, ListItemIcon } from '@mui/material';
import SectionIcon from '@mui/icons-material/Category';
import LayersIcon from '@mui/icons-material/Layers';
import BoxIcon from '@mui/icons-material/CheckBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import colorPalette from 'theme/colorPalette';
import AddIcon from '@mui/icons-material/Add';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
export type DataType = {
    title: string;
    type: "section" | "shelf" | "box";
    id: string | null; // Change the type of id to be nullable
    budgetStatus: string | null; // Change the type of budgetStatus to be nullable
    budgetAmount: number | null; // Change the type of budgetAmount to be nullable
    children: DataType[];
};

type FlatDataType = {
    title: string;
    type: 'section' | 'shelf' | 'box';
    id: string | null; // Change the type of id to be nullable
    budgetStatus: string | null; // Change the type of budgetStatus to be nullable
    budgetAmount: number | null;
    parent: string | null;
    grandparent?: string | null;
};

const categoryTypes = ['section', 'shelf', 'box'];
function flattenData(items: DataType[], parent: string | null = null, grandparent: string | null = null): FlatDataType[] {
    return items.reduce((acc: FlatDataType[], item: DataType) => {
        const flatItem: FlatDataType = {
            title: item.title,
            type: item.type,
            id: item.id || '', // Provide a default value for id
            budgetStatus: item.budgetStatus || '', // Provide a default value for budgetStatus
            budgetAmount: item.budgetAmount || 0, // Provide a default value for budgetAmount
            parent,
            grandparent,
        };
        acc.push(flatItem);

        if (item.children) {
            acc = acc.concat(flattenData(item.children, item.title, parent));
        }

        return acc;
    }, []);
}


interface CocoCategorySearchProps {
    data: DataType[];
    selectText?: 'expense' | 'income' | 'merchant';
    placeholder?: string;
    defaultValue?: string;
    onUpdate?: (title: string) => void;
    handleNewCategory?: (categoryName: string, parentId: string, categoryType: string) => void;
    background?: string;
    selectMode?: 'selectOnly' | 'viewAndSelect';
    badgeLabel?: string;
}

const CocoCategorySearch: React.FC<CocoCategorySearchProps> = ({
    data,
    selectText = 'Category',
    defaultValue = '',
    onUpdate,
    placeholder = 'No item added',
    handleNewCategory,
    background = colorPalette.gray_100, // Set the default background here
    selectMode = 'viewAndSelect',
    badgeLabel,
}) => {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState<'section' | 'shelf' | 'box'>('section');
    const [selectedSection, setSelectedSection] = useState<DataType | null>(null);
    const [selectedShelf, setSelectedShelf] = useState<DataType | null>(null);
    const [selectedBox, setSelectedBox] = useState<FlatDataType | null>(null);
    const [moveToDestinationEndItem, setMoveToDestinationEndItem] = useState<FlatDataType | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [newItem, setNewItem] = useState('');
    const [tableItemHovered, setTableItemHovered] = useState(false);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const anchorEl = useRef(null);
    const flatData = flattenData(data);

    const [operatingMode, setOperatingMode] = useState<'select' | 'create' | 'edit'>('select');



    const searchEntireDataSet = (term: string): DataType[] => {
        return flattenData(data)
            .filter(item => item.title.toLowerCase().includes(term.toLowerCase()))
            .map(item => ({
                title: item.title,
                type: item.type,
                id: null, // Provide a default value for id
                budgetStatus: '', // Provide a default value for budgetStatus
                budgetAmount: 0, // Provide a default value for budgetAmount
                children: [],
            }));
    };

    const searchResults = searchTerm.trim() ? searchEntireDataSet(searchTerm) : [];

    const getIconForType = (type: 'section' | 'shelf' | 'box', color: string = colorPalette.gray_500) => {
        const iconStyle = { fontSize: "large", color: color };

        if (type === 'section') return <SectionIcon style={iconStyle} />;
        if (type === 'shelf') return <LayersIcon style={iconStyle} />;
        return <BoxIcon style={iconStyle} />; // Assuming you want the BoxIcon to have the color styling as well
    };

    const [prevDefaultValue, setPrevDefaultValue] = useState<string | null>(null);
    const [userChanged, setUserChanged] = useState(false);

    useEffect(() => {
        if (!userChanged && defaultValue !== prevDefaultValue) {
            const itemFromDefaultValue = flatData.find(item => item.title === defaultValue);
            if (itemFromDefaultValue) {
                setSelectedBox(itemFromDefaultValue);
            } else {
                setSelectedBox(null); // reset if no matching item found
            }
            setPrevDefaultValue(defaultValue); // update the previous default value state
        }
        setUserChanged(false); // reset userChanged flag after every render
    }, [defaultValue, flatData]);


    const handleListItemClick = (item: DataType) => {
        setUserChanged(true);
        if (!item.children?.length || item.type === 'box') {
            const flatItem: FlatDataType = {
                title: item.title,
                type: item.type,
                id: item.id || '', // Provide a default value if needed
                budgetStatus: item.budgetStatus || '', // Provide a default value if needed
                budgetAmount: item.budgetAmount || 0, // Provide a default value if needed
                parent: item.type === 'section' ? null : item.type === 'shelf' ? (selectedSection?.title ?? null) : (selectedShelf?.title ?? null),
            };

            if (typeof onUpdate === "function") {
                onUpdate(item.title);
            }
            setOpen(false);
            if (selectMode === 'selectOnly') {
                console.log('1selectOnly mode, setting new flow');
                handleNewItemFlow('select');
            }
        } else if (item.type === 'section') {
            setSelectedSection(item);
            setStep('shelf');
        } else if (item.type === 'shelf') {
            setSelectedShelf(item);
            setStep('box');
        }
    };

    const handleMoveToDestinationClick = (item: DataType) => {
        // setUserChanged(true);
        if (!item.children?.length || item.type === 'box') {
            const flatItem: FlatDataType = {
                title: item.title,
                type: item.type,
                id: item.id || '', // Provide a default value if needed
                budgetStatus: item.budgetStatus || '', // Provide a default value if needed
                budgetAmount: item.budgetAmount || 0, // Provide a default value if needed
                parent: item.type === 'section' ? null : item.type === 'shelf' ? (selectedSection?.title ?? null) : (selectedShelf?.title ?? null),
            };
            if (item.type !== 'box')
                setMoveToDestinationEndItem(flatItem);
            // if (typeof onUpdate === "function") {
            //     onUpdate(item.title);
            // }
            // setOpen(false);
        } else if (item.type === 'section') {
            setSelectedSection(item);
            setStep('shelf');
        } else if (item.type === 'shelf') {
            setSelectedShelf(item);
            // if (typeof onUpdate === "function") {
            //     onUpdate(item.title);
            // }
            setStep('box');
        }
    };
    const handleSelectItemClick = (item: DataType) => {
        const flatItem: FlatDataType = {
            title: item.title,
            type: item.type,
            id: item.id || '', // Provide a default value if needed
            budgetStatus: item.budgetStatus || '', // Provide a default value if needed
            budgetAmount: item.budgetAmount || 0, // Provide a default value if needed
            parent: item.type === 'section' ? null : item.type === 'shelf' ? (selectedSection?.title ?? null) : (selectedShelf?.title ?? null),
        };
        setSelectedBox(flatItem);
        if (typeof onUpdate === "function") {
            onUpdate(item.title);
        }
        setOpen(false);
        if (selectMode === 'selectOnly') {
            console.log('2selectOnly mode, setting new flow');
            handleNewItemFlow('select');
        }
    };
    const handleBack = () => {
        if (step === 'box') {
            setStep('shelf');
            setSelectedShelf(null);
            setMoveToDestinationEndItem(null);
        } else if (step === 'shelf') {
            setStep('section');
            setSelectedSection(null);
            setMoveToDestinationEndItem(null);
        }
        setMoveToDestinationEndItem(null);
    };


    const getButtonLabel = () => {
        let titleToDisplay = defaultValue; // Start by defaulting to defaultValue

        if (selectedBox) {
            titleToDisplay = selectedBox.title; // If there's a selectedBox, override the titleToDisplay with its title
        }

        if (titleToDisplay) { // If there's a valid title to display (either defaultValue or selectedBox's title)
            return (
                <Stack
                    textAlign='left'
                    direction='column'
                    width={'100%'}
                    onMouseEnter={() => setTableItemHovered(true)}
                    onMouseLeave={() => setTableItemHovered(false)}

                >
                    {badgeLabel && (
                        <Stack direction={'row'} alignItems='center' gap={0.5} color={colorPalette.Blue_500}>
                            <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                            <Typography variant="subtitle2" >
                                {badgeLabel}
                            </Typography>
                        </Stack>
                    )}
                    <Typography variant="body2">{titleToDisplay}</Typography>
                    <Stack direction={'row'} gap={0.5} style={{ width: '100%', overflow: 'hidden' }}>
                        {selectedBox?.grandparent ? (
                            <>
                                <Typography
                                    color={colorPalette.gray_600}
                                    variant='subtitle2'
                                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '180px' }}>
                                    {selectedBox?.grandparent}
                                </Typography>
                            </>
                        ) : null}
                        {selectedBox?.grandparent ? (
                            <Typography
                                color={colorPalette.gray_500}
                                variant='subtitle1'
                                style={{ whiteSpace: 'nowrap' }}>
                                {' > '}
                            </Typography>
                        ) : null}
                        <Typography
                            color={colorPalette.gray_800}
                            variant='subtitle2'
                            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '180px' }}>
                            {selectedBox?.parent}
                        </Typography>
                    </Stack>

                </Stack>
            );
        }

        // Adjust color based on placeholder value
        const buttonTextColor = placeholder === 'No item added' ? colorPalette.gray_500 : "currentColor"; // Use currentColor to maintain the default color

        return (
            <Typography variant="body1" style={{ color: buttonTextColor }}>
                {placeholder}
            </Typography>
        ); // If no valid title to display, fall back to the default string
    };
    const getOptions = () => {
        if (step === 'section') return data;
        if (step === 'shelf') return selectedSection?.children || [];
        if (step === 'box') return selectedShelf?.children || [];
        return [];
    };
    const renderSearchResults = () => {

        return (
            <>
                <List sx={{ maxHeight: "420px", overflowY: "auto", top: "0px", pb: "24px" }}>
                    {["section", "shelf", "box"].map(type => {
                        const items = searchResults.filter(item => item.type === type);
                        if (items.length === 0) return null;
                        return (
                            <div key={type}>
                                <Stack height={"24px"} sx={{ padding: "0 48px", background: background }}>
                                    <Typography variant="h6" color={colorPalette.gray_500}>
                                        {type.charAt(0).toUpperCase() + type.slice(1) + "s"}
                                    </Typography>
                                </Stack>
                                {items.map((item, index) => (
                                    <ListItem button key={index} onClick={() => handleListItemClick(item)}>
                                        <ListItemIcon sx={{ width: "32px", minWidth: "unset" }} color={colorPalette.Blue_400}>
                                            {getIconForType(item.type)}
                                        </ListItemIcon>
                                        <Typography variant="body1" color={colorPalette.black}>
                                            {item.title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </div>
                        );
                    })}
                </List>
                {/* Check if there is no exact match in the search results */}
                {searchResults.every(item => item.title !== searchTerm) && (operatingMode === 'select') ? (
                    <ListItem button key='add-item'
                        sx={{ height: '48px' }}
                        onClick={() => handleNewItemFlow('create')}>
                        <ListItemIcon sx={{ width: "32px", minWidth: "unset" }} color={colorPalette.Blue_400}>
                            <AddIcon style={{ color: colorPalette.Blue_A400 }} />
                        </ListItemIcon>
                        <Typography variant="body1" color={colorPalette.Blue_A400}>
                            Add {searchTerm}
                        </Typography>

                    </ListItem>
                ) : null}


            </>
        );
    };
    const getTitleForStep = (step: 'section' | 'shelf' | 'box') => {
        if (step === 'shelf') return 'Your Shelves';
        if (step === 'box') return 'Your Boxes';
        return 'Your Sections';
    };
    const handleNewItemFlow = (mode: 'create' | 'select') => {
        setNewItem(searchTerm);
        setSearchTerm('');
        setOperatingMode(mode);
        setMoveToDestinationEndItem(null);
        setSelectedSection(null);
        setSelectedShelf(null);
        setStep('section');
        if (selectMode === 'selectOnly')
            setSelectedBox(null);
    }
    const handleCreateNewCategory = () => {
        let parentId = '';
        let categoryTypeValue = '';
        if (moveToDestinationEndItem) {
            parentId = moveToDestinationEndItem.id || '';
            const typeIndex = categoryTypes.indexOf(moveToDestinationEndItem.type);
            if (typeIndex !== -1 && typeIndex < categoryTypes.length - 1) {
                categoryTypeValue = categoryTypes[typeIndex + 1];
            }
        } else if (selectedShelf) {
            parentId = selectedShelf.id || '';
            const typeIndex = categoryTypes.indexOf(selectedShelf.type);
            if (typeIndex !== -1 && typeIndex < categoryTypes.length - 1) {
                categoryTypeValue = categoryTypes[typeIndex + 1];
            }
        } else if (selectedSection) {
            parentId = selectedSection.id || '';
            const typeIndex = categoryTypes.indexOf(selectedSection.type);
            if (typeIndex !== -1 && typeIndex < categoryTypes.length - 1) {
                categoryTypeValue = categoryTypes[typeIndex + 1];
            }
        } else {
            categoryTypeValue = 'main'; // Default to main category type if no section is selected
        }
        if (handleNewCategory)
            handleNewCategory(newItem, parentId, categoryTypeValue);
        setOpen(false);
        if (selectMode === 'selectOnly') {
            console.log('3selectOnly mode, setting new flow');
            handleNewItemFlow('select');
        }

    };

    const switchToPopupSelect = () => {
        setOpen(false);
        setMoveToDestinationEndItem(null);
        setNewItem('');
        setStep('section');
        setOperatingMode('select');
    }

    return (
        <div>
            <Button
                ref={anchorEl}
                onClick={() => {
                    setOpen(true);
                    setStep('section');
                    setSelectedSection(null);
                    setSelectedShelf(null);
                }}
                style={{
                    background: background,
                    width: '100%',
                    borderRadius: '8px',
                    padding: '0 1em',
                    height: 'auto',
                    minHeight: '56px',
                    minWidth: '150px',
                    justifyContent: 'left',
                    boxShadow: isButtonHovered ? `inset 0 0 0 2px ${colorPalette.Blue_A400}` : 'none',
                    color: placeholder === 'No item added' ? colorPalette.gray_500 : colorPalette.Blue_A400, // Set the color based on the placeholder

                }}
                onMouseEnter={() => setIsButtonHovered(true)} // Set hover state to true on mouse enter
                onMouseLeave={() => setIsButtonHovered(false)} // Set hover state to false on mouse leave
            >
                {getButtonLabel()}
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl.current}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        maxHeight: '720px',
                        minHeight: '320px',
                        width: '360px',
                        overflow: 'hidden',  // For adding a scrollbar if content exceeds the max height
                        paddingRight: 0
                    },
                }}
            >
                {operatingMode === 'select' ? (
                    <>
                        <Stack direction={'column'} gap={1} sx={{ position: 'relative', top: 0 }}>
                            {selectedBox ? (
                                <>
                                    <Stack id='current-selection' direction={'column'} padding={2} gap={1}>
                                        <Typography variant='h4'>Change {selectText}</Typography>
                                        <Typography variant='h6'>Current selection</Typography>
                                        <Stack p={1}
                                            border={1}
                                            borderColor={colorPalette.gray_400}
                                            borderRadius={'4px'}
                                            sx={{ background: background }}>
                                            <Typography variant='body1'>{selectedBox?.title}</Typography>
                                            <Stack direction={'row'} gap={0.5}>
                                                {selectedBox?.grandparent ? (
                                                    <Typography
                                                        color={colorPalette.gray_600}
                                                        variant='body2'>
                                                        {selectedBox?.grandparent ? selectedBox?.grandparent : ''}
                                                    </Typography>
                                                ) : null}
                                                {selectedBox?.grandparent ? (
                                                    <Typography
                                                        color={colorPalette.gray_500}
                                                        variant='body2'>
                                                        {selectedBox?.grandparent ? ' > ' : ''}
                                                    </Typography>
                                                ) : null}
                                                <Typography
                                                    color={colorPalette.gray_800}
                                                    variant='body2'>
                                                    {selectedBox?.parent}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </>
                            ) : null}
                            {!selectedBox ? (
                                <Stack id='current-selection' direction={'column'} padding={2} gap={1}>
                                    <Typography variant='h4'>Add {selectText}
                                    </Typography>
                                </Stack>
                            ) : null}
                            <Stack gap={2} style={{ padding: '0px 16px' }}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    placeholder="Search or add new"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />

                            </Stack>
                            {
                                !searchTerm.length ? (
                                    <>
                                        <Stack
                                            padding={'8px 4px 4px 16px'}
                                            width={'100%'}
                                            sx={{ background: colorPalette.Cyan_050 }}>
                                            <Typography variant="h6" color={colorPalette.Cyan_600}>
                                                {getTitleForStep(step)} {/* Set the popover title based on the current step */}
                                            </Typography>
                                        </Stack>
                                        {flatData.length === 0 && (
                                            <Stack p={2}>
                                                <Stack
                                                    spacing={2}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    border={`2px dashed ${colorPalette.gray_300}`}
                                                    sx={{ padding: '20px' }}>
                                                    {selectText === 'merchant' ? (<Inventory2TwoToneIcon />) : (<StorefrontTwoToneIcon />)}

                                                    <Typography variant="h6" color={colorPalette.gray_800}>No {selectText}s added</Typography>
                                                    <Typography variant="body2" color={colorPalette.gray_500} textAlign={'center'}>
                                                        Add your first {selectText} category by adding name in the search
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                    </>
                                ) : null
                            }
                        </Stack>
                        {searchTerm.trim() ? renderSearchResults() : (
                            <List sx={{ maxHeight: '420px', overflowY: 'auto', top: '0px', pb: '24px' }}>
                                {step !== 'section' && (
                                    <ListItem button onClick={handleBack}
                                        sx={{
                                            height: '53px'
                                            // Optional for rounded edges
                                        }}>
                                        <ListItemIcon sx={{ width: '32px', minWidth: 'unset' }}>
                                            <ArrowBackIcon fontSize="small" style={{ color: colorPalette.gray_500 }} />
                                        </ListItemIcon>
                                        <Typography variant="body2" color={colorPalette.gray_500}>
                                            Go Back
                                        </Typography>
                                    </ListItem>
                                )}
                                {(step === 'shelf' || step === 'box') && (
                                    <ListItem
                                        button
                                        onClick={() => handleListItemClick(step === 'shelf' ? selectedSection! : selectedShelf!)}
                                        sx={{
                                            background: colorPalette.Blue_050,
                                            '&:hover': { background: colorPalette.Blue_200 },
                                            borderRadius: '4px',
                                            height: '53px'
                                            // Optional for rounded edges
                                        }}
                                    >
                                        <ListItemIcon sx={{ width: '32px', minWidth: 'unset' }} color={colorPalette.Blue_400}>
                                            {getIconForType(step, colorPalette.Blue_400)}
                                        </ListItemIcon>
                                        <Typography
                                            variant="body1"
                                            color={colorPalette.Blue_800}
                                            onClick={() => {
                                                if (step === 'shelf' && selectedSection) {
                                                    handleSelectItemClick(selectedSection);
                                                } else if (step === 'box' && selectedShelf) {
                                                    handleSelectItemClick(selectedShelf);
                                                }
                                            }}
                                        >
                                            {`Select ${step === 'shelf' ? selectedSection?.title : selectedShelf?.title}`}
                                        </Typography>
                                    </ListItem>
                                )}
                                {getOptions().map((option: DataType, index: number) => (
                                    <ListItem button key={index} sx={{
                                        height: '64px'
                                        // Optional for rounded edges
                                    }}
                                        onClick={() => handleListItemClick(option)}>
                                        <ListItemIcon sx={{ width: '32px', minWidth: 'unset' }}>
                                            {getIconForType(option.type)}
                                        </ListItemIcon>
                                        <Stack>
                                            <Typography variant="body1" color={colorPalette.black}>
                                                {option.title}
                                            </Typography>
                                            <Typography variant="body2" color={colorPalette.gray_500}>
                                                {`${option.children?.length || 0} ${option.type === 'section' ? 'shelves' : 'boxes'}`}
                                            </Typography>
                                        </Stack>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </>
                ) :
                    <>
                        {operatingMode === 'create' ? (
                            <>
                                <Stack direction={'column'} gap={1} sx={{ position: 'relative', top: 0 }}>
                                    {selectedBox ? (
                                        <>
                                            <Stack id='current-selection' direction={'column'} padding={2} gap={1}>
                                                <Typography variant='h4'>Add new item '{newItem}'</Typography>
                                                <Typography variant='body1' color={colorPalette.Cyan_700}>Please select a section or shelf to place new item</Typography>
                                            </Stack>
                                        </>
                                    ) : null}
                                    {!selectedBox ? (
                                        <Stack id='current-selection' direction={'column'} padding={2} gap={1}>
                                            <Typography variant='h4'>Add {selectText}
                                            </Typography>
                                        </Stack>
                                    ) : null}
                                    {step !== 'section' && (
                                        <ListItem button onClick={handleBack}
                                            sx={{
                                                height: '40px'
                                                // Optional for rounded edges
                                            }}>
                                            <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                                                <ArrowBackIcon fontSize="small" style={{ color: colorPalette.Blue_A400 }} />
                                                <Typography variant="body1" color={colorPalette.Blue_A400}>
                                                    Go Back
                                                </Typography>
                                            </Stack>
                                        </ListItem>
                                    )}
                                    <Stack
                                        padding={'8px 4px 4px 16px'}
                                        width={'100%'}
                                        sx={{ background: colorPalette.Cyan_050 }}>
                                        <Typography variant="h6" color={colorPalette.Cyan_600}>
                                            {getTitleForStep(step)} {/* Set the popover title based on the current step */}
                                        </Typography>
                                    </Stack>

                                </Stack>

                                {searchTerm.trim() ? renderSearchResults() : (
                                    <List sx={{ maxHeight: '420px', overflowY: 'auto', top: '0px', pb: '24px' }}>
                                        {getOptions().map((option: DataType, index: number) => (
                                            <ListItem button key={index} sx={{
                                                height: '64px',
                                                // Optional for rounded edges
                                                background: moveToDestinationEndItem?.id === option.id ? colorPalette.Blue_050 : colorPalette.white
                                            }}
                                                onClick={() => handleMoveToDestinationClick(option)}>
                                                <ListItemIcon sx={{ width: '32px', minWidth: 'unset' }}>
                                                    {getIconForType(option.type)}
                                                </ListItemIcon>
                                                <Stack>
                                                    <Typography variant="body1" color={colorPalette.black}>
                                                        {option.title}
                                                    </Typography>
                                                    <Typography variant="body2" color={colorPalette.gray_500}>
                                                        {`${option.children?.length || 0} ${option.type === 'section' ? 'shelves' : 'boxes'}`}
                                                    </Typography>
                                                </Stack>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                                {operatingMode === 'create' ? (
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        gap={1}
                                        justifyContent={'space-between'}
                                        sx={{ height: '56px', background: colorPalette.gray_800 }}
                                        padding={2}
                                        borderTop={2}
                                        borderColor={colorPalette.gray_300}
                                    >
                                        {moveToDestinationEndItem ? (
                                            <>
                                                <Typography variant="body2" color={colorPalette.white}>
                                                    {`Add  ${categoryTypes[categoryTypes.indexOf(moveToDestinationEndItem.type) + 1]} ${newItem} to ${moveToDestinationEndItem.title} `}
                                                </Typography></>) : (<>
                                                    {(step === 'section') ? (
                                                        <Typography variant="body2" color={colorPalette.white}>
                                                            {`Add ${newItem} as a Section`}
                                                        </Typography>
                                                    ) :
                                                        <Typography variant="body2" color={colorPalette.white}>
                                                            {`Add ${categoryTypes[categoryTypes.indexOf(step)]} to ${step === 'shelf' ? selectedSection?.title : selectedShelf?.title}`}
                                                        </Typography>
                                                    }
                                                </>)}


                                        <Button size='small'
                                            variant='contained'
                                            sx={{ color: colorPalette.black, background: colorPalette.white, cursor: 'pointer' }}
                                            onClick={() => handleCreateNewCategory()}
                                        >
                                            Add
                                        </Button>
                                        <Button size='small'
                                            variant='text'
                                            sx={{ color: colorPalette.white, cursor: 'pointer' }}
                                            onClick={() => switchToPopupSelect()}
                                        >
                                            Close
                                        </Button>

                                    </Stack>
                                ) :
                                    null}
                            </>
                        ) : null}
                    </>

                }
            </Popover>
        </div>
    );

};

export default CocoCategorySearch;