import axios from 'axios';
import { Category } from '../interfaces/category';
import { validateUser } from '../services/validateUser';
import { TreeDataItem } from '../interfaces/category';
import { DataType } from '../components/CocoCategorySearch';
import { hardParseFloat } from 'services/handyFunctions';


export const getAllCategories = async () => {
  const validated_user = await validateUser();
  if (validated_user) {
    const userId = validated_user.user_pack.id;
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/categories/getallcategories/${userId}`);
    const categories = response.data;
    return categories;
  } else {
    return [];
  }
};


export const getAllExpenseCategories = async () => {
  const validated_user = await validateUser();
  if (validated_user) {
    const userId = validated_user.user_pack.id;
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/categories/getallexpensecategories/${userId}`);
    const categories = response.data;
    return categories;
  } else {
    return [];
  }
};

export const getAllCategoriesAsSections = async (categoryType: "income" | "expense" | "merchant"): Promise<DataType[]> => {
  const categoriesArray = await getAllCategories();
  const categories: Category[] = categoriesArray?.categories || [];

  // Define a mapping of category types to their corresponding main, section, shelf, or box values
  const typeMappings: Record<string, "section" | "shelf" | "box"> = {
    'income-section': 'section',
    'income-shelf': 'shelf',
    'income-box': 'box',
    'expense-section': 'section',
    'expense-shelf': 'shelf',
    'expense-box': 'box',
    'merchant-section': 'section',
    'merchant-shelf': 'shelf',
    'merchant-box': 'box',
  };

  const mainCategories: DataType[] = [];

  const buildTree = (parentId: string | null): DataType[] => {
    const children: DataType[] = [];
    for (const category of categories) {
      if (category.parentCategory === parentId) {
        const child: DataType = {
          title: category.name,
          type: typeMappings[category.type] || 'section', // Use typeMappings
          id: category._id,
          budgetStatus: category.budgetStatus,
          budgetAmount: hardParseFloat(category.budgetAmount),
          children: buildTree(category._id),
        };
        children.push(child);
      }
    }
    return children;
  };

  const mainCategoryType = `${categoryType}-main`;

  // Find the main categories and add them to the mainCategories array
  for (const category of categories) {
    if (category.type === mainCategoryType) {
      const mainCategory: DataType = {
        title: category.name,
        type: typeMappings[category.type] || 'section',
        id: category._id,
        budgetStatus: category.budgetStatus,
        budgetAmount: hardParseFloat(category.budgetAmount),
        children: buildTree(category._id),
      };
      mainCategories.push(mainCategory);
    }
  }

  return mainCategories;
};


export const getAllCategoriesAsTree = async (): Promise<TreeDataItem[]> => {
  const categoriesArray = await getAllCategories();

  const categories: Category[] = categoriesArray?.categories || [];

  const buildTree = (parentId: string | null): TreeDataItem[] => {
    const children: TreeDataItem[] = [];
    for (const category of categories) {
      if (category.parentCategory === parentId) {
        const child: TreeDataItem = {
          _id: category._id,
          name: category.name,
          type: category.type,
          budgetStatus: category.budgetStatus,
          budgetAmount: hardParseFloat(category.budgetAmount),
          children: buildTree(category._id),
        };
        children.push(child);
      }
    }
    return children;
  };

  const tree = buildTree(null);
  return tree;
};

export const editCategory = async (categoryId: string, newName: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/categories/${categoryId}`;

    const requestData = {
      name: newName,
    };

    const response = await axios.put(apiUrl, requestData);

    // Handle the response as needed
  } catch (error) {
    console.log('editCategory error:', error);
    // Handle the error as needed
  }
};

export const addNewCategory = async (categoryName: string, parentId: string | null, categoryType: string) => {
  try {
    if (!['income-main','income-section', 'income-shelf', 'income-box', 'expense-main','expense-section', 'expense-shelf', 'expense-box', 'merchant-main','merchant-section', 'merchant-shelf', 'merchant-box'].includes(categoryType)) {
      throw new Error('Invalid categoryType');
    }
    const validated_user = await validateUser();
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/categories/add`;
    let userId = validated_user?.user_pack?.id;
    const requestData = {
      userId,
      categoryName,
      parentId,
      categoryType
    };

    const response = await axios.post(apiUrl, requestData);
    // Handle the response as needed
    return response.data;

  } catch (error) {
    console.log('addNewCategory error:', error);
    // Handle the error as needed
    throw error;
  }
};

export const addAndAssignNewCategory = async (categoryName: string, parentId: string | null, subTransactionId: string) => {
  try {
    const validated_user = await validateUser();
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/categories/add-and-assign`;
    let userId = validated_user?.user_pack?.id;
    const requestData = {
      userId,
      categoryName,
      parentId,
      subTransactionId
    };

    const response = await axios.post(apiUrl, requestData);
    // Handle the response as needed
    return response.data;

  } catch (error) {
    console.log('addNewCategory error:', error);
    // Handle the error as needed
    throw error;
  }
};



export const deleteCategory = async (categoryId: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/categories/${categoryId}`;

    const response = await axios.delete(apiUrl);
    // Handle the response as needed
  } catch (error) {
    console.log('deleteCategory error:', error);
    // Handle the error as needed
  }
};

export const saveBudgetToCategory = async (categoryId: string, budgetAmount: string) => {
  try {
    const validated_user = await validateUser();

    const userId = validated_user?.user_pack.id;
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/categories/updatebudget/${userId}`;
    const requestData = {
      categoryId,
      budgetAmount,
    };

    const response = await axios.put(apiUrl, requestData);
    // Handle the response as needed
  } catch (error) {
    console.log('updateCategory error:', error);
    // Handle the error as needed
  }
};

export const moveToCategory = async (categoryId: string, newParentCategoryId: string) => {
  try {

    const validated_user = await validateUser();
    const userId = validated_user?.user_pack.id;

    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/categories/move-cat/${userId}`;
    const requestData = {
      categoryId,
      newParentCategoryId,
    };

    const response = await axios.post(apiUrl, requestData);
    // Handle the response as needed
  } catch (error) {
    console.log('moveToCategory error:', error);
    // Handle the error as needed
  }
};


export const getSuggestedCategories = async () => {

  const validated_user = await validateUser();

  const userId = validated_user?.user_pack.id;
  const url = `${process.env.REACT_APP_SERVER_URL}/api/categories/getsuggestedcategories/${userId}`;



  // Make the fetch request with the options
  const categorySuggestions = await fetch(url);
  return categorySuggestions;
}



export const handleUpdateCategory = async (categoryId: string, fieldType: string, newValue: string) => {

  const validated_user = await validateUser();
  const userId = validated_user?.user_pack.id;
  const url = `${process.env.REACT_APP_SERVER_URL}/api/categories/updatecategoryfield/${userId}`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      categoryId,
      fieldType,
      newValue,
    }),
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error('Failed to update category field');
    }
    const updatedCategory = await response.json();
    return updatedCategory;
  } catch (error) {
    console.error('Failed to update category field', error);
    throw error;
  }
};
