import React from 'react';
import { Typography, Divider, Box, Stack } from '@mui/material';
import TransactionFrame from './TransactionFrame';
import { Transaction } from 'interfaces/transaction';

type CategorizedTransactions = {
  Today: Transaction[];
  Yesterday: Transaction[];
  Older: Transaction[];
};

const RecentTransactions = ({ transactions }: { transactions: Transaction[] }) => {
  const categorizedTransactions: CategorizedTransactions = {
    Today: [],
    Yesterday: [],
    Older: [],
  };

  const isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const isYesterday = (date: Date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDate() === yesterday.getDate() &&
           date.getMonth() === yesterday.getMonth() &&
           date.getFullYear() === yesterday.getFullYear();
  };

  transactions.forEach((transaction) => {
    if (transaction.transactionDate) {
      const date = new Date(transaction.transactionDate);
      if (isToday(date)) {
        categorizedTransactions.Today.push(transaction);
      } else if (isYesterday(date)) {
        categorizedTransactions.Yesterday.push(transaction);
      } else {
        categorizedTransactions.Older.push(transaction);
      }
    }
  });

  const renderTransactions = (transactions: Transaction[], title: string) => (
    transactions.length > 0 && (
      <Stack direction="column" spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <Divider variant="middle" />
        <Stack direction="column" spacing={1}>
          {transactions.map((transaction) => (
            <TransactionFrame key={transaction.id} transaction={transaction} />
          ))}
        </Stack>
      </Stack>
    )
  );

  return (
    <Box>
      <Stack direction="column" spacing={3}>
        {renderTransactions(categorizedTransactions.Today, 'Today')}
        {renderTransactions(categorizedTransactions.Yesterday, 'Yesterday')}
        {renderTransactions(categorizedTransactions.Older, 'Older')}
      </Stack>
    </Box>
  );
};

export default RecentTransactions;
