import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, Box, Card, CardContent, Typography, TextField, Button, Avatar } from '@mui/material';
import './Chatstyle.css';
import colorPalette from 'theme/colorPalette';
import { validateUser } from 'services/validateUser';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { height } from '@pankod/refine-mui';
import CoinsAvatar from '../assets/logo/logo.jpeg';
import { AnimatedContainer } from '../theme/animations';
const MODEL_ID = 'gpt-3.5-turbo';

interface Message {
  role: 'system' | 'user' | 'assistant';
  content: string;
}


interface SampleQuestionCardProps {
  question: string;
  description: string;
  onSelect: (question: string) => void;
}

const SampleQuestionCard: React.FC<SampleQuestionCardProps> = ({ question, description, onSelect }) => {
  return (
    <Card className="question-card" sx={{ width: 'calc(50% - 16px)', position: 'relative' }} onClick={() => onSelect(question)}>
      <CardContent>
        <Typography variant="body1" component="h4">
          {question}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <div className="overlay">
        {/* Replace with the icon you want to use */}
        <div className="icon-container"><SendIcon sx={{ fontSize: '16px' }} /></div>
      </div>
    </Card>
  );
};

const ChatWithGPT = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isThinking, setIsThinking] = useState(false); // New state for tracking AI processing
  const [validatedUser, setValidatedUser] = useState<{ user_pack: { id: any; email: any; avatar: any; name: string; } } | undefined>(undefined);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    (async () => {
      const validated_user = await validateUser();
      setValidatedUser(validated_user);
    })();
  }, []);

  const sampleQuestions = [
    {
      question: 'How can I optimize my tax deductions?',
      description: 'Inquire about strategies to maximize tax deductions and reduce taxable income.'
    },
    {
      question: 'Is it better for me to lease or buy a company car?',
      description: 'Seek advice on the financial implications of leasing versus buying a company vehicle.'
    },
    {
      question: 'Can you explain my cash flow statement?',
      description: 'Request a detailed breakdown and understanding of the cash flow statement to better manage finances.'
    },
    {
      question: 'What are the best retirement savings plans for my situation?',
      description: 'Discuss various retirement saving options and find out which is most suitable for your financial goals and circumstances.'
    },
  ];
  const rows = [];
  for (let i = 0; i < sampleQuestions.length; i += 2) {
    rows.push(sampleQuestions.slice(i, i + 2));
  }

  const handleQuestionSelect = async (question: string) => {
    console.log(question);
    await sendMessage(question); // Send the message immediately    
  };

  const scrollToBottom = () => {
    const messageContainer = document.getElementById('message-container');
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  };

  const sendMessage = async (message?: any) => {
    console.log("Sending message", message, "OR", inputValue);
    if (inputValue.trim() === '' && !message) {
      return;
    }

    setIsThinking(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: message ? message : inputValue },
    ]);

    try {
      const validated_user = await validateUser();
      if (validated_user) {
        const userId = validated_user.user_pack.id;

        // Make an API call to your Express server
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/${userId}`, {
          userMessage: message ? message : inputValue,
          // Include any other data you need to send to the server
        });

        // Handle the response from the server (update messages, etc.)
        console.log("Helloo", response.data);
        const assistantResponse = response.data.message;
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: assistantResponse },
        ]);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
    setIsThinking(false);
    setInputValue('');
  };


  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <Box sx={{ background: colorPalette.gray_100, overflow: 'auto', height: '100vh' }}>
      <Box className="chat-container">
        <AnimatedContainer>
          <div id="message-container" className="message-container">
            {messages.length === 0 && (
              <>
                <Stack alignItems='center' className="header-content">
                  <img src={CoinsAvatar} width={'64px'} alt="Logo" />
                  <h2>How can I help you today?</h2>
                </Stack>
                <Stack direction={'column'} gap={1}>
                  {rows.map((row, rowIndex) => (
                    <Stack key={rowIndex} direction="row" spacing={2} justifyContent="center">
                      {row.map((question, index) => (
                        <SampleQuestionCard key={index} {...question} onSelect={handleQuestionSelect} />
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </>
            )}
            {messages.map((message, index) => (
              <Stack key={index} direction={'column'} gap={0} className={`message ${message.role}`}>
                <Stack direction="row" spacing={2} alignItems="center" className="message-header">
                  <Avatar
                    src={message.role === 'assistant' ? CoinsAvatar : validatedUser?.user_pack.avatar}
                    sx={{ height: '28px', width: '28px' }} />
                  <Typography variant="body1">{message.role === 'user' ? 'You' : 'Assistant'}</Typography>
                </Stack>
                <Box ml={5} className="message-body">
                  {/* Encapsulate the message content with ReactMarkdown */}
                  <ReactMarkdown className={`message-content ${message.role}-content`} remarkPlugins={[remarkGfm]}>
                    {message.content}
                  </ReactMarkdown>
                </Box>
              </Stack>
            ))}
          </div>

        </AnimatedContainer>
        <div className="input-container">
          <TextField
            fullWidth
            variant="outlined"
            multiline
            maxRows={4}
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            placeholder={isThinking ? "Thinking..." : "Type your message..."}
            disabled={isThinking}
            style={{ margin: '8px 0', width: '100%' }}
          />
          <Button
            variant="contained"
            onClick={sendMessage}
            disabled={isThinking}
            sx={{ height: '40px' }}
          >
            Send
          </Button>
        </div>
      </Box>
    </Box>
  );

};

export default ChatWithGPT;
