import React, { useEffect, useState } from 'react'
import { Typography, Stack, Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import { MuiOtpInput } from 'mui-one-time-password-input'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLogin } from "@refinedev/core";
import ResentOtp from '../components/ResentOtp'

interface LoginOtpProps {
  email: string,
  otpPrompt: boolean,
  handleOtpPrompt: () => void,
  handleOtpSubmit: (otp: any) => void,
  handleVerifyOtpSubmit: () => void
}

const LoginOtp: React.FC<LoginOtpProps> = ({
  email,
  otpPrompt,
  handleOtpPrompt,
  handleOtpSubmit,
  handleVerifyOtpSubmit
}) => {
  // const [otpPrompt, setOtpPrompt] = useState(false); // New state variable for OTP prompt
  const [otp, setOtp] = useState(""); // New state variable for OTP
  // const [email, setEmail] = useState("");

  const { mutate: check } = useLogin();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);

  //   const handleResentOtpSubmit =() =>{
  //     handleVerifyOtpSubmit()
  //   }
  const handleOtpChange = (newValue: string) => {

    setOtp(newValue)
  }

  return (
    <Grid container component="main" sx={{ height: '10vh' }}>
      <Stack
        direction="column"
        alignItems="center"
        spacing={1}
        width={'100%'}
      >
        <Zoom in={otpPrompt} style={{ transitionDelay: '300ms' }}>
          <MuiOtpInput TextFieldsProps={{ size: 'small' }}
            width={'100%'}
            gap={1} length={6}
            autoFocus
            validateChar={(character: string, index: number) => true}
            value={otp} onChange={handleOtpChange} />
        </Zoom>
        <Zoom in={otpPrompt} style={{ transitionDelay: '300ms' }}>
          <div style={{width: '100%'}}>
            <ResentOtp handleVerifyOtpSubmit={handleVerifyOtpSubmit} />
          </div>
        </Zoom>

        <Zoom in={otpPrompt} style={{ transitionDelay: '300ms' }}>
          <div>
            <Button variant="contained" onClick={() => handleOtpSubmit(otp)}>
              Submit OTP
            </Button>
          </div>

        </Zoom>


      </Stack>
    </Grid>)
}

export default LoginOtp