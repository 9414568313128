import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRive, Layout, Fit, Alignment } from "rive-react";
import {
    Stack,
    Typography,
} from "@mui/material";
import colorPalette from 'theme/colorPalette';

interface LoaderAnimationProps {
    loadingText?: string;
    subtitle?: (string | null | undefined)[]; // Accept subtitle as an array of strings, null, or undefined
}

const LoaderAnimation: React.FC<LoaderAnimationProps> = ({ loadingText = "You don't have any data", subtitle }) => {
    const { RiveComponent } = useRive({
        src: "/rive/impatient_placeholder.riv",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    const riveStyle: React.CSSProperties = {
        height: '200px',
        width: '200px',
        borderRadius: '12px', // smooth cornering
        overflow: 'hidden', // clip the animation to fit the rounded corners
    };

    const textContainerStyle: React.CSSProperties = {
        maxWidth: '640px', // Adjust the value as needed
        textAlign: 'center',
    };


    const [randomSubtitle, setRandomSubtitle] = useState<string | undefined>(undefined);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (subtitle && subtitle.length > 0) {
            interval = setInterval(() => {
                const randomIndex = Math.floor(Math.random() * subtitle.length);
                setRandomSubtitle(subtitle[randomIndex] || undefined);
            }, 4000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [subtitle]);

    return (
        <div style={containerStyle}>
            <Stack spacing={4} alignItems={"center"}>
                <div style={riveStyle}>
                    <RiveComponent />
                </div>
                <Typography variant="h4" style={{ color: colorPalette.black, ...textContainerStyle }}>
                    {loadingText}
                </Typography>
                {randomSubtitle !== undefined && (
                    <Typography variant="body1" style={{ color: colorPalette.gray_400, ...textContainerStyle }}>
                        {randomSubtitle}
                    </Typography>
                )}
            </Stack>
        </div>
    );
};

// Custom prop type validator for an array of strings, including null and undefined
const subtitleArrayValidator = (props: any, propName: string, componentName: string) => {
    const subtitleArray = props[propName];

    if (subtitleArray === null || subtitleArray === undefined) {
        return null; // Allow null and undefined values
    }

    if (!Array.isArray(subtitleArray)) {
        return new Error(`Invalid prop ${propName} supplied to ${componentName}. Expected an array.`);
    }

    for (const subtitle of subtitleArray) {
        if (typeof subtitle !== 'string' && subtitle !== null && subtitle !== undefined) {
            return new Error(`Invalid prop ${propName} supplied to ${componentName}. Array should only contain strings, null, or undefined.`);
        }
    }

    return null;
};

LoaderAnimation.propTypes = {
    loadingText: PropTypes.string,
    subtitle: subtitleArrayValidator,
};

export default LoaderAnimation;
