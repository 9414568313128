import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Tabs, Tab, Button, Modal, Stack, TextField, ListItem, ListItemIcon, Card, List, Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import colorPalette from 'theme/colorPalette';
import PlaidAccounts from '../components/plaid/PlaidAccounts';
import CocoField from '../components/CocoField'; // Assuming CocoField is properly imported
import { fetchUserData, updateUserProfile } from '../api-routers/SettingsRouter';
import Tooltip from '@mui/material/Tooltip';

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51NI9fdACeyc3YFec2OpR4JwcjlVIum5lJJyt7c5UaKbgR6Vggrz76UeZ0oj4m8bseyOqzc3zAu8Nk1R1QHqTMD3000xd6JVXWn');

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const Settings = () => {
  const [value, setValue] = React.useState(0);
  const [userProfile, setUserProfile] = useState({ name: '', email: '', subscriptionStatus: '' });
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation(); // useLocation to get the current path
  const [subscriptionActive, setSubscriptionActive] = useState(false);


  useEffect(() => {
    fetchUserData().then(data => {
      const subscriptionStatus = data.subscription && data.subscription.status ? data.subscription.status : null;
      setUserProfile({ name: data.name, email: data.email, subscriptionStatus });
      if (subscriptionStatus === 'active') {
        setSubscriptionActive(true);
      }
      setSaveEnabled(false);
    });
  }, []);

  // Logic to handle URL changes and set appropriate tab
  useEffect(() => {
    const { pathname, search } = location;
    const queryParams = new URLSearchParams(search);
    const sessionId = queryParams.get('session_id');

    if (pathname.includes('/settings/subscription/return') && sessionId) {
      setSubscriptionActive(true); // Assume payment success for example
      setModalOpen(false);
    }

    if (pathname.includes('/subscription')) {
      setValue(1);
    }
  }, [location]);


  const fetchClientSecret = useCallback(async () => {
    try {
      // Fetch user data and await the response before proceeding
      const data = await fetchUserData();
      console.log('data', data);

      // Prepare the userInfo with fetched data
      const userInfo = {
        name: data.name,
        email: data.email,
        subscriptionStatus: data.subscription.status
      };

      console.log('userInfo', userInfo);

      // Make the POST request to create a checkout session and await the response
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/stripe/create-checkout-session`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: userInfo.email })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { clientSecret } = await response.json();
      return clientSecret;
    } catch (error) {
      console.error('Error fetching client secret:', error);
      // Handle errors appropriately in your context, potentially re-throwing them or returning null/undefined
      return null;
    }
  }, []);


  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleFormChange = (field: any) => (event: any) => {
    setUserProfile({ ...userProfile, [field]: event.target.value });
    setSaveEnabled(true);
  };

  const handleSaveChanges = () => {
    updateUserProfile(userProfile).then(() => {
      console.log('Profile updated successfully');
      setSaveEnabled(false);
    });
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <Stack
      sx={{
        bgcolor: colorPalette.gray_100,
        height: '100vh',
        p: 2,
        gap: 2,
        alignItems: 'center'
      }}
    >
      <Box
        maxWidth="1180px"
        sx={{ bgcolor: colorPalette.white }}
        className={`shadowStylePaper ${modalOpen ? 'animate__animated animate__fadeOutUp animate__faster' : 'animate__animated animate__fadeIn'}`}
        mt={4}
      >
        <Stack
          direction={'row'}
          gap={3}
          alignContent='left'
          sx={{
            minHeight: '50vh',
          }}
        >
          <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} aria-label="Vertical tabs" sx={{ pl: 2, background: colorPalette.gray_050, borderRight: 0 }}>
            <Tab sx={{ mt: 2 }} label="User Profile" {...a11yProps(0)} />
            <Tab label="Subscription" {...a11yProps(1)} />
            <Tab label="Accounts" {...a11yProps(2)} />
            <Tab label="Support" {...a11yProps(3)} />
          </Tabs>
          <TabPanel className='settings-panel' value={value} index={0}>
            <Stack gap={2} mt={3} className={'settings-card-ring'}>
              <Typography variant='h3'>Profile</Typography>
              <Divider></Divider>
              <Stack mt={2} direction='row' gap={2}>
                <Typography variant='body2' alignContent={'center'} className='settings-label'>Full Name</Typography>
                <TextField
                  value={userProfile.name}
                  onChange={handleFormChange('name')}
                  placeholder="Enter Name"
                />
              </Stack>
              <Stack direction='row' gap={2}>
                <Typography variant='body2' alignContent={'center'} className='settings-label'>Email</Typography>
                <Tooltip title="You cannot change the registered email" placement="right">
                  <div> {/* Tooltip does not directly work on disabled elements, hence the div wrapper */}
                    <TextField
                      value={userProfile.email}
                      disabled
                      onChange={() => handleFormChange('email')}
                      placeholder="Enter Email"
                      fullWidth
                    />
                  </div>
                </Tooltip>
              </Stack>
              <Button
                variant="contained"
                sx={{ alignSelf: 'flex-start', mt: 4 }}
                onClick={handleSaveChanges}
                disabled={!isSaveEnabled}
              >
                Save changes
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel className='settings-panel' value={value} index={1}>
            <Stack gap={2} mt={3} className={'settings-card-ring'}>
              <Typography variant='h3'>Subscription</Typography>
              <Divider></Divider>
              {subscriptionActive ? (
                <Stack
                  sx={{
                    backgroundColor: colorPalette.gray_900,
                    borderRadius: '12px',
                    width: '100%',
                    p: 2,
                    pl: 4,
                    display: 'flex',
                    maxWidth: '480px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2,
                    color: '#fff'
                  }}
                >
                  <Stack direction={'row'} gap={1}>
                    <Typography variant='h5' color={colorPalette.white}>
                      Pro Plan
                    </Typography>
                    <Typography variant='h5' color={colorPalette.white} sx={{ background: colorPalette.Purple_600, p: '2px 8px', borderRadius: 8 }}>
                      Active
                    </Typography>
                  </Stack>
                  <Typography variant='h2' color={colorPalette.white} sx={{ fontWeight: 'bold' }}>
                    $8/month
                  </Typography>

                  <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>Includes all Free features</Typography></Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>Store data for unlimited time</Typography></Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>30 messages per day with Chat Assistant</Typography></Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>Premium Support</Typography></Box>
                  <Divider></Divider>
                  <Stack direction={'row'} gap={2}>
                    <Button
                      variant='text'
                      sx={{ color: colorPalette.gray_500 }}
                      onClick={handleModalOpen}
                    >
                      Cancel subscription
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction={'row'} gap={2}>
                  {/* Free Version Card */}
                  <Stack
                    sx={{
                      border: '1px solid',
                      borderColor: colorPalette.gray_300,
                      borderRadius: '12px',
                      p: 2,
                      pl: 4,
                      width: '100%',
                      maxWidth: '280px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 2,
                      backgroundColor: '#fff'
                    }}
                  >
                    <Typography variant='h6' color="text.primary" sx={{ fontWeight: 'medium' }}>
                      Free Plan
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'success.main' }}>
                      Current Subscription
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon color="success" /><Typography sx={{ ml: 1 }} variant='body2'>Monthly Budgeting</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon color="success" /><Typography sx={{ ml: 1 }} variant='body2'  >Cashflow Tracking</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon color="success" /><Typography sx={{ ml: 1 }} variant='body2'>Health Tracking</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon color="success" /><Typography sx={{ ml: 1 }} variant='body2'>Personalized AI Categorization</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon color="success" /><Typography sx={{ ml: 1 }} variant='body2'>Connect Multiple Accounts</Typography></Box>
                    <Divider></Divider>
                    <Button
                      variant='outlined'
                      onClick={handleModalOpen}
                      size='large'
                      sx={{ alignSelf: 'center' }}
                    >
                      Start Free
                    </Button>
                  </Stack>
                  {/* Pro Version Card */}
                  <Stack
                    sx={{
                      backgroundColor: colorPalette.gray_900,
                      borderRadius: '12px',
                      width: '100%',
                      p: 2,
                      pl: 4,
                      display: 'flex',
                      maxWidth: '280px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 2,
                      color: '#fff'
                    }}
                  >


                    <Typography variant='h5' color={colorPalette.white}>
                      Pro Plan
                    </Typography>
                    <Typography variant='h2' color={colorPalette.white} sx={{ fontWeight: 'bold' }}>
                      $8/month
                    </Typography>

                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>Includes all Free features</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>Store data for unlimited time</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>30 messages per day with Chat Assistant</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon /><Typography variant='body2' sx={{ ml: 1, color: colorPalette.white }}>Premium Support</Typography></Box>
                    <Divider></Divider>
                    <Button
                      variant='contained'
                      className='white-btn'
                      sx={{ background: colorPalette.white }}
                      onClick={handleModalOpen}
                    >
                      Upgrade Now
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </TabPanel>
          <TabPanel className='settings-panel' value={value} index={2}>
            <Stack gap={2} mt={3} className={'settings-card-ring'}>
              <Typography variant='h3'>Connected accounts</Typography>
              <Divider></Divider>
              <PlaidAccounts editMode={true} />
            </Stack>
          </TabPanel>
          <TabPanel className='settings-panel' value={value} index={3}>
            <Typography mt={3}>Support content</Typography>
          </TabPanel>
        </Stack>
      </Box>
      {modalOpen && (
        <Box
          className='shadowStylePaper animate__animated animate__fadeInUp animate__fast'
          sx={{
            position: 'absolute',
            top: modalOpen ? '4%' : '100%', // Start from below the screen if not open
            width: '100%',
            maxWidth: '1100px',
            bgcolor: colorPalette.Cyan_050,
            p: '0 0 24px 0',
            borderRadius: 2,
            zIndex: 1000, // Ensure it's above other content
            opacity: modalOpen ? 1 : 0,
            transition: 'top 0.3s ease-out, opacity 0.3s ease-out' // Adjust transition for top and opacity
          }}
        >
          <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ fetchClientSecret }}>
              <EmbeddedCheckout />
              <Button sx={{ ml: 10, color: colorPalette.white }} onClick={handleModalClose} variant='outlined'>Cancel Payment</Button>
            </EmbeddedCheckoutProvider>
          </div>
        </Box>
      )}
    </Stack>
  );
};

export default Settings;
