import React, { useState, useEffect } from "react";
import {
  Typography,
  Avatar,
  Stack,
  Chip,
  Button,
  TextField,
  Paper,
  Menu, MenuItem, IconButton, Skeleton, Box,
} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import colorPalette from "theme/colorPalette";
import SubTransactionTable from "../components/SubTransactionTable";
import Dropzone from "../components/DropZone";
import SubTransactionInputModal from "components/SubTransactionInputModal";
import { Transaction } from "interfaces/transaction";
import { SubTransaction } from "interfaces/subtransaction";
import {
  fetchSubTransactions,
  deleteTransaction,
  addSubTransactionManually,
  deleteSubTransaction,
  handleUpdateTransaction,
  updateSubTransactionField,
  handleImageUpload,
  createNewSubTransaction
} from "api-routers/TransactionsRouter";
import { getAllCategoriesAsSections, addNewCategory } from "api-routers/CategoriesRouter";
import { validateUser } from "services/validateUser";
import { hardParseFloat } from "services/handyFunctions";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles for the date selector library
import CocoField from '../components/CocoField';
import CocoCategorySearch from '../components/CocoCategorySearch';
import { DataType } from '../components/CocoCategorySearch';
import { toast } from 'react-toastify';
import CocoReceiptUpload from './CocoReceiptUpload';
import { AnimatedContainer } from '../theme/animations';


interface TransactionDetailsProps {
  transaction: Transaction;
  transactionStatus: string;
  isUpdated: () => void;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  transaction,
  transactionStatus,
  isUpdated,
}) => {

  const [validatedUser, setValidatedUser] = useState<{
    user_pack: { id: any; name: any; email: any; avatar: any };
  } | null>(null);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allSubTransactions, setAllSubTransactions] = useState<SubTransaction[]>([]);


  const [editedAmount, setEditedAmount] = useState(`${transaction.amountBreakdown?.totalCost ? transaction.amountBreakdown?.totalCost : 0}`);
  const [transactionName, setTransactionName] = useState(`${transaction.transactionName ? transaction.transactionName : ''}`);
  const [editedDate, setEditedDate] = useState(new Date(transaction.transactionDate ?? "1970-01-01").toISOString().split('T')[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [categoriesData, setCategoriesData] = useState<DataType[]>([]);
  const [merchantsData, setMerchantsData] = useState<DataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [uploadStatus, setUploadStatus] = useState('not uploaded');
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setIsLoading(true);  // Start loading
    // Your existing code...

    const fetchData = async () => {
      // Your fetch logic...
      setIsLoading(false);  // Stop loading once fetch completes
    };

    fetchData();
  }, [transaction]);

  useEffect(() => {
    const fetchData = async () => {
      const categoriesDataResponse = await getAllCategoriesAsSections("expense");
      setCategoriesData(categoriesDataResponse[0].children);

      const merchantsDataResponse = await getAllCategoriesAsSections("merchant");
      setMerchantsData(merchantsDataResponse[0].children);
    };

    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllCategoriesAsSections("expense");
      setCategoriesData(data[0].children);
    };

    fetchData();
  }, []);  // This ensures the data is fetched only once when the component mounts

  useEffect(() => {
    setIsLoading(true);
    const fetchValidatedUser = async () => {
      const validatedUser = await validateUser();
      if (validatedUser) {
        setValidatedUser(validatedUser);
      }
    };

    const fetchSubTransactionsData = async () => {
      if (transaction) {
        const allSubTransactions = await fetchSubTransactions(transaction.id);
        setAllSubTransactions(allSubTransactions);
        setTimeout(() => {
          setIsLoading(false);
        }, 600);
      }
    };

    fetchValidatedUser();
    setTransactionName(transaction.transactionName ? transaction.transactionName : '');

    setEditedAmount(`${transaction.amountBreakdown?.totalCost ? transaction.amountBreakdown?.totalCost : 0}`);
    fetchSubTransactionsData();



  }, [transaction]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const addAndCreateNewMechant = async (merchantName: string, parentId: string, categoryType: string) => {
    try {
      if (['main', 'section', 'shelf', 'box'].includes(categoryType)) {
        await addNewCategory(merchantName, parentId, 'merchant-' + categoryType);
        handleCategoryUpdate("merchantCategory", merchantName)
      }

    }
    catch (error) {
      console.error("Failed to add new merchant:", error);
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onCreateNewSubTransaction = async (fieldName: string, newValue: string): Promise<string> => {
    try {
      const newSubTransaction = await createNewSubTransaction(transaction.id, fieldName, newValue);
      isUpdated();
      return newSubTransaction._id;
    } catch (error) {
      console.error("Failed to create new sub transaction:", error);
      return '';
      // Handle error
    }
  };

  const handleDeleteTransaction = async (transactionId: string) => {
    try {
      await deleteTransaction(transactionId);
      isUpdated();
    } catch (error) {
      console.error("Failed to delete transaction:", error);
      // Handle error
    }
  };

  const handleViewReceipt = (receiptUrl?: string) => {
    if (receiptUrl) {
      window.open(receiptUrl, "_blank");
    }
  };

  const handleSubmitSubTransaction = async (subTransactionValues: any) => {
    try {
      const validatedUser = await validateUser();
      await addSubTransactionManually(subTransactionValues, transaction.id, validatedUser?.user_pack?.id);
      handleCloseModal();
      isUpdated();
    } catch (error) {
      console.error("Failed to add sub-transaction:", error);
      // Handle error
    }
  };

  // const handleDeleteSubTransaction = async (subTransactionId: string) => {
  //   try {
  //     // Remove the deleted sub-transaction from the state
  //     setAllSubTransactions((prevSubTransactions) =>
  //       prevSubTransactions.filter(
  //         (subTransaction) => subTransaction.id !== subTransactionId
  //       )
  //     );
  //     await deleteSubTransaction(subTransactionId);
  //   } catch (error) {
  //     console.error("Error deleting sub-transaction:", error);
  //   }
  // };

  const handleEditSubTransaction = async (
    transactionDetails: {
      rowId: string;
      cellType: string;
      oldValue: string;
      newValue: string;
    }
  ) => {
    try {
      const response = await updateSubTransactionField(transaction.id, transactionDetails.cellType, transactionDetails.rowId, transactionDetails.newValue);
      isUpdated();

    } catch (error) {
      console.error("Error editing sub-transaction:", error);
    }
  };

  // This function handles the update coming from CocoCategorySearch component
  const handleCategoryUpdate = async (fieldType: string, newValue: string) => {
    let existingValue;
    // Determine the existing value based on the fieldType
    if (fieldType === "category") {
      existingValue = transaction?.category?.[0]?.categoryName;
    } else if (fieldType === "merchantCategory") {
      existingValue = transaction.merchantCategory?.name;
    }

    // Check if newValue is different from the existing value
    if (existingValue !== newValue) {
      try {
        await handleUpdateTransaction(transaction.id, fieldType, newValue);
        isUpdated();  // assuming isUpdated will refetch or rerender necessary parts
        toast.success(`Updated ${fieldType}`);
      } catch (error) {
        toast.error(`Error updating ${fieldType}`);
        console.error("Error updating transaction field:", error);
      }
    } else {
      console.log(`${fieldType} is unchanged.`);
    }
  };

  const MetadataCard = (props: any) => (
    <Paper style={{ padding: '16px', border: `1px solid ${colorPalette.gray_300}`, borderRadius: '4px', width: '98%', minHeight: '120px' }}>
      {props.children}
    </Paper>
  );

  const handleFieldUpdate = async (fieldType: string, newValue: string) => {
    let existingValue;

    // Determine the existing value based on the fieldType
    switch (fieldType) {
      case 'amountBreakdown.totalCost':
        existingValue = `${transaction.amountBreakdown.totalCost}`;
        break;
      case 'amountBreakdown.tax1':
        existingValue = transaction.amountBreakdown?.tax1 ? `${transaction.amountBreakdown.tax1}` : "0";
        break;
      case 'transactionDate':
        existingValue = new Date(transaction.createdDate ?? "1970-01-01").toISOString().split('T')[0];
        break;
      case 'transactionName':
        existingValue = transaction.transactionName;
        break;
      default:
        // For other fieldTypes, you can add more cases or handle them accordingly
        break;
    }

    // Check if newValue is different from the existing value
    if (existingValue !== newValue) {
      try {
        await handleUpdateTransaction(transaction.id, fieldType, newValue);
        isUpdated();  // assuming isUpdated will refetch or rerender necessary parts
        toast.success(`Updated ${fieldType}`);
      } catch (error) {
        toast.error(`Error updating ${fieldType}`);
        console.error("Error updating transaction field:", error);
      }
    } else {
      console.log(`${fieldType} is unchanged.`);
    }
  };

  const renderTransactionInfo = () => {
    if (!transaction) return null;

    return (
      <div>
        {isLoading ? (
          <Stack direction="column" gap={3} justifyContent={"space-between"} width={"100%"}>
            <MetadataCard>
              <Stack className="transaction-info" gap={3} direction={"column"} width={"100%"}>
                <Stack direction="row" gap={1} width='100%' justifyContent={'space-between'}>
                  <Stack direction="column" gap={0.5} width="240px">
                    <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>
                      {transaction.transactionType === 'income' ? 'Who paid you?' : 'To Whom did you pay?'}
                    </Typography>

                    <CocoCategorySearch
                      {...(transaction.merchantCategory?.name ? { defaultValue: transaction.merchantCategory.name } : {})}
                      data={merchantsData} selectText="merchant"
                      placeholder="Add merchant"
                      onUpdate={(newValue) => handleCategoryUpdate("merchantCategory", newValue)}
                      handleNewCategory={(categoryName, parentId, categoryType) => addAndCreateNewMechant(categoryName, parentId, categoryType)}
                    />
                  </Stack>
                  <Stack direction={'row'} gap={2}>
                    <Stack direction="column" gap={0.5} width="120px">
                      <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>Total Amount</Typography>
                      <CocoField
                        variant="body1"
                        value={`${transaction.amountBreakdown?.totalCost ? transaction.amountBreakdown?.totalCost : 0}`}
                        pretext="$"
                        placeholder="Enter Amount"
                        handleSave={(newValue) => handleFieldUpdate('amountBreakdown.totalCost', newValue)}
                      />
                    </Stack>
                    <Stack direction="column" gap={0.5} width="180px">
                      <Typography marginLeft={2} variant="h5" color={colorPalette.gray_800}>Transaction date</Typography>
                      <CocoField
                        variant="body1"
                        type="date"
                        value={transaction.transactionDate ? transaction.transactionDate : "1970-01-01"}
                        placeholder={"Add date"}
                        handleSave={(newValue) => handleFieldUpdate('transactionDate', newValue)}
                      />
                    </Stack>
                  </Stack>
                </Stack>

              </Stack>
            </MetadataCard>
            <Stack direction="column" gap={1}>
              <Typography marginLeft={2} color={colorPalette.gray_800} variant="body1">
                Uploaded documents
              </Typography>
              <Box marginLeft={1} width={'480px'}>
                <Skeleton variant="rounded" width={100} height={100} />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack direction={'column'} gap={3} width={"100%"}>
            <MetadataCard>
              <Stack className="transaction-info" gap={3} direction={"column"} width={"100%"}>
                <Stack direction="row" gap={1} width='100%' justifyContent={'space-between'}>
                  <Stack direction="column" gap={0.5} width="240px">
                    <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>
                      {transaction.transactionType === 'income' ? 'Who paid you?' : 'To Whom did you pay?'}
                    </Typography>

                    <CocoCategorySearch
                      {...(transaction.merchantCategory?.name ? { defaultValue: transaction.merchantCategory.name } : {})}
                      data={merchantsData} selectText="merchant"
                      placeholder="Add merchant"
                      onUpdate={(newValue) => handleCategoryUpdate("merchantCategory", newValue)}
                      handleNewCategory={(categoryName, parentId, categoryType) => addAndCreateNewMechant(categoryName, parentId, categoryType)}
                    />
                  </Stack>
                  <Stack direction={'row'} gap={2}>
                    <Stack direction="column" gap={0.5} width="120px">
                      <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>Total Amount</Typography>
                      <CocoField
                        variant="body1"
                        value={`${transaction.amountBreakdown?.totalCost ? transaction.amountBreakdown?.totalCost : 0}`}
                        pretext="$"
                        placeholder="Enter Amount"
                        handleSave={(newValue) => handleFieldUpdate('amountBreakdown.totalCost', newValue)}
                      />
                    </Stack>
                    {/* <Stack direction="column" gap={0.5} width="120px">
                      <Typography marginLeft={2} variant="h5" color={colorPalette.gray_800}>Tax (optional)</Typography>
                      <CocoField
                        variant="body1"
                        value={`${transaction.amountBreakdown?.tax1 ? transaction.amountBreakdown.tax1 : "0"}`}
                        pretext="$"
                        placeholder={"Enter Amount"}
                        handleSave={(newValue) => handleFieldUpdate('amountBreakdown.tax1', newValue)}
                      />
                    </Stack> */}
                    <Stack direction="column" gap={0.5} width="180px">
                      <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>Transaction date</Typography>
                      <CocoField
                        variant="body1"
                        type="date"
                        value={transaction.transactionDate ? transaction.transactionDate : "1970-01-01"}
                        placeholder={"Add date"}
                        handleSave={(newValue) => handleFieldUpdate('transactionDate', newValue)}
                      />
                    </Stack>
                  </Stack>
                </Stack>

              </Stack>
            </MetadataCard>
            <Stack direction="column" gap={1}>
              <Typography marginLeft={2} color={colorPalette.gray_800} variant="body1">
                Uploaded documents
              </Typography>
              <CocoReceiptUpload
                transactionId={transaction.id}
                uploadedReceiptCloudURL={transaction.uploadedReceiptCloudURL ? `https://coinreceipts.s3.amazonaws.com/${transaction.uploadedReceiptCloudURL}` : null}
                transactionStatus={transaction.transactionStatus}
                transactionType={transaction.transactionType === "expense" ? "expense" : "income"}
                updatedImage={() => isUpdated()}
                viewMode={(allSubTransactions.length > 0 || transaction.transactionStatus === 'Processed') ? 'collapsed' : 'expanded'}
              ></CocoReceiptUpload>

            </Stack>
          </Stack>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (!transaction) return null;

    const handleClose = () => {
      setAnchorEl(null);
    };

    const transactionDate = transaction.transactionDate?.toLocaleDateString();

    return (
      <Stack
        padding={"1rem 2rem 4rem 2rem"}
        direction={"column"}
        justifyContent={"space-between"}
      >
        <Stack direction="column" gap={2} width={'100%'}>
          {/* Transaction Header */}
          <Stack direction={'row'} justifyContent="space-between">
            <Stack direction="row" gap={2} alignItems={'center'}>
              <Avatar
                alt="Remy Sharp"
                src={validatedUser?.user_pack.avatar}
                className="avatar-lg"
                sx={{ width: 32, height: 32 }}
              />
              <Stack direction="column">
                <Typography variant="subtitle1">
                  {validatedUser?.user_pack.name}
                </Typography>
                <Typography variant="subtitle2" color={colorPalette.gray_700}>
                  Added on {transactionDate}
                </Typography>
              </Stack>
            </Stack>
            <MenuItem
              onClick={() => {
                handleDeleteTransaction(transaction.id);
                handleClose();
              }}
              sx={{
                borderRadius: 8,
                p: '8px 12px',
                height: 32,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <DeleteOutlineIcon sx={{ mr: 1, color: colorPalette.Red_500 }} />
              Delete transaction
            </MenuItem>
          </Stack>
          <Stack direction="row" gap={2} sx={{ width: '100%' }} justifyContent="space-between">
            <Stack direction={'column'} gap={1} sx={{ width: '100%' }}>
              {/* <Typography marginLeft={2} variant="h3">{transaction?.merchantCategory?.name ? `Paid to ${transaction.merchantCategory.name}` : transaction.transactionName}</Typography> */}
              <CocoField
                variant="h3"
                // value={`${transaction.transactionName}`}
                value={transaction.transactionName}
                placeholder="Add transaction name"
                handleSave={(newValue) => handleFieldUpdate('transactionName', newValue)}
                background={colorPalette.gray_100}
              />
              <Stack
                marginLeft={2}
                width={'64px'}
                height={'8px'}
                borderRadius={8}
                sx={{
                  background: transaction.transactionType === 'income' ? colorPalette.Green_600 : colorPalette.Orange_600,
                  transform: 'translateY(-24px)'
                }}></Stack>
            </Stack>
          </Stack>

          {renderTransactionInfo()}
          <Stack gap={0.5}>
            <Typography ml={2} variant="body1" color={colorPalette.gray_800} sx={{ padding: '0 0 8px 0' }}> Transaction details</Typography>
            {(transactionStatus === "Processed" || transactionStatus === "Not processed") && (
              allSubTransactions.length || 1 ? (
                <SubTransactionTable
                  onCreateNewSubTransaction={onCreateNewSubTransaction}
                  transactionAmount={transaction.amountBreakdown?.totalCost}
                  transactionId={transaction.id}
                  transactionType={transaction.transactionType ? transaction.transactionType : ''}
                  allSubTransactions={allSubTransactions}
                  // onDelete={handleDeleteSubTransaction}
                  onEdit={handleEditSubTransaction}
                  isLoading={isLoading}
                />
              ) : (
                <></>
              )
            )}</Stack>

        </Stack>
      </Stack>
    );


  };

  return (
    <Box key={transaction.id}
    className={`animate__animated animate__fadeIn animate__faster`}>
      {renderContent()}
      <SubTransactionInputModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitSubTransaction}
      />
    </Box>
  );
};

export default TransactionDetails;
