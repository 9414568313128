import React from 'react';
import { Chip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { CocoChipProps } from './CocoChipProps';
import colorPalette from 'theme/colorPalette';
import { Typography } from '@mui/material';
const getColorFromClassName = (className?: string) => {
  // Add more conditions as necessary
  if (className?.includes('merchant')) return colorPalette.Amber_500;
  if (className?.includes('income')) return colorPalette.Blue_500;
  if (className?.includes('expense')) return colorPalette.Orange_500;
  return ''; // default color (or return a fallback color if desired)
};

const CocoChip: React.FC<CocoChipProps> = ({ label, className }) => {
  const colorApplied = getColorFromClassName(className);

  return (<>
    <Typography variant='subtitle2'>{label}</Typography>
  </>
  );
};

export default CocoChip;
