import React from 'react';
import PropTypes from 'prop-types';
import { useRive, Layout, Fit, Alignment } from "rive-react";
import {
    Button,
    Stack,
    Typography,
} from "@mui/material";
import colorPalette from 'theme/colorPalette';
import { useNavigate } from "react-router-dom";

interface EmptyStateWithActionProps {
    loadingText?: string;
    buttonText?: string;
    buttonUrl?: string;
    primaryButtonClick?: () => void;
    secondaryButtonClick?: () => void;
    navigateUrl?: string;
    subtitle?: string; // Optional subtitle prop
}

const EmptyStateWithAction: React.FC<EmptyStateWithActionProps> = ({ loadingText = "You don't have any data", buttonText = "Add a new transaction", primaryButtonClick, secondaryButtonClick, navigateUrl, subtitle }) => {
    const { RiveComponent } = useRive({
        src: "/rive/impatient_placeholder.riv",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    const riveStyle: React.CSSProperties = {
        height: '200px',
        width: '200px',
        borderRadius: '12px', // smooth cornering
        overflow: 'hidden', // clip the animation to fit the rounded corners
    };

    const textContainerStyle: React.CSSProperties = {
        maxWidth: '420px', // Adjust the value as needed
        textAlign: 'center',
    };

    const navigate = useNavigate();

    return (
        <div style={containerStyle}  className='animate__animated animate__fadeIn'>
            <Stack spacing={4} alignItems={"center"}
                sx={{ background: colorPalette.white }}
                p={4} border={2} borderRadius={'8px'} borderColor={colorPalette.gray_300}>
                <div style={riveStyle}>
                    <RiveComponent />
                </div>
                <Stack direction={'column'} gap={1}>

                    <Typography variant="h4" style={{ color: colorPalette.black, ...textContainerStyle }}>
                        {loadingText}
                    </Typography>

                    {subtitle && (
                        <Typography variant="body2" style={{ color: colorPalette.gray_600, ...textContainerStyle }}>
                            {subtitle}
                        </Typography>
                    )}
                </Stack>
                {(primaryButtonClick || secondaryButtonClick) && (
                    <Stack direction={'row'} gap={1}>
                        {primaryButtonClick ? (<Button variant='outlined' onClick={() => primaryButtonClick()}>
                            Add Expense
                        </Button>) : null}
                        {secondaryButtonClick ? (<Button variant='outlined' onClick={() => secondaryButtonClick()}>
                            Add Income
                        </Button>) : null}
                    </Stack>)}

                {navigateUrl ? (<Button variant='outlined' onClick={() => navigate(navigateUrl)}>
                    {buttonText}
                </Button>) : null}

            </Stack>
        </div>
    );
};

EmptyStateWithAction.propTypes = {
    loadingText: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    subtitle: PropTypes.string,
};

export default EmptyStateWithAction;
