import React from 'react';
import { Stack } from '@mui/material';
import TransactionListItem from './TransactionListItem';
import { AnimatedContainer } from '../theme/animations';

interface AllTransactionsProps {
  data: Array<any>;
  onTransactionClick: (transaction: any) => void;
  activeTransaction: any;
}

const AllTransactions: React.FC<AllTransactionsProps> = ({ data, onTransactionClick, activeTransaction }) => {

  // Generate a key based on the data length (and possibly other factors if needed)
  const dataKey = `transactions-${data[0]?.transaction?.id}`;

  return (
    <AnimatedContainer>
      <Stack direction={"column"} gap={1}>
        {data.map((transaction) => (
          <TransactionListItem
            key={transaction.id}
            isReceiptAdded={transaction.uploadedReceiptData ? true : false}
            transactionName={transaction.transactionName}
            transactionAmount={transaction.transactionAmount}
            accountName={transaction.accountName}
            transactionType={transaction.transactionType}
            category={transaction.category}
            transactionDate={transaction.transactionDate}
            onClick={() => onTransactionClick(transaction)}
            isActive={activeTransaction && activeTransaction.id === transaction.id}
          />
        ))}
      </Stack>
    </AnimatedContainer>
  );
};

export default AllTransactions;
