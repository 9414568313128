import React from 'react';
import {
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { validateUser } from '../../services/validateUser';
import { ToastContainer } from "react-toastify";

import CountrySelect from '../../components/CountrySelect';
import CocoField from '../../components/CocoField';
import colorPalette from '../../theme/colorPalette';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
const Step1ProfileSetup = () => {
  const navigate = useNavigate();
  const validatedUser = validateUser();

  return (
    <Stack
      p={4}
      direction={'column'}
      alignItems={'left'}
    >
      <Stack alignItems={'left'} gap={2}>
        <AccountCircleTwoToneIcon
          sx={{
            fontSize: '4em',
            color: colorPalette.Purple_600,
          }} />
        <Typography variant="h2">
          Create account
        </Typography>
      </Stack>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <Stack
          direction="column"
          alignItems="flex-start"
          width={"100%"}
          marginTop={4}
          spacing={2}
          maxWidth="640px"
        >
          <Stack direction={"column"} gap={0.5} width={'100%'}>
            <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>Your name</Typography>
            <CocoField
              variant="body1"
              value={validatedUser?.user_pack.name}
              placeholder="Name"
            />
          </Stack>
          <Stack direction={"column"} gap={0.5} width={'100%'}>
            <Typography marginLeft={2} variant="body1" color={colorPalette.gray_800}>Email address</Typography>
            <CocoField
              variant="body1"
              value={validatedUser?.user_pack.email}
              placeholder="Email"
            />
            <Typography marginTop={0.5} variant="subtitle1" color={colorPalette.gray_600}>Please enter your email address to receive notifications and financial insights tailored to your data.</Typography>
          </Stack>
        </Stack>
      </Box>
      <ToastContainer />
    </Stack>
  );
}

export default Step1ProfileSetup;
