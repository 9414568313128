import React, { useState } from "react";
import { Stack, TextField, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import axios from "axios";
import Logo from "../assets/logo/Logo";
import Background from "../assets/login/background.jpg";
import { Box, Grid, Paper } from "@mui/material";
import colorPalette from "theme/colorPalette";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLogin } from "@refinedev/core";
import LoginOtp from "components/LoginOtp";
import GoogleLoginSingup from '../components/GoogleLoginSignup'


export const Registration = () => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const { mutate: check } = useLogin();
  const handleOtpPrompt = () => {
    setIsEmailVerified(false)
    // handleOtpSubmit()
  }
  const handleVerifyOtpSubmit = async () => {
    // e.preventDefault();
    if (!name || !email) {
      toast.error("Please enter your name and email.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/otp/sendotp`, { email });
      toast.success("OTP sent successfully");
      setIsEmailVerified(true);
    } catch (error) {
      console.error(error);
      toast.error("Failed to send OTP");
    }
  };
  const handleOtpSubmit = async (otp: any) => {
    if (!name || !email || (isEmailVerified && !otp)) {
      toast.error("Please enter all the required information.");
      return;
    }

    try {
      // Send the entered OTP and name to the server for verification and registration
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/otp/register`, {
        name, // Add the name field to the request payload
        email,
        otp,
      });
      if (response.status === 200) {
        toast.success("OTP verified successfully"); // Display success message

        localStorage.setItem(
          "user",
          JSON.stringify({
            username: response.data.user.name,
            email: response.data.user.email,
            userid: response.data.user._id,
          })
        );
        localStorage.setItem("token", `${response.data.token}`);
        check({});
      } else {
        toast.error("Invalid OTP"); // Display error message
      }
    } catch (error) {
      console.error(error);
      toast.error("Invalid OTP"); // Display error message
    }
  };


  return (
    <Grid container component="main" sx={{ height: '100vh', overflow: 'auto' }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} sx={{ background: colorPalette.gray_100 }} square >
        <Stack
          direction="column"
          width={'480px'}
          alignItems="center"
          gap={0}
          sx={{
            margin: 'auto',
            marginTop: '80px',
            border: `2px solid ${colorPalette.gray_200}`,
            boxShadow: '0px 10px 15px rgba(0,0,0,0.06)',
            background: colorPalette.white
          }}
        >
          <Stack padding={2} width={'100%'} borderBottom={`2px solid ${colorPalette.gray_200}`}>
            <Logo />
          </Stack>
          <Stack padding={4} gap={2} alignItems={'center'} width={'100%'}>
            <Stack direction="column" alignItems="center" spacing={0}>
              <Typography variant="h3" style={{ color: colorPalette.black }}>
                Sign up with us
              </Typography>
              <Typography variant="body2">
                Add your email and name to start using the app.
              </Typography>
            </Stack>
            <Stack gap={2} alignItems="center" >
              <TextField
                variant="outlined"
                placeholder="Enter your Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                variant="outlined"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
            {!isEmailVerified && (
              <Button variant="contained" onClick={handleVerifyOtpSubmit}>
                Send OTP to your email
              </Button>
            )}

            {isEmailVerified && (<Typography style={{ color: colorPalette.black }}>
              <LoginOtp email={email} otpPrompt={isEmailVerified} handleOtpPrompt={handleOtpPrompt} handleOtpSubmit={handleOtpSubmit} handleVerifyOtpSubmit={handleVerifyOtpSubmit} />
            </Typography>)}

            <Typography variant="body2" align="center">OR</Typography>
            <GoogleLoginSingup />
            <Stack
              padding={2}
              direction="row"
              spacing={1}
              className="signup-text"
              justifyContent="center"
            >
              <Typography variant="body1">Existing User?</Typography>
              <Link to="/login">Login Now</Link>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
