import React from 'react';
import PropTypes from 'prop-types';
import { useRive, Layout, Fit, Alignment, useStateMachineInput } from "rive-react";
import {
    Stack,
    Typography,
} from "@mui/material";
import colorPalette from 'theme/colorPalette';

const PageToPageSmileyLoader = ({ loadingText = 'Loading...' }) => {
    const { rive, RiveComponent } = useRive({
        src: "/rive/mix-it-up.riv",
        stateMachines: "DayNightSwitch",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    const riveStyle: React.CSSProperties = {
        height: '360px',
        width: '580px',
        borderRadius: '12px', // smooth cornering
        overflow: 'hidden', // clip the animation to fit the rounded corners
    };

    // Get a reference to the "Night" trigger in the "DayNightSwitch" state machine.
    const nightTrigger = useStateMachineInput(rive, "DayNightSwitch", "Night");

    // Fire the "Night" trigger to set the initial state (optional).
    if (rive && nightTrigger) {
        nightTrigger.fire();
    }

    return (
        <div style={containerStyle}>
            <Stack spacing={4}>
                <div style={riveStyle}>
                    <RiveComponent />
                </div>
                <Typography variant="h4" style={{ color: colorPalette.black, textAlign: 'center' }}>
                    {loadingText}
                </Typography>
            </Stack>
        </div>
    );
};

PageToPageSmileyLoader.propTypes = {
    loadingText: PropTypes.string,
};

export default PageToPageSmileyLoader;
