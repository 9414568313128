
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack } from '@mui/material';
import { TreeDataItem } from '../interfaces/category';
import { Typography } from '@mui/material';

interface InputWithChipsProps {
  categoriesData: TreeDataItem[];
  handleAddedCategoriesIDChange: (ids: string[]) => void;
  transactionType: 'income' | 'expense' | 'merchant' | 'income-box' | 'expense-box';
  defaultSelectedCategories?: string[];
  textFieldLabel?: string; // Optional TextField label
  textFieldHelperText?: string; // Optional TextField helperText
  selectMode?: 'single' | 'multi'; // Optional select mode
  postSelectLabel?: string;
}


const InputWithChips = forwardRef((props: InputWithChipsProps, ref) => {
  const {
    categoriesData, 
    handleAddedCategoriesIDChange, 
    transactionType, 
    defaultSelectedCategories, 
    textFieldLabel = "Choose categories",  // default value if not provided
    textFieldHelperText = "Select from dropdown or press Enter or , to add a category", // default value
    selectMode = 'multi', // multi-select by default
    postSelectLabel = "Merchant added"
  } = props;
  const [inputValue, setInputValue] = useState('');

  const findCategoryNameById = (id: string): string => {
    // Recursive search for category by ID
    const search = (data: TreeDataItem[]): string | null => {
      for (let item of data) {
        if (item._id === id) return item.name;
        if (item.children) {
          const result = search(item.children);
          if (result) return result;
        }
      }
      return null;
    };
    return search(categoriesData) || '';
  };

  const initialSelectedCategories = defaultSelectedCategories ? defaultSelectedCategories.map(id => findCategoryNameById(id)) : [];

  const [selectedCategories, setSelectedCategories] = useState<string[]>(initialSelectedCategories);

  const handleInputChange = (event: any, value: any, reason: string) => {
    // Only update inputValue when the reason is input (i.e., typing in the text field)
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  const handleOptionSelect = (event: any, value: any) => {
    const trimmedValue = value ? value.trim() : '';
    if (trimmedValue && !selectedCategories.includes(trimmedValue)) {
      setSelectedCategories([...selectedCategories, trimmedValue]);
      handleAddedCategoriesIDChange([...selectedCategories, trimmedValue]);
    }
    setInputValue(''); // This will clear the Autocomplete input
  };
  
  

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' || event.key === ',') {
      const trimmedValue = inputValue.trim();
      if (trimmedValue && !selectedCategories.includes(trimmedValue)) {
        setSelectedCategories([...selectedCategories, trimmedValue]);
        setInputValue('');
        handleAddedCategoriesIDChange([...selectedCategories, trimmedValue]);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    clearSelectedCategories: () => {
      setSelectedCategories([]);
      handleAddedCategoriesIDChange([]);
    },
  }));

  const handleDeleteChip = (category: any) => {
    const updatedCategories = selectedCategories.filter((item) => item !== category);
    setSelectedCategories(updatedCategories);
    handleAddedCategoriesIDChange(updatedCategories);
  };

  const getSuggestedOptions = (data: TreeDataItem[]): string[] => {
    let options: string[] = [];
    data.forEach((item) => {
      options.push(item.name);
      if (item.children) {
        options = options.concat(getSuggestedOptions(item.children));
      }
    });
    return options;
  };

  const getGroupedOptions = (data: TreeDataItem[]): { title: string; options: string[] }[] => {
    let temp: { [key: string]: string[] } = {};
    data.forEach((item) => {
      if (!temp[item.type]) {
        temp[item.type] = [];
      }
      temp[item.type].push(item.name);
      if (item.children) {
        const childOptions = getGroupedOptions(item.children);
        childOptions.forEach((child) => {
          if (!temp[child.title]) {
            temp[child.title] = [];
          }
          temp[child.title].push(...child.options);
        });
      }
    });
    return Object.keys(temp).map(key => ({ title: key, options: temp[key] }));
  };

  const getFilteredCategories = () => {
    switch (transactionType) {
      case 'income':
        return categoriesData.filter(
          (item) => 
            item.type === 'income-section' || 
            item.type === 'income-shelf' || 
            item.type === 'income-box'
        );
      case 'expense':
        return categoriesData.filter(
          (item) => 
            item.type === 'expense-section' || 
            item.type === 'expense-shelf' || 
            item.type === 'expense-box'
        );
      case 'merchant':
        return categoriesData.filter(
          (item) => 
            item.type === 'merchant-section' || 
            item.type === 'merchant-shelf' || 
            item.type === 'merchant-box'
        );
        case 'income-box':
          return categoriesData.filter((item) => item.type === 'income-box');
        case 'expense-box':
          return categoriesData.filter((item) => item.type === 'expense-box');
        default:
          return categoriesData; 
    }
  };

  const determineTextFieldLabel = () => {
    if (selectMode === 'single' && selectedCategories.length > 0) {
      return postSelectLabel; 
    }
    return textFieldLabel;
  };

  return (
    <Stack direction="column" gap={1}>
      <Autocomplete
        value={inputValue}
        onInputChange={handleInputChange}
        onChange={(event, value) => handleOptionSelect(event, value)}
        onKeyPress={handleKeyDown}
        options={getGroupedOptions(getFilteredCategories()).flatMap(group => group.options)}
        disabled={selectMode === 'single' && selectedCategories.length > 0}
        groupBy={(option) => {
          for (let group of getGroupedOptions(getFilteredCategories())) {
            if (group.options.includes(option)) return group.title;
          }
          return "";
        }}
        renderGroup={(params) => (
          <div key={params.key}>
            <Typography paddingLeft={2}  bgcolor={'#ededed'} variant="h6" gutterBottom>
              {params.group}
            </Typography>
            {params.children}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={determineTextFieldLabel()} // Using the prop here
            helperText={textFieldHelperText} // Using the prop here
            disabled={selectMode === 'single' && selectedCategories.length > 0} // Disable input if single select mode and a category is selected
          />
        )}
      />
      <Stack direction="row" gap={1}>
        {selectedCategories.map((category) => (
          <Chip
            key={category}
            label={category}
            onDelete={() => handleDeleteChip(category)}
            style={{ marginRight: 5 }}
          />
        ))}
      </Stack>
    </Stack>
  );
});

export default InputWithChips;