import { Typography, Stack, Box, Tooltip, IconButton } from '@mui/material'
import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';
import colorPalette from 'theme/colorPalette';
import InfoIcon from '@mui/icons-material/Info';
import English_Locale from "../../locales/english/english.json";
interface DataItem {
  date: string;
  expense: string;
  income: string;
  ratio: string;
}

interface SeriesData {
  name: string;
  data: [number, number][];
}

const generateSeriesFromData = (seriesdata: DataItem[]) => {
  const series: SeriesData[] = [
    { name: 'Expense', data: [] },
    { name: 'Income', data: [] }
  ];

  seriesdata?.forEach((item) => {
    const timestamp = new Date(item.date).getTime();
    series[0].data.push([timestamp, parseFloat(item.expense)]);
    series[1].data.push([timestamp, parseFloat(item.income)]);
  });

  return series;
};

const CHIChart = ({ chiData }: any) => {

  useEffect(() => {

    const seriesdata = chiData.series;
    const series: SeriesData[] = generateSeriesFromData(seriesdata);
    const options = {

      series,
      chart: {
        type: 'area',
        height: 350,
        stacked: false,
        events: {
          selection: function (chart: any, e: any) {
            console.log(new Date(e.xaxis.min))
          }
        },
      },
      colors: [colorPalette.Red_300, colorPalette.Blue_300, colorPalette.Orange_300],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
      legend: {
        enabled: false,
        position: 'bottom',
        horizontalAlign: 'center'
      },
      xaxis: {
        type: 'datetime'
      },
    };
    const chart = new ApexCharts(document.querySelector("#CHIChart"), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [chiData]);

  return (
    <Box>
      <Stack direction="column" spacing={2.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h4">Coins Health Index</Typography>
          <Tooltip title={English_Locale.CHITooltip} placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="column" spacing={0.5}>
          <Typography variant="body2" color={colorPalette.gray_700}>Your score</Typography>
          <Typography variant="h2">{chiData.HealthIndexValue}</Typography>
        </Stack>
        <div id="CHIChart"></div>
      </Stack>
    </Box>
  );
}

export default CHIChart;
