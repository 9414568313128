import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Stack, Tooltip, IconButton, Drawer } from "@mui/material";

import InfoIcon from '@mui/icons-material/Info';
import ColumnChart from "../components/charts/columnChart";
import RecentTransactions from "../components/RecentTransactions";
import ExpenseStats from "../components/ExpenseStats";
import CHIChart from "../components/charts/CHIChart";
import CurrentOverBudgetsCategories from "../components/CurrentOverBudgetsCategories";
import English_Locale from "../locales/english/english.json";
import { getAllBudgetsAndActuals, getAllTopCategories, getCHIData } from "../api-routers/DashboardRouter";
import { fetchAllTransactions, getCurrentMonthAndYear } from "../api-routers/TransactionsRouter";
import { MonthlyExpense, MonthlyData } from '../interfaces/dashboard';
import { Transaction } from '../interfaces/transaction';
import { validateUser } from '../services/validateUser';
import EmptyStateWithAction from "../components/loaders/EmptyStateWithAction";
import LoaderAnimation from "../components/loaders/LoaderAnimation";
import colorPalette from "theme/colorPalette";
import BudgetVsActuals2 from "../components/graphs/BudgetsVsActuals2";
import ConnectWithPlaid from '../components/plaid/PlaidAccounts';
import { AnimatedContainer } from '../theme/animations';
const Home = () => {

  const [budgetsAndActuals, setBudgetsAndActuals] = useState<MonthlyData>({});
  const [recentTransactions, setRecentTransactions] = useState<Transaction[]>([]);
  const [topCategories, setTopCategories] = useState<[]>([]);
  const [CHIData, setCHIData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const validated_user = validateUser();
    if (validated_user) {
      setIsLoading(true);
      const fetchData = async () => {
        const monthlyData = await getAllBudgetsAndActuals(validated_user.user_pack.id);
        setBudgetsAndActuals(monthlyData.monthlyData);
        const recentTransactionsResponse = await fetchAllTransactions(validated_user.user_pack.id);

        if (recentTransactionsResponse.transactions === null || recentTransactionsResponse.transactions.length === 0) {
          setIsEmpty(true);
        } else {
          setRecentTransactions(recentTransactionsResponse.transactions);
        }

        const fetchedTopCategories = await getAllTopCategories(validated_user.user_pack.id);
        setTopCategories(fetchedTopCategories);
        const fetchedCHIData = await getCHIData(validated_user.user_pack.id);
        setCHIData(fetchedCHIData);
        setIsLoading(false);
      };
      fetchData();
    }
  }, []);

  if (isLoading) {
    return (
      <Box padding={2} sx={{ background: colorPalette.gray_100 }}>
        <LoaderAnimation
          loadingText={"Loading..."}
        /></Box>);
  } else if (isEmpty) {
    return (
      <Box padding={2} sx={{ background: colorPalette.gray_100 }}>
        <EmptyStateWithAction
          loadingText={"No transactions added"}
          buttonText={"Go to transactions"}
          navigateUrl={"/transactions"}
          subtitle=" You will be able to view Dashboard and analytics from adding your first transaction"
        />
      </Box>);
  }

  return (
    <Stack gap={2} padding={2} sx={{ background: colorPalette.gray_100, height: '100%' }}>
      <Box padding={2} sx={{ maxWidth: '1200px', margin: 'auto' }}>
        <Typography variant='h1' mb={1} textAlign={'center'}>
          {getCurrentMonthAndYear()}
        </Typography>
        <Typography variant='body1' color={colorPalette.gray_800} m={'auto'} mb={3} textAlign={'center'} maxWidth={'640px'}>
          Welcome to your {getCurrentMonthAndYear()}{' '}
          Financial Dashboard! Here, you'll find a comprehensive
          view of your financial health and activities for the month.
        </Typography>
        <AnimatedContainer>
          <Grid container spacing={2} >
            {/* BudgetVsActuals2 Widget */}
            <Grid item xs={12} md={8} lg={8}>
              <Box p={2} className='shadowStylePaper' sx={{ background: colorPalette.white, overflowX: 'auto', height: '100%' }}>
                <Stack direction={'row'}>
                  <Typography variant="h4">{English_Locale.ExpenseBudgetTitle}</Typography>
                  <Tooltip title={English_Locale.ExpensesVsBudgets} placement="right">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Box mt={2} height={'100%'}>
                  <BudgetVsActuals2 monthlyData={budgetsAndActuals} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className='shadowStylePaper' p={2} sx={{ background: colorPalette.white, maxHeight: '540px', overflow: 'auto' }}>
                <Box>
                  <CHIChart chiData={CHIData} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className='shadowStylePaper' p={2} sx={{ background: colorPalette.white, maxHeight: '540px', overflow: 'auto' }}>
                <Box>
                  <Stack direction={'row'}>
                    <Typography variant="h4">{English_Locale.SyncAccountsTitle}</Typography>
                    <Tooltip title={English_Locale.SyncAccountsTooltip} placement="right">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <ConnectWithPlaid></ConnectWithPlaid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className='shadowStylePaper' p={2} sx={{ background: colorPalette.white, maxHeight: '540px', overflow: 'auto' }}>
                <Stack direction={'row'}>
                  <Typography variant="h4">Top expense categories</Typography>
                  <Tooltip title={English_Locale.TopExpensesTooltip} placement="right">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <ExpenseStats categoryAmount={topCategories} />
              </Box>
            </Grid>
            {/* RecentTransactions Widget */}
            <Grid item xs={12} md={4} lg={4}>
              <Box className='shadowStylePaper' p={2} sx={{ background: colorPalette.white, maxHeight: '540px', overflow: 'auto' }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <Stack direction={'row'}>
                      <Typography variant="h4">{English_Locale.RecentTransactionsTitle}</Typography>
                      <Tooltip title={English_Locale.RecentTransactionsTooltip} placement="right">
                        <IconButton size="small">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <RecentTransactions transactions={recentTransactions} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </AnimatedContainer>
      </Box>
    </Stack>
  );
};

export default Home;
