import { styled } from "@mui/system";

// Define your animations
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const bounceUp = keyframes`
  0% {
    transform: translateY(12px);
  }
  30% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Apply animations to a styled div
export const AnimatedContainer = styled('div')`
  animation: ${fadeIn} 1s ease-out, ${bounceUp} 0.5s ease-out;
`;