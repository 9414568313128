import axios from 'axios';
import { validateUser } from '../services/validateUser';

const getServerUrl = () => process.env.REACT_APP_SERVER_URL;

export const fetchUserData = async () => {
    try {
        // Assuming validateUser() returns the userId or an object containing the userId
        const validated_user = await validateUser();
        if (validated_user) {
            const userId = validated_user.user_pack.id;
            const response = await axios.get(`${getServerUrl()}/api/settings/fetchUser/${userId}`);
            return response.data;
        }
        if (!validated_user) throw new Error('User not validated');


    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

export const updateUserProfile = async (userData: any) => {
    try {
        const validated_user = await validateUser();
        if (validated_user) {
            const userId = validated_user.user_pack.id;
            const response = await axios.post(`${getServerUrl()}/api/settings/updateUser/${userId}`, userData);
            return response.data;
        }
        if (!validated_user) throw new Error('User not validated');

    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};