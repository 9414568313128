import React, { useState, useEffect } from "react";
import {
  Modal,
  Stack,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import InputWithChips from './InputWithChips';
import { getAllCategories } from '../api-routers/CategoriesRouter';
interface SubTransactionInputModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (subTransactionValues: any) => Promise<void>; // Updated onSubmit prop
}

const SubTransactionInputModal: React.FC<SubTransactionInputModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [subTransactionValues, setSubTransactionValues] = useState({
    item: "",
    amount: "",
    category: "",
    notes: "",
  });

  const [categories, setCategories] = useState<[]>([]);
  const [addedCategoriesIDString, setAddedCategoriesIDString] = useState<string>('');
  const [addedItems, setAddedItems] = useState<string>(''); 
  useEffect(() => {
    const fetchData = async () => {
      const fetchedCategories = await getAllCategories();
      setCategories(fetchedCategories.categories);
    };

    fetchData();
  }, []);

  const handleAddedCategoriesIDChange = (categoriesID: string[]) => {
    subTransactionValues.category = categoriesID.join(',');
    setAddedCategoriesIDString(categoriesID.join(','));
    
  }

  const handleAddedItemChange = (categoriesID: string[]) => {
    subTransactionValues.item =categoriesID.join(',');
    setAddedItems(categoriesID.join(','));
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSubTransactionValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    await onSubmit(subTransactionValues); // Pass form values to onSubmit function
    setSubTransactionValues({
      item: "",
      amount: "",
      category: "",
      notes: "",
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Stack gap={2} direction={"column"} sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 400,
        }}
      >
        
        <Typography variant="h4">Add Sub-Transaction</Typography>
        {/* <TextField
          name="item"
          label="Item"
          value={subTransactionValues.item}
          onChange={handleInputChange}
          fullWidth
        /> */}
        <InputWithChips
          categoriesData={categories}
          handleAddedCategoriesIDChange={handleAddedItemChange}
          transactionType='expense-box'
          textFieldLabel = "Select or add item"
          postSelectLabel = "Item added"
          textFieldHelperText = "Select from dropdown or press Enter or , to add an item"
          selectMode="single"
        />
        <TextField
          name="amount"
          label="Amount"
          type="number"
          value={subTransactionValues.amount}
          onChange={handleInputChange}
          fullWidth
        />
        <InputWithChips
          categoriesData={categories}
          handleAddedCategoriesIDChange={handleAddedCategoriesIDChange}
          transactionType='expense'
        />
        <TextField
          name="notes"
          label="Notes"
          value={subTransactionValues.notes}
          onChange={handleInputChange}
          multiline
          fullWidth
        />
        <Stack direction={"row"} gap={2}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Close
        </Button>
        </Stack>
        
      </Stack>
    </Modal>
  );
  
};

export default SubTransactionInputModal;
