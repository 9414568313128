import axios from 'axios';
import { Account } from '../interfaces/account';
import { validateUser } from '../services/validateUser';
export const getAllAccounts = async () => {
    const validated_user = await validateUser();
    if (validated_user) {
        const userId = validated_user.user_pack.id;
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/account/getaccounts/${userId}`);
        const accounts = response.data;
        return accounts;
    } else {
        return [];
    }
};

export const getAllTransactions = async () => {
    const validated_user = await validateUser();
    if (validated_user) {
        const userId = validated_user.user_pack.id;
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/account/gettransactions/${userId}`);
        const accounts = response.data;
        return accounts;
    } else {
        return [];
    }
};

export const getPlaidCategories = async () => {
    const validated_user = await validateUser();
    if (validated_user) {
        const userId = validated_user.user_pack.id;
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/account/getcategories/${userId}`);
        const categories = response.data;
        return categories;
    } else {
        return [];
    }
};