import axios from 'axios';
import { Transaction } from '../interfaces/transaction';
import { SubTransaction } from '../interfaces/subtransaction';
import { splitStringWithCommas } from '../services/handyFunctions'
import { validateUser } from '../services/validateUser';

export const getCurrentMonthAndYear = () => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const currentDate = new Date();
  const currentMonth = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  return `${currentMonth} ${currentYear}`;
};


export const createEmptyTransaction = async (transactionType: 'income' | 'expense'): Promise<string> => {
  // Validate the user before proceeding
  const validated_user = await validateUser();

  // Check if user was validated successfully, if not, throw an error
  if (!validated_user) {
    throw new Error('User validation failed');
  }

  const userId = validated_user.user_pack.id; // Extracting userId from the validated_user result

  // Include userId in the URL
  const url = `${process.env.REACT_APP_SERVER_URL}/api/transactions/${userId}/create-empty`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ transactionType }), // Send transactionType in the body
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to create an empty transaction');
    }

    const responseData = await response.json();

    // Assuming the response contains a field 'transactionId' that holds the ID of the created transaction
    return responseData.transactionId;

  } catch (error) {
    console.error('Error creating an empty transaction:', error);
    throw error;
  }
};


const convertFileToDataURL = (file: File): Promise<{ name: string; url: string }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve({ name: file.name, url: reader.result as string });
    };
    reader.onerror = reject;
  });
};

export const handleImageUpload = async (selectedFile: File, transactionId: string, useGPT: boolean) => {
  if (!selectedFile) return;

  const validated_user = await validateUser();
  if (!validated_user) {
    throw new Error('User validation failed');
  }

  const userId = validated_user.user_pack.id;
  const url = `${process.env.REACT_APP_SERVER_URL}/api/transactions/add-receipt/`;

  const ImageFile = await convertFileToDataURL(selectedFile);

  const formData = new FormData();
  formData.append('imageUrl', ImageFile.url);
  formData.append('userId', userId);
  formData.append('transactionId', transactionId);
  formData.append('useGPT', useGPT.toString());

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload receipt.');
    }

    const responseData = await response.json();
  } catch (error) {
    console.error('Error uploading receipt:', error);
    throw error;
  }
};

export const handleReplaceDocument = () => {
  const fileInput = document.getElementById('upload-receipt');
  if (fileInput) {
      fileInput.click(); // Trigger the file input to select a new document
  }
};

export const fetchAllTransactions = async (
  userId: number,
  page: number = 1,
  pageSize: number = 10,
  sort: string = 'latest', // Default to 'latest' sorting
  searchKey: string = '',
): Promise<{ transactions: Transaction[], totalPages: number, currentPage: number, currentPageSize: number, totalItems: number }> => {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/transactions/${userId}/?page=${page}&pageSize=${pageSize}&sort=${sort}&searchKey=${searchKey}`;


    const response = await axios.get(url);

    const allTransactions = response.data.transactions.map((transaction: any) => {
      const mappedCategory = transaction.category.map((cat: any) => {
        return {
          categoryName: cat.categoryName ? cat.categoryName : '',
          categoryClass: cat.categoryClass,
          parentCategory: cat.parentCategory,
          id: cat.id,
          icon: cat.icon,
        };
      });

      return {
        id: transaction.id,
        transactionName: transaction.transactionName ? transaction.transactionName : '',
        transactionAmount: transaction.amountBreakdown.totalCost,
        category: mappedCategory,
        accountName: transaction.accountName? transaction.accountName: '',
        transactionDate: new Date(transaction.transactionDate ? transaction.transactionDate:"1970-01-01"),
        transactionStatus: transaction.transactionStatus,
        amountBreakdown: transaction.amountBreakdown,
        merchantCategory: transaction.merchantCategory,
        createdDate: new Date(transaction.createdDate?transaction.createdDate:"1970-01-01"),
        transactionType: transaction.transactionType,
        uploadedReceiptData: transaction.uploadedReceiptData ? transaction.uploadedReceiptData : '',
        uploadedReceiptCloudURL: transaction.uploadedReceiptCloudURL ? transaction.uploadedReceiptCloudURL : '',
      };
    });

    const totalPages = response.data.totalPages;
    const totalItems = response.data.totalItems;
    const currentPage = response.data.page;
    const currentPageSize = response.data.pageSize;
    return {
      transactions: allTransactions,
      totalPages,
      currentPage,
      currentPageSize,
      totalItems
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleUpdateTransaction = async (transactionId: string, fieldName: string, newValue: string | number) => {
  // Validate the user before proceeding
  const validated_user = await validateUser();

  // Check if user was validated successfully, if not, throw an error
  if (!validated_user) {
    throw new Error('User validation failed');
  }

  const userId = validated_user.user_pack.id;

  const url = `${process.env.REACT_APP_SERVER_URL}/api/transactions/${userId}/field-update`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId,
      fieldName,
      newValue
    }),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to update transaction field');
    }

    const updatedTransaction = await response.json();
    return updatedTransaction;

  } catch (error) {
    console.error('Error updating transaction field:', error);
    throw error;
  }
};

export const updateSubTransactionField = async (transactionId: string, fieldName: string, subTransactionId: string, newValue: string) => {
  const validated_user = await validateUser();
  const userId = validated_user?.user_pack.id;

  const url = `${process.env.REACT_APP_SERVER_URL}/api/subtransactions/${subTransactionId}/field-update`;

  const requestOptions = {
    method: 'PUT', // Assuming the server side accepts PUT for updates
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId,
      fieldName,
      subTransactionId,
      newValue,
      userId
    }),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to update subtransaction field');
    }

    const updatedSubTransaction = await response.json();
    return updatedSubTransaction;

  } catch (error) {
    console.error('Error updating subtransaction field:', error);
    throw error;
  }
};
export const createNewSubTransaction = async (transactionId: string, fieldName: string, newValue: string) => {
  const validated_user = await validateUser();
  const userId = validated_user?.user_pack.id;

  const url = `${process.env.REACT_APP_SERVER_URL}/api/subtransactions/${userId}/create-sub`;

  const requestOptions = {
    method: 'PUT', // Assuming the server side accepts PUT for updates
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId,
      fieldName,
      newValue,
    }),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to create subtransaction');
    }

    const newSubTransaction = await response.json();
    return newSubTransaction;

  } catch (error) {
    console.error('Error updating subtransaction field:', error);
    throw error;
  }
}
export const getAllTransactionsWithGivenCategory = async (userId: string, categoryId: string): Promise<any> => {
  try {
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/transactions/categorytransactions/${userId}/${categoryId}`;


    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.log('getAllTransactionsWithGivenCategory error:', error);
    throw error;
  }
};

export const fetchSubTransactions = async (transactionId: string): Promise<SubTransaction[]> => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/subtransactions/${transactionId}`);
  const subTransactions = await response.json();
  return subTransactions;
};

export const handleDeleteDocument = async (transactionId: string): Promise<void> => {
  try {
    // Validate the user before proceeding
    const validated_user = await validateUser();

    // Check if user was validated successfully, if not, throw an error
    if (!validated_user) {
      throw new Error('User validation failed');
    }

    const userId = validated_user.user_pack.id;
    const url = `${process.env.REACT_APP_SERVER_URL}/api/transactions/delete-document/${transactionId}/${userId}`;

    const requestOptions = {
      method: 'PUT',
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to delete document');
    }

    // If the document is deleted successfully, you can perform any additional actions here if needed.

  } catch (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
};
export const deleteSubTransaction = async (subTransactionId: string): Promise<SubTransaction> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/subtransactions/${subTransactionId}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Failed to delete sub-transaction');
    }

    const deletedSubTransaction = await response.json();
    return deletedSubTransaction;
  } catch (error) {
    console.error('Error deleting sub-transaction:', error);
    throw error;
  }
};

export const addSubTransactionManually = async (subTransactionValues: any, transactionId: string, userId: string) => {
  try {
    const { item, category, amount, notes } = subTransactionValues;
    const categoriesArray = splitStringWithCommas(category);

    // Make a POST request to the createSubTransactionManually API
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/subtransactions/create-subtransaction/${transactionId}/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ item, category: categoriesArray, amount, notes }),
    });
  } catch (error) {
    console.error('Failed to add sub-transaction:', error);
    throw error;
  }
};


export const deleteTransaction = async (transactionId: string): Promise<Transaction> => {

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/transactions/${transactionId}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Failed to delete transaction');
    }

    const deletedTransaction = await response.json();
    return deletedTransaction;
  } catch (error) {
    console.error('Error deleting transaction:', error);
    throw error;
  }

};