import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/Logo";
import { Box } from "@mui/material";
import colorPalette from "../../theme/colorPalette";
import { getSuggestedCategories, createSampleData } from "../../api-routers/CategoriesRouter";

import { Circle } from '@mui/icons-material';
import PageToPageSmileyLoader from "../../components/loaders/PageToPageSmileyLoader";

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const CategoriesSetup = () => {
  const navigate = useNavigate();

  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState<string[]>([]);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false); // New state to control loading



  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await getSuggestedCategories();
        const data = await response.json();

        // Set initial active categories based on defaultEnabled
        const defaultActiveIncomeCategories = data.filter((cat: any) => cat.type === "income-main").flatMap((cat: any) => cat.children).filter((child: any) => child.defaultEnabled).map((child: any) => child.name);
        const defaultActiveExpenseCategories = data.filter((cat: any) => cat.type === "expense-main").flatMap((cat: any) => cat.children).filter((child: any) => child.defaultEnabled).map((child: any) => child.name);
        
        setSelectedIncomeCategories(defaultActiveIncomeCategories);
        setSelectedExpenseCategories(defaultActiveExpenseCategories);

        setCategoriesData(data);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    }

    fetchCategories();
  }, []);

  const renderChips = (
    categories: any[],
    setSelectedFunction: React.Dispatch<React.SetStateAction<string[]>>,
    selectedCategories: string[]
  ) => (
    categories.map((category: any) => (
      <React.Fragment key={category._id}>
        {category.children && category.children.map((child: any) => (
          <React.Fragment key={child._id}>
            {/* Depth 1 Chip with one circle dot icon */}
            <Chip
              label={child.name}
              clickable
              color={selectedCategories.includes(child.name) ? "primary" : "default"}
              icon={<Circle sx={{ width: "8px", height: "8px", color: "red" }} />} // Set icon color to red
              onClick={() => {
                const isCurrentlySelected = selectedCategories.includes(child.name);
                if (isCurrentlySelected) {
                  const newSelectedCategories = selectedCategories.filter((cat: string) => cat !== child.name);
                  setSelectedFunction(newSelectedCategories);
                } else {
                  const newSelectedCategories = [...selectedCategories, child.name];
                  setSelectedFunction(newSelectedCategories);
                }
              }}
            />

            {/* Depth 2 Chips (only if parent is active or has defaultEnabled: true) */}
            {(selectedCategories.includes(child.name) || child.defaultEnabled) && child.children && child.children.map((grandChild: any) => (
              <Chip
                key={grandChild._id}
                sx={
                  selectedCategories.includes(grandChild.name)
                    ? {}
                    : { backgroundColor: `${colorPalette.white}`, border: `1px solid ${colorPalette.gray_400}` }
                }
                label={grandChild.name}
                clickable
                color={selectedCategories.includes(grandChild.name) ? "primary" : "default"}
                icon={
                  <React.Fragment>
                    <Circle sx={{ width: "8px", height: "8px", marginRight: "2px", marginLeft: "6px", color: "white", border: "1px solid gray", borderRadius: '40px' }} />
                    <Circle sx={{ width: "8px", height: "8px", color: "white", border: "1px solid gray", borderRadius: '40px' }} />
                  </React.Fragment>
                }
                onClick={() => {
                  if (selectedCategories.includes(grandChild.name)) {
                    setSelectedFunction((prev: string[]) => prev.filter((cat: string) => cat !== grandChild.name));
                  } else {
                    setSelectedFunction((prev: string[]) => [...prev, grandChild.name]);
                  }
                }}
              />
            ))}

          </React.Fragment>
        ))}
      </React.Fragment>
    ))
  );

  if (isLoading) {
    return <PageToPageSmileyLoader loadingText={"Hold on! We are setting up your account"} />; // Show the loader if isLoading is true
  }



  return (
    <Grid container component="main" sx={{ height: '100vh', overflow: 'auto' }}>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          // backgroundImage: `url(${Background})`,

          backgroundRepeat: 'no-repeat',
          backgroundColor: "#F8D881",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Stack
            direction="column"
            spacing={3}
            maxWidth={"860px"}
          >
            <Logo />
            <Typography variant="h2" style={{ color: colorPalette.black }}>
              Choose your Income categories
            </Typography>
            <Stack direction="row" sx={{ gap: "1rem", flexWrap: "wrap", justifyContent: "left" }}>
              {renderChips(categoriesData.filter(cat => cat.type === "income-main"), setSelectedIncomeCategories, selectedIncomeCategories)}
            </Stack>

            <Typography variant="h2" style={{ color: colorPalette.black }}>
              Choose your Expense categories
            </Typography>
            <Stack direction="row" sx={{ gap: "1rem", flexWrap: "wrap", justifyContent: "left" }}>
              {renderChips(categoriesData.filter(cat => cat.type === "expense-main"), setSelectedExpenseCategories, selectedExpenseCategories)}
            </Stack>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={async () => {
                try {
                  setIsLoading(true); // Set loading to true


                  // Call createSampleData here with the selected categories

                  // After successfully creating sample data, navigate to the next page
                  navigate("/homepage");
                } catch (error) {
                  console.error("Error creating sample data:", error);
                  // Handle error here if needed
                }
                finally {
                  setIsLoading(false);
                }
              }}
            >
              Proceed
            </Button>
          </Stack>

        </Box>
      </Grid>
    </Grid>
  );


}

export default CategoriesSetup;
