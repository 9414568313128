export function hardParseFloat(value: string | number): number {
  const cleanedValue = String(value).replace(/[^0-9.-]+/g, '');
  const parsedValue = parseFloat(cleanedValue);
  return parseFloat(parsedValue.toFixed(2)); // Fixed to two decimal places
}


export function hardParseFloatReturnString(value: string | number): string {
  const cleanedValue = String(value).replace(/[^0-9.-]+/g, '');
  const parsedValue = parseFloat(cleanedValue);
  return parsedValue.toFixed(2); // Always includes two decimal places
}

export const generateDayWiseTimeSeries = (baseDate: any, count: any, yRange: any) => {
  let series = [];
  let baseTime = baseDate;
  for (let i = 0; i < count; i++) {
    const y = Math.floor(Math.random() * (yRange.max - yRange.min + 1) + yRange.min);
    series.push([baseTime, y]);
    baseTime += 86400000; // Add 1 day in milliseconds
  }
  return series;
};

export function capitalizeFirstLetter(string: string) {
    if (!string || typeof string !== 'string') {
      return '';
    }
  
    const firstLetter = string.charAt(0).toUpperCase();
    const restOfString = string.slice(1);
  
    return firstLetter + restOfString;
  }

  export function  splitStringWithCommas(string: string) {
    try {
      if (typeof string !== 'string') {
        throw new Error('Invalid input. Input must be a string.');
      }
  
      if (string.trim().length === 0) {
        return [];
      }
  
      const array = string.split(',').map((item) => item.trim());
  
      return array;
    } catch (error) {
      console.error('Failed to break string:', error);
      return [];
    }
  }

  export function getRelationalDateTime(dateInput: Date | string): string {
    // If dateInput is a string, create a new Date object from it. Otherwise, use it directly.
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

    if (isNaN(date.getTime())) {
      // If date is not valid, return 'Invalid date'
      return 'Invalid date';
    }

    // Create a new Date object for the current date
    const now = new Date();

    // Calculate the time difference in milliseconds
    const timeDiff = now.getTime() - date.getTime();

    // Calculate the time difference in seconds, minutes, hours, and days
    const secondsDiff = Math.floor(timeDiff / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    const daysDiff = Math.floor(hoursDiff / 24);

    if (secondsDiff < 60) {
      return `${secondsDiff} seconds ago`;
    } else if (minutesDiff < 60) {
      return `${minutesDiff} minutes ago`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff} hours ago`;
    } else if (daysDiff === 1) {
      return 'Yesterday';
    } else {
      const day = date.getDate();
      const month = date.getMonth() + 1; // months are zero-based
      const year = date.getFullYear();
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    }
}
