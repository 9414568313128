import React from 'react'
import { Typography, Stack } from '@mui/material'
import LogoAvatar from './logo.jpeg'
import colorPalette from 'theme/colorPalette'

const Logo = () => {
  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <img
          src={LogoAvatar}
          alt="Logo"
          style={{
            width: "3.2rem",
            height: "3.2rem",
            transform: "rotate(12.08deg)",
            borderRadius: "1.85675rem",
            background: colorPalette.white,
          }}
        />
        <Typography
          variant="h3"
          sx={{
            color: colorPalette.gray_600,
            fontWeight: 700,
          }}
        >
          Coins
        </Typography>
      </Stack></div>
  )
}

export default Logo