import React from 'react';
import { useLogout } from "@refinedev/core";
import { Button, Link, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import colorPalette from '../theme/colorPalette';

export const LogoutButton = () => {
    const { mutate: logout } = useLogout();

    return (
        <Link
            href="#"
            underline="none"
            onClick={() => logout()}
            sx={{
                width: '100%',
                padding: '8px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
                background: 'rgba(243, 129, 129, 0.2)', // Semi-transparent red background
                border: 'none',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                backdropFilter: 'blur(10px)', // Blur effect for glassmorphism
                WebkitBackdropFilter: 'blur(10px)', // Corrected for cross-browser compatibility
                transition: 'background 0.3s ease, transform 0.3s ease',
                '&:hover': {
                    background: 'rgba(243, 60, 60, 0.25)', // Slightly darker on hover
                    transform: 'scale(1.02)' // Scale up slightly on hover
                }
            }}
        >
            <Typography
                variant="body1"
                style={{
                    color: '#d16037', // A strong, contrasting orange
                    fontWeight: 'bold' // Optional: makes the text stand out more
                }}
            >
                Logout now
            </Typography>
            <ArrowForwardIcon
                sx={{
                    color: '#d16037' // Ensuring the icon matches the text color
                }}
            />
        </Link>
    );

};
