import { createTheme } from '@mui/material';

import colorPalette from './colorPalette';


const cocowahTheme = createTheme({
  typography: {
    fontFamily: [
      'Manrope',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '3rem',
      fontWeight: '600', // Updated font weight
      color: colorPalette.black,
      textAlign: 'left',
      // Add other styles as needed
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '2rem',
      fontWeight: '600', // Updated font weight
      color: colorPalette.black,
      textAlign: 'left',
      // Add other styles as needed
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1.5rem',
      fontWeight: '600 !important', // Updated font weight
      color: colorPalette.black,
      textAlign: 'left',
      // Add other styles as needed
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1.2rem',
      fontWeight: '500 !important', // Updated font weight
      color: colorPalette.black,
      textAlign: 'left',
      // Add other styles as needed
    },

    h5: {
      fontFamily: 'Manrope',
      fontSize: '1rem',
      fontWeight: '600 !important',
      color: colorPalette.black,
      textAlign: 'left',
      // Add other styles as needed
    },
    h6: {
      fontFamily: 'Manrope',
      fontSize: '0.84rem',
      fontWeight: '600 !important',
      textTransform: 'uppercase',
      color: colorPalette.gray_500,
      textAlign: 'left',
      // Add other styles as needed
    },
    body1: {
      color: "#000",
      fontSize: "0.880rem",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
      textAlign: "left",
      textShadow: 'none'
    },
    body2: {
      color: "#000",
      fontSize: "0.860rem",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.3rem",
      textAlign: "left",      
      textShadow: "1px 1px 0px rgba(225, 255, 0, 0.5)"
    },
    subtitle1: {
      color: "#000",
      fontSize: "0.840rem",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.5rem",
      textAlign: "left",
    },
    subtitle2: {
      color: "inherit",
      fontSize: "0.780rem",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.3rem",
      textAlign: "left",
    }
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: () => ({
          backgroundColor: colorPalette.Blue_500,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          alignItems: 'flex-start',
          minWidth: '180px',
          // Conditional styling
          [`&.coco-tabs-main`]: {
            borderRadius: '0.25rem',
            border: '1px solid #EDEFF1',
            background: '#FFF',
            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
            '&.Mui-selected': {
              borderRadius: '0.25rem',
              borderBottom: '2px solid #000',
              background: '#EDEFF1',
              boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset',
            },
            '&:hover': {
              backgroundColor: 'lightgrey',
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colorPalette.gray_050,
          padding: '40px 24px',
          '&.drawer280': {
            width: '17.5rem !important', // Equivalent to 280px when base font size is 16px
          },
          '&.drawer40': {
            width: '26.25rem !important', // Equivalent to 420px when base font size is 16px
          },
        },
        root: {
          '&.drawer280': {
            width: '17.5rem', // Equivalent to 280px when base font size is 16px
          },
          '&.drawer40': {
            width: '26.25rem', // Equivalent to 420px when base font size is 16px
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Removes shadow
          minHeight: 'unset',
          marginBottom: '4px', // Adds gap between each accordion
          '&:before': {
            display: 'none', // Removes the default line at the top of the accordion
          },
          borderRadius: '2px !important',
          // borderBottom: `1px solid ${colorPalette.gray_200}`,
          '& .MuiButtonBase-root': { // Targeting ButtonBase within AccordionSummary
            minHeight: 'unset !important', // Setting minHeight to 0 or 'unset'\
            height: '32px'
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: 'auto',
          },
          '& .MuiTypography-root': {
            textTransform: 'capitalize', // Title casing
            color: 'black', // Black color
          }
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'Kadwa, sans-serif',
          fontSize: '1.5rem',
          color: colorPalette.black,
          display: '-webkit-box !important',
        },
        primary: {
          fontFamily: 'Kadwa, sans-serif',
          fontSize: '1.5rem',
          color: colorPalette.black,
          display: '-webkit-box !important',
        },

        secondary: {
          fontFamily: 'Kadwa, sans-serif',
          fontSize: '1rem',
          color: colorPalette.black,
          display: '-webkit-box !important',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid',
          borderRadius: '40px'
        },
      },
    },
    MuiTextField: {
      // styleOverrides: {
      //   root: {
      //     height: '40px'
      //   }
      // },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            width: "18rem",
            borderRadius: "0.25rem",
            border: colorPalette.gray_900,
            background: colorPalette.white
          },
        },
      ]
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       height: '40px'
    //     }
    //   }
    // },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          width: 'auto',
          textTransform: 'none',
          height: '48px'
        },
        contained: {
          backgroundColor: colorPalette.Green_600,
          color: colorPalette.white,
          padding: '0.5rem 2rem',
          alignSelf: 'center',

          '&:hover': {
            backgroundColor: colorPalette.Green_700,
            color: colorPalette.white,
            transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
          },
          // Styles for the 'white-btn' class
          '&.white-btn': {
            backgroundColor: colorPalette.white,
            color: colorPalette.gray_800,
            borderColor: colorPalette.gray_800,

            '&:hover': {
              backgroundColor: 'transparent',
              color: colorPalette.white,
              borderColor: colorPalette.gray_800,
            },
          },
        },
        outlined: {          
          borderRadius: 20,
          height: '36px',
          color: colorPalette.Blue_600,
          borderColor: colorPalette.Blue_600,
          '&:hover': {
            backgroundColor: colorPalette.Blue_600,
            color: colorPalette.white,
            transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
          },          
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '1.5rem',
          padding: '0',
          borderRadius: '0.2rem',
          border: '0',
          backgroundColor: 'transparent',
          '&.merchant-section': {
            borderColor: colorPalette.Amber_500,
          },
          '&.merchant-shelf': {
            borderColor: colorPalette.Amber_500,
          },
          '&.merchant-box': {
            borderColor: colorPalette.Amber_500,
          },
          '&.income-section': {
            borderColor: colorPalette.Blue_500,
          },
          '&.income-shelf': {
            borderColor: colorPalette.Blue_500,
          },
          '&.income-box': {
            borderColor: colorPalette.Blue_500,
          },
          '&.expense-section': {
            borderColor: colorPalette.Orange_500,
          },
          '&.expense-shelf': {
            borderColor: colorPalette.Orange_500,
          },
          '&.expense-box': {
            borderColor: colorPalette.Orange_500,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.active.column-tree-list': {
            background: '#ededed',
            width: '16em !important',
          },
          '&.column-tree-list': {
            width: '16em !important',
          },
          '&.nav-item': {
            opacity: 0.4,
            transition: 'opacity 0.3s',
            '&:hover': {
              opacity: 0.8,
            },
          },
          '&.nav-item.active': {
            opacity: 1,
          },

        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.transactions-table': {
            boxShadow: 'unset',
          },
        },
      }
    },
  },
});

export default cocowahTheme;
