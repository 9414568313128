import React, { useState, useEffect } from "react";
import { LinearProgress, Typography, Button } from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { handleImageUpload, handleDeleteDocument } from "../api-routers/TransactionsRouter";
import colorPalette from "../theme/colorPalette";

import {
    Stack, Box, Checkbox, IconButton,
    Menu, MenuItem, Divider
} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { color } from "@pankod/refine-mui";

const CocoReceiptUpload = ({ uploadedReceiptCloudURL, transactionId, transactionStatus, transactionType, updatedImage, viewMode = 'expanded' }: {
    uploadedReceiptCloudURL: string | null | undefined;
    transactionStatus?: string | null | undefined;
    transactionType: 'income' | 'expense' | null | undefined;
    transactionId: string; updatedImage: () => void;
    viewMode?: 'collapsed' | 'expanded';
}) => {
    const [uploadStatus, setUploadStatus] = useState("not uploaded");
    const [isLoading, setIsLoading] = useState(false);
    const [uploadError, setUploadError] = useState("");
    const [isCollapsed, setIsCollapsed] = useState(viewMode === 'collapsed');
    let cancelToken;

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [useReceiptScanner, setUseReceiptScanner] = useState(transactionType === 'expense');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentMessage, setCurrentMessage] = useState("");
    const [isHoveringDoc, setIsHoveringDoc] = useState(false);

    // Array of personal finance quotes


    useEffect(() => {
        let intervalId: NodeJS.Timeout | number | undefined;


        const quotes = [
            "Saving is a great habit but without investing, it’s only half the story.",
            "Do not save what is left after spending, but spend what is left after saving.",
            "It’s not your salary that makes you rich, it’s your spending habits.",
            // Add more quotes as needed
        ];

        if (isLoading) {
            // Set the initial message for the first three seconds
            setCurrentMessage("Scanning your receipt");
            let quoteIndex = 0;

            // Change the message every three seconds
            intervalId = setInterval(() => {
                setCurrentMessage(quotes[quoteIndex]);
                quoteIndex = (quoteIndex + 1) % quotes.length;
            }, 3000);
        } else {
            setCurrentMessage(""); // Reset the message when not loading
        }

        // Clean up the interval on unmount or when loading stops
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isLoading]);


    const handleClick = (event: React.MouseEvent<HTMLElement>) => { // Specify the type for event
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDocMenu = () => {
        setAnchorEl(null);
    };


    const handleDeleteDocumentAndUpdate = async () => {
        try {
            handleCloseDocMenu();
            // Attempt to execute the delete document function
            await handleDeleteDocument(transactionId);
            // If successful, update the image
            updatedImage();
        } catch (error) {
            // Handle any errors that occur
            console.error("An error occurred while deleting the document:", error);
            // Optionally, you can add additional error handling logic here
        }
    }

    const handleReplaceDocument = () => {
        console.log("Replacing document");
    }

    const handleOpenImageModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setOpenImageModal(true);
    };

    const handleCloseImageModal = () => {
        setSelectedImage(null);
        setOpenImageModal(false);
    };

    const toggleView = () => {
        setIsCollapsed(!isCollapsed); // Toggles the state between true and false
    };

    const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            setIsLoading(true);
            setUploadError("");

            handleImageUpload(selectedFile, transactionId, useReceiptScanner)
                .then((response) => {
                    // Handle success, call prop updatedImage
                    setIsLoading(false);
                    updatedImage();
                })
                .catch((error) => {
                    // Handle error
                    setIsLoading(false);
                    setUploadError("Failed to process");
                });
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const selectedFile = event.dataTransfer.files[0];

        if (selectedFile) {
            setIsLoading(true);
            setUploadError("");

            handleImageUpload(selectedFile, transactionId, useReceiptScanner)
                .then((response) => {
                    // Handle success, call prop updatedImage
                    setIsLoading(false);
                    updatedImage();
                })
                .catch((error) => {
                    // Handle error
                    setIsLoading(false);
                    setUploadError("Failed to process");
                });
        }
    };

    const toggleReceiptScanner = () => {
        setUseReceiptScanner(!useReceiptScanner);
    };

    return (
        <div>
            <div>
                {uploadedReceiptCloudURL ? (
                    <Box
                        width={'100px'}
                        height={'100px'}
                        overflow={'hidden'}
                        marginLeft={1}
                        onMouseEnter={() => setIsHoveringDoc(true)}
                        onMouseLeave={() => setIsHoveringDoc(false)}
                        style={{
                            position: 'relative', // Added for positioning the overlay
                            cursor: 'pointer',
                            borderRadius: '8px',
                            border: `1px solid ${colorPalette.gray_400}`,
                            background: colorPalette.gray_200,
                            padding: 0,
                        }}
                    >
                        <img
                            src={uploadedReceiptCloudURL}
                            alt="Document"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '8px',
                                objectFit: 'cover',
                            }}
                            onClick={() => handleOpenImageModal(uploadedReceiptCloudURL)}
                        />
                        <Box
                            style={{
                                position: 'absolute', // Positions the Box over the image
                                right: 0,
                                top: 0,
                                width: '24px',
                                height: '24px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: 1, // Hide by default
                                transition: 'opacity 0.3s',
                                cursor: 'pointer',
                            }}
                            onClick={handleClick} // Assuming this opens the menu or additional options
                        >
                            {isHoveringDoc && (
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        background: colorPalette.gray_100,
                                    }}
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            )}

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseDocMenu}
                            >
                                <MenuItem onClick={() => handleDeleteDocumentAndUpdate()}>
                                    Remove document
                                </MenuItem>
                                <MenuItem onClick={() => handleReplaceDocument()}>
                                    Replace document
                                </MenuItem>
                            </Menu>
                            {/* Add more icons or buttons here for additional options */}
                        </Box>
                    </Box>
                ) : (
                    <Stack
                        direction={'column'} gap={3}>
                        <Box
                            textAlign="center"
                            p={1}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            width={isCollapsed ? '180px' : '100%'}
                            sx={{
                                borderRadius: 2,                            
                                background: (isCollapsed || !useReceiptScanner) ? colorPalette.blue_gray_050 : colorPalette.blue_gray_050,
                                borderColor: (isCollapsed || !useReceiptScanner) ? colorPalette.gray_300 : colorPalette.gray_300,
                                borderWidth: 2,
                                borderStyle: isCollapsed ? 'solid' : 'dashed', // This makes the border dashed
                            }}
                        >
                            {!isLoading && (
                                <>
                                    {isCollapsed ? (
                                        <Button variant="text" onClick={toggleView}>
                                            <AutoAwesomeIcon sx={{mr: '8px'}}/>
                                            Scan receipts
                                        </Button>

                                    ) : (
                                        <Stack gap={1} width={'100%'} justifyContent={'center'}>
                                            <InsertDriveFileIcon sx={{ color: colorPalette.blue_gray_300, fontSize: '40px', margin: 'auto' }} />
                                            <Typography textAlign={"center"} variant="body1">Drop your receipt here</Typography>
                                            <Typography textAlign={"center"} variant="body2">OR</Typography>
                                            <Box
                                                bgcolor={colorPalette.Blue_600}
                                                color="white"
                                                mt={1}
                                                p={0}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderRadius: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    textAlign: "center",
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                    height: '36px',
                                                    maxWidth: '260px',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    margin: 'auto',
                                                    ':hover::before': { // Shine effect on hover using pseudo-element
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: '-50%',
                                                        bottom: 0,
                                                        width: '50%',
                                                        background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4), transparent)',
                                                        animation: 'shineAnimation 1s ease',
                                                        pointerEvents: 'none',
                                                    },
                                                    '@keyframes shineAnimation': { // Defining the keyframes inside the sx prop
                                                        from: { left: '-50%' },
                                                        to: { left: '100%' },
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    textAlign={"center"}
                                                    variant="body2"
                                                    color="inherit" // Inherit the color from the parent Box
                                                    onClick={() => document?.getElementById('upload-receipt')?.click()}
                                                >
                                                    Click here to browse (Max 4MB)
                                                </Typography>
                                            </Box>
                                            {transactionType === 'expense' && (
                                                <Stack direction={"row"} alignItems={'center'} justifyContent={'center'} onClick={toggleReceiptScanner}>
                                                    <Checkbox checked={useReceiptScanner} color="default" sx={{ color: colorPalette.Blue_600 }} />
                                                    <Typography variant="body2" paddingTop={'4px'} sx={{ color: colorPalette.gray_700, textDecoration: 'underline', cursor: 'pointer' }} >
                                                        Enable magic scan to automatically add transaction details
                                                    </Typography>
                                                </Stack>
                                            )}

                                            

                                        </Stack>)}



                                </>
                            )}
                            {isLoading && (
                                <Box width={'100%'} sx={{ background: colorPalette.Blue_050 }} justifyContent={'center'} >
                                    <Stack mb={0} width={'100%'} height={'27px'}>
                                        <LinearProgress />
                                    </Stack>
                                    <Typography textAlign={"center"} variant="body1">
                                        {currentMessage}
                                    </Typography>
                                    <Stack direction={"row"} justifyContent={'center'}>
                                        <Checkbox checked={useReceiptScanner} color="default" sx={{ color: colorPalette.Blue_300 }} />
                                        <Typography variant="body2" paddingTop={'4px'} sx={{ color: colorPalette.Blue_300, textDecoration: 'underline', cursor: 'pointer' }} >
                                            Enable magic scan to automatically transaction details
                                        </Typography>
                                    </Stack>

                                    <Box
                                        bgcolor={colorPalette.Blue_300}
                                        color="white"
                                        mt={1}
                                        p={0}
                                        sx={{ cursor: 'pointer' }}
                                        borderRadius={1}
                                        display="flex"
                                        justifyContent={'center'}
                                        textAlign={"center"}
                                        width={'100%'}
                                    >
                                        <Typography
                                            textAlign={"center"}
                                            variant="body2"
                                            color={colorPalette.white}
                                        >
                                            Processing image...
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {uploadError && (
                                <div>
                                    <Typography variant="body1" color="error">
                                        {uploadError}
                                    </Typography>
                                </div>
                            )}
                        </Box>
                    </Stack>
                )}
            </div>
            <div>
                {openImageModal && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            zIndex: 9999
                        }}
                        onClick={handleCloseImageModal}
                    >
                        <img
                            src={selectedImage || ''}
                            alt="Enlarged Document"
                            style={{ maxWidth: '90%', maxHeight: '90%' }}
                        />
                        <Button
                            variant="contained"
                            style={{ position: 'absolute', top: '5%', right: '5%' }}
                            onClick={handleCloseImageModal}
                        >
                            Close
                        </Button>
                    </div>
                )}
                <input
                    type="file"
                    id="upload-receipt"
                    style={{ display: 'none' }}
                    accept="image/*"
                    capture='environment'
                    onChange={handleImageSelect}
                />
            </div>
        </div>
    );
};

export default CocoReceiptUpload;
