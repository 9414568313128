import axios from "axios";
export const validateUser = () => {
    const userInfoString = localStorage.getItem("user");
    const user = userInfoString ? JSON.parse(userInfoString) : null;
    const token = localStorage.getItem("token");
  
    if (token && typeof window !== "undefined" && user && user.email) {
        return {
            user_pack: { email: user.email, id: user.userid, name: user.name, avatar: user.avatar }
          };
    }
    else {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        axios.defaults.headers.common = {};
        if(token && typeof window !== "undefined"){
        window.google?.accounts.id.revoke(token, () => {
          return {};
        })};
    }  
    // Redirect to the desired page after logout
    // window.location.href = "/login";
  };