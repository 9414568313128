import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import colorPalette from "theme/colorPalette";


interface ResentOtpProps {
  handleVerifyOtpSubmit: () => void;
}


const ResentOtp: React.FC<ResentOtpProps> = ({
  handleVerifyOtpSubmit
}) => {
  // State variables to manage OTP input, minutes, and seconds
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    // Function to handle the countdown logic
    const interval = setInterval(() => {
      // Decrease seconds if greater than 0
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      // When seconds reach 0, decrease minutes if greater than 0
      if (seconds === 0) {
        if (minutes === 0) {
          // Stop the countdown when both minutes and seconds are 0
          clearInterval(interval);
        } else {
          // Reset seconds to 59 and decrease minutes by 1
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000); // Run this effect every 1000ms (1 second)

    return () => {
      // Cleanup: stop the interval when the component unmounts
      clearInterval(interval);
    };
  }, [seconds]); // Re-run this effect whenever 'seconds' changes

  // Function to resend OTP
  const resendOTP = () => {
    handleVerifyOtpSubmit()
    setMinutes(1);
    setSeconds(30);
  };

  return (
    <Stack direction="row"
      alignItems="center"
      spacing={1}
      width={'100%'}
      justifyContent={'space-between'}
      >
      <Typography variant="body2" align="center">
        {/* Display countdown timer if seconds or minutes are greater than 0 */}

        {seconds > 0 || minutes > 0 ? (
          <p>
            Time Remaining:{" "}
            <span style={{ fontWeight: 600 }}>
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </p>
        ) : (
          // Display if countdown timer reaches 0
          <p>Didn't receive code?</p>
        )}
      </Typography>
      <Button variant="text"
        disabled={seconds > 0 || minutes > 0}
        style={{
          color: seconds > 0 || minutes > 0 ? colorPalette.gray_300 : colorPalette.Blue_900,
        }}
        onClick={resendOTP}
      >
        Resend OTP
      </Button>
    </Stack>
  );
}

export default ResentOtp;