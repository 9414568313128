import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { getRelationalDateTime } from '../../services/handyFunctions';
import { styled } from '@mui/system';
import colorPalette from 'theme/colorPalette';

const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
];

const HoverTypography = styled(Typography)({
    transition: 'background-color 0.3s',
    padding: '4px',
    borderRadius: 4,  // for rounded corners
    '&:hover': {
        backgroundColor: '#f5f5f5',  // You can replace this with any color you prefer
    },
    
    color: colorPalette.gray_600
});

function formatDate(input: any) {
    const date = typeof input === 'string' ? new Date(input) : input;

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export default function DateDisplay(props: any) {
    const { dateInput } = props;
    const [toggle, setToggle] = useState(false);

    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

    const handleClick = () => {
        setToggle(!toggle);
    };

    return (
        <HoverTypography
            variant="subtitle2"
            onClick={handleClick}
            style={{ backgroundColor: toggle ? '#f5f5f5' : 'inherit' }}
        >
            {!toggle ? formatDate(dateInput) : getRelationalDateTime(dateInput)}
        </HoverTypography>
    );
}
