import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Stack, IconButton, Typography, Card, CardContent, Button, Divider,
} from '@mui/material';
import { ViewList as ViewListIcon, ViewModule as ViewModuleIcon } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SubTransaction } from 'interfaces/subtransaction';
import { getAllCategories, getAllCategoriesAsSections, addNewCategory } from '../api-routers/CategoriesRouter';
import colorPalette from 'theme/colorPalette';
import { hardParseFloat } from '../services/handyFunctions';
import CocoField from './CocoField';
import { DataType } from '../components/CocoCategorySearch';
import CocoCategorySearch from '../components/CocoCategorySearch';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';
import { display } from '@pankod/refine-mui';
import English_Locale from '../locales/english/english.json';
import {
  deleteSubTransaction,
} from "api-routers/TransactionsRouter";

export interface SubTransactionTableProps {
  transactionId: string;
  transactionAmount: number;
  transactionType: string;
  allSubTransactions: SubTransaction[];
  // onDelete: (transactionId: string) => void;
  onEdit: (transactionToEdit: { rowId: string, cellType: string, oldValue: string, newValue: string }) => void;
  onCreateNewSubTransaction: (fieldName: string, newValue: string) => Promise<string>;
  isLoading?: boolean;
}

const SubTransactionTable: React.FC<SubTransactionTableProps> = ({
  transactionId,
  transactionAmount,
  transactionType,
  allSubTransactions,
  // onDelete,
  onEdit,
  onCreateNewSubTransaction,
  isLoading = false
}) => {

  const [categories, setCategories] = useState<[]>([]);
  const [editableCell, setEditableCell] = useState<{ rowId: string, cellType: string } | null>(null);
  const [oldValue, setOldValue] = useState<string>('');
  const [editableValue, setEditableValue] = useState<string>('');
  const [addedCategoriesIDString, setAddedCategoriesIDString] = useState<string>('');
  const [categoriesData, setCategoriesData] = useState<DataType[]>([]);
  const totalItems = allSubTransactions.length;
  const subTotal = allSubTransactions.reduce((acc, subTransaction) => {
    return acc + (subTransaction.amountBreakdown.totalCost || subTransaction.amountBreakdown.cost || 0);
  }, 0);
  const totalTax = allSubTransactions.reduce((acc, subTransaction) => {
    return acc + (subTransaction.amountBreakdown.tax1 || 0);
  }, 0);
  console.log("subsubsub", allSubTransactions.map(subTransaction => subTransaction.itemName));
  const [subTransactions, setSubTransactions] = useState<SubTransaction[]>([])

  // Sorting logic
  const [sortBy, setSortBy] = useState<'itemName.name' | 'amountBreakdown.totalCost' | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    // Reset to default values
    setSubTransactions(allSubTransactions);
    setSortBy(null);
    setSortDirection('asc');
  }, [allSubTransactions]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedCategories = await getAllCategories();
      setCategories(fetchedCategories.categories);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let categoriesDataResponse;

      if (transactionType === "expense") {
        categoriesDataResponse = await getAllCategoriesAsSections("expense");
      } else if (transactionType === "income") {
        categoriesDataResponse = await getAllCategoriesAsSections("income");
      } else {
        // Handle the case where transactionType is neither "expense" nor "income"
        throw new Error("Invalid transactionType");
      }

      setCategoriesData(categoriesDataResponse[0].children);
    };

    fetchData();
  }, [transactionType]);

  const handleCellClick = (cellType: string, rowId: string, currentValue: string) => {
    if (editableCell) return;
    setEditableCell({ cellType, rowId });
    setOldValue(currentValue);
    setEditableValue(currentValue);

    if (cellType === 'category') {
      const currentCategories = subTransactions.find(trans => trans.id === rowId)?.category;
      if (currentCategories) {
        const categoryIds = currentCategories.map(cat => typeof cat === 'string' ? cat : cat.id);
        setAddedCategoriesIDString(categoryIds.join(','));
      }
    }
  };

  const handleCellSave = (rowId: string, cellType: string, oldValue: string, newValue: string) => {
    // Check if the newValue is the same as the oldValue
    if (newValue !== oldValue) {
      onEdit({ rowId, cellType, oldValue, newValue });
      toast.success(`Updated ${cellType}`);
    }

    // Reset regardless of whether an update was made
    setEditableCell(null);
    setOldValue('');
    setAddedCategoriesIDString('');
  };

  const handleDelete = (subTransactionId: string) => {
    //onDelete(subTransactionId);
    try {
      // Remove the deleted sub-transaction from the state
      setSubTransactions((prevSubTransactions) =>
        prevSubTransactions.filter(
          (subTransaction) => subTransaction.id !== subTransactionId
        )
      );
      deleteSubTransaction(subTransactionId);
    } catch (error) {
      console.error("Error deleting sub-transaction:", error);
    }
  };

  const handleHeaderClick = (field: 'itemName.name' | 'amountBreakdown.totalCost') => {
    if (sortBy === field) {
      setSortDirection(prevDirection => (prevDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  const getSortedTransactions = () => {
    const sorted = [...subTransactions];

    if (sortBy === 'itemName.name') {
      sorted.sort((a, b) => a.itemName?.name.localeCompare(b.itemName?.name));
    } else if (sortBy === 'amountBreakdown.totalCost') {
      sorted.sort((a, b) =>
        (a.amountBreakdown.totalCost || a.amountBreakdown.cost || 0) -
        (b.amountBreakdown.totalCost || b.amountBreakdown.cost || 0)
      );
    }

    if (sortDirection === 'desc') sorted.reverse();
    return sorted;
  };

  const SortIcon: React.FC<{ direction: 'asc' | 'desc' }> = ({ direction }) => {
    return <span>{direction === 'asc' ? '↑' : '↓'}</span>;
  };

  const addAndAssignCategoryToSubTnx = async (categoryName: string, parentId: string, categoryType: string, subTransactionId: string) => {
    try {
      const response = await addNewCategory(categoryName, parentId, `${transactionType}-${categoryType}`);
      if (response?.category?.name === categoryName) {
        handleCellSave(subTransactionId, 'itemName.name', '', categoryName);
      }
    } catch (error) {
      console.error("Error in addAndAssignCategoryToSubTnx:", error);
    }
  }

  const addAndAssignCategoryToNewSubTnx = async (categoryName: string, parentId: string, categoryType: string) => {
    try {
      const response = await addNewCategory(categoryName, parentId, `${transactionType + '-' + categoryType}`);
      if (response?.category?.name === categoryName) {
        handleCreateNewSubTransaction('itemName.name', categoryName);
      }
    } catch (error) {
      console.error("Error in addAndAssignCategoryToNewSubTnx:", error);
    }
  }
  // Function to create a new SubTransaction object
  const createSubTransaction = (itemName: string, totalCost: number, notes: string): SubTransaction => {
    const newSubTransaction: SubTransaction = {
      id: undefined, // Generate a new ID or set as undefined if the ID is generated by the backend
      itemName: {
        // Assuming itemName is an object with name and id properties
        id: undefined, // Generate a new ID or set appropriate value
        name: itemName
      },
      category: [], // Set default or appropriate value
      amountBreakdown: {
        totalCost: totalCost,
        currency: 'CAD'
      },
      transactionId: undefined, // Set this based on your application context
      createdDate: new Date(),
      notes: notes
    };

    return newSubTransaction;
  };
  // Function to handle creation of a new SubTransaction
  const localCreateNewSubTransaction = async (fieldName: string, newValue: string): Promise<SubTransaction> => {
    let newSubTransaction: SubTransaction;

    switch (fieldName) {
      case 'itemName.name':
        newSubTransaction = createSubTransaction(newValue, 0, '');
        break;
      case 'amountBreakdown.totalCost':
        newSubTransaction = createSubTransaction('', hardParseFloat(newValue), '');
        break;
      case 'notes':
        newSubTransaction = createSubTransaction('', 0, newValue);
        break;
      default:
        // Return a rejected promise or throw an error
        throw new Error(`Unknown fieldName: ${fieldName}`);
    }

    return newSubTransaction;
  };

  const handleCreateNewSubTransaction = async (fieldName: any, newValue: any) => {
    try {
      console.log('CreateNewSubTransaction', fieldName, newValue);
      // Create the initial sub-transaction object
      const newSubTransaction = await localCreateNewSubTransaction(fieldName, newValue);

      // Add this object to the subTransactions array
      setSubTransactions([...subTransactions, newSubTransaction]);

      // Call the function from the parent component to create the sub-transaction and get the ID
      const newId = await onCreateNewSubTransaction(fieldName, newValue);

      // Update the ID of the newly added sub-transaction
      setSubTransactions(currentSubTransactions =>
        currentSubTransactions.map(subTransaction =>
          subTransaction === newSubTransaction ? { ...subTransaction, id: newId } : subTransaction
        )
      );
    } catch (error) {
      console.error("Failed to create new sub transaction:", error);
      // Handle error accordingly
    }
  };

  return (
    <Stack>
      <Stack sx={{ background: colorPalette.gray_050 }} padding={2} border={0} borderColor={colorPalette.gray_300} pb={16}>
        <TableContainer
          component={Paper}
          className='transactions-table'
          style={{
            width: '100%',
            background: 'transparent'
          }}>
          <TableHead
            sx={{
              height: '40px',
              marginBottom: '12px',
              border: 0,
              display: 'inline-table',
              width: '100%'
            }}>
            <TableRow>
              <TableCell
                onClick={() => handleHeaderClick('itemName.name')}
                style={{
                  padding: '0 0 0 12px',
                  backgroundColor: sortBy === 'itemName.name' ? colorPalette.Blue_050 : undefined,
                  verticalAlign: 'bottom',
                  borderBottom: 0
                }}
              ><Typography variant="body1" color={colorPalette.gray_800}>
                  {subTransactions.length ? `${hardParseFloat(totalItems)} Items` : 'Items'}</Typography>
                {sortBy === 'itemName.name' && <SortIcon direction={sortDirection} />}
              </TableCell>
              <TableCell
                align="left"
                onClick={() => handleHeaderClick('amountBreakdown.totalCost')}
                style={{
                  padding: '0 0 0 12px',
                  backgroundColor: sortBy === 'amountBreakdown.totalCost' ? colorPalette.Blue_050 : undefined,
                  width: '20%',
                  verticalAlign: 'bottom',
                  borderBottom: 0

                }}
              >
                {Math.abs(transactionAmount - (subTotal + totalTax)) > 0.01 ? (
                  <Typography variant="subtitle2" color={colorPalette.Amber_800}>
                    {English_Locale.total_amount_sum_error}
                  </Typography>
                ) : null}
                <Typography variant="body1" color={colorPalette.gray_800}>
                  {subTransactions.length ? `Total $ ${hardParseFloat(subTotal + totalTax)}` : '($)'}
                </Typography>
                {sortBy === 'amountBreakdown.totalCost' && <SortIcon direction={sortDirection} />}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  padding: '0 0 0 12px',
                  width: '30%',
                  verticalAlign: 'bottom',
                  borderBottom: 0
                }}>
                <Typography variant="body1" color={colorPalette.gray_800}>Details</Typography></TableCell>
              <TableCell
                align="right"
                style={{
                  padding: '0 0 0 12px',
                  width: '10%',
                  verticalAlign: 'bottom',
                  borderBottom: 0
                }}>
                <Typography variant="body1" color={colorPalette.gray_800}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ display: 'grid' }}>
            {isLoading ? (
              // Skeleton rows when isLoading is true
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}
                  style={{
                    background: colorPalette.gray_200,
                    marginBottom: '4px',
                    boxSizing: 'border-box',
                    display: 'inline-table'

                  }}>
                  <TableCell style={{ padding: '0px 0px', width: "20%" }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell align="right" style={{ padding: '0px 0px', width: "20%" }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell align="left" style={{ padding: '0px 0px', width: "20%" }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell align="right" style={{ padding: '0px 0px', width: "20%" }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                </TableRow>
              ))
            ) : (!subTransactions.length ? (
              <TableRow
                style={{
                  background: colorPalette.gray_200,
                  marginBottom: '8px',
                  // border: `1px solid ${colorPalette.gray_400}`,
                  borderRadius: '8px',
                  boxSizing: 'border-box'
                }}
              >
                <TableCell style={{
                  padding: '0px 0px',
                }}>
                  <CocoCategorySearch
                    data={categoriesData}
                    selectText="expense"
                    onUpdate={(newValue) => handleCreateNewSubTransaction('itemName.name', newValue)}
                    placeholder='Add new item'
                    handleNewCategory={(categoryName, parentId, categoryType) => addAndAssignCategoryToNewSubTnx(categoryName, parentId, categoryType)}
                    background={colorPalette.gray_200}
                    selectMode='selectOnly'
                  />

                </TableCell>
                <TableCell align="right"
                  style={{
                    padding: '0px 0px',
                    width: '15%'
                  }}>
                  <CocoField
                    placeholder={'Add cost'}
                    handleSave={(newValue) => handleCreateNewSubTransaction('amountBreakdown.totalCost', newValue)}
                    pretext='$'
                  />
                </TableCell>
                <TableCell align="right"
                  style={{
                    padding: '0px 0px',
                    width: '28%'
                  }}>
                  <CocoField placeholder={'Add notes'}
                    handleSave={(newValue) => handleCreateNewSubTransaction('notes', newValue)} />
                </TableCell>
                <TableCell align="right" style={{
                  padding: '0px 0px',
                  width: '10%'
                }}>
                  <IconButton aria-label="delete" size="small">
                    <DeleteOutlineIcon />

                  </IconButton>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {
                  getSortedTransactions().map((subTransaction) => {
                    return (
                      <TableRow
                        key={subTransaction.id}
                        style={{
                          backgroundColor: colorPalette.gray_200,
                          marginBottom: '8px',
                          border: `1px solid ${colorPalette.gray_200}`,
                          borderRadius: '8px',
                          boxSizing: 'border-box'
                        }}>
                        <TableCell
                          style={{
                            padding: '0px 0px',
                          }}
                          scope="row"
                          onClick={() => handleCellClick('itemName.name', subTransaction.id, subTransaction.itemName?.name)}
                        >
                          <CocoCategorySearch
                            {...(subTransaction.itemName?.name ? { defaultValue: subTransaction.itemName?.name } : {})}
                            {...(subTransaction.itemName?.categoryStatus === 'draft' ? { badgeLabel: 'New item' } : {})}
                            data={categoriesData}
                            selectText="expense"
                            onUpdate={(newValue) => handleCellSave(subTransaction.id, 'itemName.name', subTransaction.itemName?.name, newValue)}
                            handleNewCategory={(categoryName, parentId, categoryType) => addAndAssignCategoryToSubTnx(categoryName, parentId, categoryType, subTransaction.id)}
                            background={colorPalette.gray_200}
                          />

                        </TableCell>

                        <TableCell align="right"
                          style={{
                            padding: '0px 0px',
                            width: '20%'
                          }}>
                          <CocoField
                            value={String(subTransaction.amountBreakdown.totalCost)}
                            handleSave={(newValue) => handleCellSave(subTransaction.id, 'amountBreakdown.totalCost', String(subTransaction.amountBreakdown.totalCost), newValue)}

                          />
                        </TableCell>

                        <TableCell align="right"
                          style={{
                            padding: '0px 0px',
                            width: '30%'
                          }}>
                          <CocoField
                            placeholder={'Add note'}
                            value={subTransaction.notes || ''}
                            handleSave={(newValue) => handleCellSave(subTransaction.id, 'notes', subTransaction.notes || '', newValue)}
                            background={colorPalette.gray_200}
                          />
                        </TableCell>

                        <TableCell align="right" style={{ padding: '0px 0px', width: '10%' }}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => handleDelete(subTransaction.id)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                <TableRow
                  style={{
                    backgroundColor: colorPalette.gray_200,
                    marginBottom: '8px',
                    // border: `1px solid ${colorPalette.gray_400}`,
                    borderRadius: '8px',
                    boxSizing: 'border-box'
                  }}
                >
                  <TableCell style={{
                    padding: '0px 0px',
                  }}>
                    <CocoCategorySearch
                      data={categoriesData}
                      selectText="expense"
                      onUpdate={(newValue) => handleCreateNewSubTransaction('itemName.name', newValue)}
                      placeholder="Add new line item"
                      selectMode='selectOnly'
                    />
                  </TableCell>
                  <TableCell align="right"
                    style={{
                      padding: '0px 0px',
                      width: '20%'
                    }}>
                    <CocoField placeholder={'Add cost'}
                      handleSave={(newValue) => handleCreateNewSubTransaction('amountBreakdown.totalCost', newValue)}
                    />
                  </TableCell>
                  <TableCell align="right"
                    style={{
                      padding: '0px 0px',
                      width: '30%'
                    }}>
                    <CocoField placeholder={'Add note'}
                      handleSave={(newValue) => handleCreateNewSubTransaction('notes', newValue)} />
                  </TableCell>
                  <TableCell align="right" style={{
                    padding: '0px 0px',
                    width: '10%'
                  }}>
                    <IconButton aria-label="delete" size="small">
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default SubTransactionTable;





