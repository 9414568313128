import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Typography,
  Box,
  Stack,
  TextField,
  Button,
  Pagination,
  Menu,
  MenuItem,
  Skeleton,
  Select,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AllTransactions from '../components/AllTransactions';
import TransactionDetails from '../components/TransactionDetails';
import colorPalette from 'theme/colorPalette';
import { fetchAllTransactions, createEmptyTransaction } from '../api-routers/TransactionsRouter';
import { validateUser } from 'services/validateUser';
import { Transaction } from '../interfaces/transaction';
import EmptyStateWithAction from 'components/loaders/EmptyStateWithAction';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const TransactionsPage = () => {
  const [activeTransaction, setActiveTransaction] = useState<any>(null);
  const [allTransactions, setAllTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  //For sorting
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder, setSortOrder] = useState('latest');
  const [totalPages, setTotalPages] = useState(1);

  // Additional state for the dropdown menu
  const [menuOpen, setMenuOpen] = useState(false);
  const menuButtonRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [searchKey, setSearchKey] = useState('');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1); // Reset page to 1 when changing page size
  };

  const handleTransactionClick = (transaction: any) => {
    setActiveTransaction(transaction);
  };

  const handleOpenMenu = () => {
    setMenuOpen(true); // Set the menu to be open
  };

  const handleCloseMenu = () => {
    setMenuOpen(false); // Set the menu to be closed
  };

  const initiateNewTransaction = async (transactionType: 'income' | 'expense') => {
    try {

      // Use createEmptyTransaction to create a new transaction
      const transactionId = await createEmptyTransaction(transactionType);
      handleCloseMenu();

      // Update the transactions list
      isUpdated();
      // Set the active transaction to the newly created transaction


    } catch (error) {
      console.error("Error initiating a new transaction:", error);
    }
  };

  const loadTransactionsRef = useRef<() => Promise<void>>();

  const loadTransactions = async () => {
    try {
      loadTransactionsRef.current = loadTransactions;
      const validatedUser = validateUser();
      if (validatedUser) {
        const userId = validatedUser.user_pack.id;
        const transactionsResponse = await fetchAllTransactions(userId, page, pageSize, sortOrder, searchKey);

        if (Array.isArray(transactionsResponse.transactions) && transactionsResponse.transactions.length > 0) {
          setAllTransactions(transactionsResponse.transactions);
          setActiveTransaction(transactionsResponse.transactions[0]); // Set the first transaction as active
          setTotalPages(transactionsResponse.totalPages);
          setTimeout(() => {
            setLoading(false);
          }, 400);
        } else {
          // Handle the case where there are no transactions
          setAllTransactions([]);
          setActiveTransaction(null); // Ensure no transaction is set as active if there are none
          setTimeout(() => {
            setLoading(false);
          }, 400);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    loadTransactions();
  }, [page, pageSize, sortOrder]);

  const isUpdated = async () => {
    try {
      loadTransactions();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSortChange = (newSortOrder: string) => {
    setSortOrder(newSortOrder);
    setPage(1); // Reset to the first page when changing sorting order
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setLoading(true);
    setPage(newPage);
  };

  const handleSearch = () => {
    loadTransactions();
  };


  return (
    <>
      {(!loading && allTransactions.length === 0 && !searchKey) ? (
        <Stack>
          <Box padding={2}
            sx={{ background: colorPalette.gray_100 }}>
            <EmptyStateWithAction
              loadingText={"No transactions added"}
              buttonText={"Go to transactions"}
              primaryButtonClick={() => initiateNewTransaction('expense')}
              secondaryButtonClick={() => initiateNewTransaction('income')}
              subtitle="You will be able to view Dashboard and analytics from adding your first transaction"
            />
          </Box>
        </Stack >
      ) : <>
        <Box
          sx={{ width: '100%', backgroundColor: colorPalette.gray_100, height: '100vh', overflow: 'auto' }}
          padding={2}
        >
          <Stack maxWidth={'1180px'} height={'100%'} gap={2} m={'auto'}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography variant="h2" mb={1}>Transactions</Typography>
                <Typography variant='subtitle1' color={colorPalette.gray_800} m={'auto'} mb={2} textAlign={'center'} maxWidth={'640px'}>
                  View and manage your transactions here. Easily add details with receipt scanning.
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} mb={3} alignItems="center">
                <Button variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleOpenMenu}
                  ref={menuButtonRef}
                >
                  Add Transaction
                </Button>
                <Menu
                  anchorEl={menuButtonRef.current}
                  keepMounted
                  open={menuOpen}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={() => initiateNewTransaction('income')}>Add Income</MenuItem>
                  <MenuItem onClick={() => initiateNewTransaction('expense')}>Add Expense</MenuItem>
                </Menu>
              </Stack>
            </Stack>
            <Stack direction='row' gap={1} justifyContent="left" width="100%">
              <Stack
                style={{
                  background: colorPalette.gray_100,
                }}
                justifyContent={'space-between'}
                direction={'row'}
                gap={2}
              >
                <Stack>
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    label="Entries"
                    style={{ minWidth: '60px' }}
                  >
                    <MenuItem value={10}>Show 10</MenuItem>
                    <MenuItem value={25}>Show 25</MenuItem>
                    <MenuItem value={50}>Show 50</MenuItem>
                  </Select>
                </Stack>

                <TextField
                  sx={{ maxWidth: '16rem' }}
                  placeholder="Search transactions"
                  onChange={(e) => setSearchKey(e.target.value)}
                  style={{ flexGrow: 1, alignSelf: 'center' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

              </Stack>

              <Stack direction="row" justifyContent="center">
                <Button
                  aria-controls="sort-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="text"
                >
                  Sort by: <strong>{sortOrder === 'latest' ? 'Latest Transactions' : 'Oldest'}</strong>
                </Button>
                <Menu
                  id="sort-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => handleSortChange('latest')}
                    selected={sortOrder === 'latest'}
                  >
                    Latest Transactions
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSortChange('oldest')}
                    selected={sortOrder === 'oldest'}
                  >
                    Oldest
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} sx={{ height: '80%' }}>
              <Stack id="transactions-list"
                sx={{
                  minWidth: '240px',
                  maxWidth: '480px',
                  borderRadius: '8px',
                  overflowY: 'hidden',
                  position: 'relative', // This will contain the absolutely positioned pagination within the box
                  height: '100%', // Subtract the height of the pagination + some padding from the viewport height
                  paddingBottom: '70px', // Add padding to the bottom to account for the pagination,
                  width: '24rem',
                }}
              >

                <Box height={"100%"} overflow={"auto"}>
                  {loading ? (
                    <Stack direction={'column'} gap={2}>
                      {Array.from({ length: 8 }).map((_, index) => (
                        <Box key={index} pl={2} pr={2} pt={3} pb={3} border={2} borderRadius={2} borderColor={colorPalette.gray_100}>
                          <Stack gap={2}>
                            <Stack direction="row" justifyContent="space-between">
                              <Skeleton variant="rectangular" width={200} height={20} sx={{ borderRadius: '2px' }} />
                              <Skeleton variant="rectangular" width={80} height={20} sx={{ borderRadius: '2px' }} />
                            </Stack>
                            <Stack direction="row" justifyContent="space-between">
                              <Skeleton variant="rectangular" width={120} height={20} sx={{ borderRadius: '2px' }} />
                              <Skeleton variant="rectangular" width={100} height={20} sx={{ borderRadius: '2px' }} />
                            </Stack>
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  ) : (
                    <AllTransactions
                      data={allTransactions}
                      onTransactionClick={handleTransactionClick}
                      activeTransaction={activeTransaction}
                    />
                  )}
                </Box>

                <Stack
                  style={{
                    position: 'absolute',
                    background: colorPalette.gray_100,
                    width: '100%',
                    bottom: '0px', /* Adjust the value to control the distance from the bottom */
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: '2', /* Set a value higher than the Box containing transactions */
                    backgroundColor: 'white', /* Set the background color if needed */
                    padding: '16px 0', /* Adjust padding as needed */
                    borderTop: `2px solid ${colorPalette.gray_300}`,
                  }}
                  justifyContent={'space-between'}
                  direction={'row'}
                  gap={2}
                >
                  <Pagination
                    count={totalPages}
                    variant='outlined'
                    size="medium"
                    page={page}
                    onChange={handlePageChange}
                    boundaryCount={1} // Shows the first and last page numbers
                    siblingCount={1} // Shows one page before and after the current page
                    color="primary"
                    style={{ display: "flex", justifyContent: "center" }}
                  />
                </Stack>
              </Stack>
              <Stack id="transaction-details"
                className='shadowStylePaper'
                style={{
                  borderRadius: '8px',
                  maxWidth: '780px',
                  background: colorPalette.white,
                  width: '100%', height: '100%',
                  overflowY: 'auto'
                }}>
                {activeTransaction && (
                  <TransactionDetails
                    transaction={activeTransaction}
                    transactionStatus={activeTransaction.transactionStatus}
                    isUpdated={isUpdated}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </>}
    </>
  );
};

export default TransactionsPage;
