import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    Stack,
    Card,
    CardContent,
    Button,
    Modal,
    TextField,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import colorPalette from '../../theme/colorPalette';
import PlaidAddAccount from './PlaidAddAccount';
import { Account } from '../../interfaces/account';
import { getAllAccounts } from '../../api-routers/AccountsRouter';

// Styling for the modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const PlaidAccounts = ({ editMode = false }) => {
    const navigate = useNavigate();
    const [cashAccounts, setCashAccounts] = useState<Account[]>([]);
    const [creditAccounts, setCreditAccounts] = useState<Account[]>([]);
    const [hasAccounts, setHasAccounts] = useState<'connected' | 'new' | 'disconnected'>('new');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentAccount, setCurrentAccount] = useState<Account | null>(null);
    const [deleteTransactions, setDeleteTransactions] = useState(false);
    // Function to fetch and update accounts
    const fetchAndUpdateAccounts = useCallback(async () => {
        try {
            const fetchedAccounts = await getAllAccounts();
            const cash = fetchedAccounts.filter((account: Account) => account.accountType === 'depository'); // Adjust the condition based on your account data structure
            const credit = fetchedAccounts.filter((account: Account) => account.accountType === 'credit'); // Adjust the condition
            setCashAccounts(cash);
            setCreditAccounts(credit);

            const isDisconnected = fetchedAccounts.some((account: Account) => account.status === 'disconnected');
            setHasAccounts(isDisconnected ? 'disconnected' : (cash.length > 0 || credit.length > 0) ? 'connected' : 'new');

        } catch (error) {
            console.error('Error fetching accounts:', error);
            // Handle error appropriately
        }
    }, []);

    // Use useEffect to call fetchAndUpdateAccounts when component mounts
    useEffect(() => {
        fetchAndUpdateAccounts();
    }, [fetchAndUpdateAccounts]);

    const renderAccounts = (accounts: Account[], title: string) => (
        <Stack gap={0.5}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Stack spacing={2} alignItems={'center'}>
                {accounts.map((account, index) => (
                    <>
                        <Card key={index} variant="outlined" sx={{ background: colorPalette.white, width: '100%' }}>
                            <CardContent>
                                <Stack direction={'column'} gap={1}>
                                    <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                        <Typography variant="body1" align="left">
                                            {account.accountName}
                                        </Typography>
                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                            <Typography variant="body2">
                                                {account.accountSubtype}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                        <Typography variant="body2" align="left">
                                            XXXX XXXX XXXX {account.mask}
                                        </Typography>
                                        <Typography variant="body1" align="right">
                                            {'$ '}{account.accountSubtype.startsWith('s') ? '3412.23' : '6734.44'}
                                        </Typography>
                                    </Stack>
                                    {renderAccountActions(account)}
                                </Stack>
                            </CardContent>

                        </Card>

                    </>
                ))}
            </Stack>
        </Stack>
    );

    const renderAccountActions = (account: Account) => editMode && (
        <Stack direction={'row'} spacing={1}>
            <Stack direction={'row'} spacing={1}>
                <Button size="small" variant="outlined" onClick={() => handleEditAccountOpen(account)}>Edit</Button>
                <Button size="small" variant="outlined" color="error" onClick={() => handleDeleteAccountOpen(account)}>Delete</Button>
            </Stack>
        </Stack>
    );

    const renderPlaceholder = () => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={'https://onedrive.live.com/embed?resid=1656BB0C87C6BA8%21254043&authkey=%21ADo42rNtpuSwWwE&width=801&height=801'} alt="Stylized graphic of a mobile phone with a credit card displayed on its screen. The credit card is embellished with a link chain icon, symbolizing a connection or integration" style={{ maxWidth: '240px', height: 'auto' }} />
            <Stack width={'100%'} alignItems={'center'}>
                {/* <Typography variant="h4">Start connecting</Typography> */}
            </Stack>
        </Box>
    );

    const handleEditAccountOpen = (account: Account) => {
        setCurrentAccount(account);
        setEditModalOpen(true);
    };

    const handleDeleteAccountOpen = (account: Account) => {
        setCurrentAccount(account);
        setDeleteModalOpen(true);
    };

    const handleEditAccountClose = () => {
        setEditModalOpen(false);
    };

    const handleDeleteAccountClose = () => {
        setDeleteModalOpen(false);
    };



    return (
        <Stack direction={'row'}>
            <Stack gap={4} my={1} width={'100%'} maxWidth={'480px'} sx={{ background: 'transparent' }}>
                {hasAccounts === 'connected' ? (
                    <Stack gap={1.5}>
                        {editMode && (
                            <Stack width={'100%'} direction='row' justifyContent={'space-between'}>
                                <Typography variant="body2">
                                    {cashAccounts && creditAccounts ? `${cashAccounts.length + creditAccounts.length} accounts found` : "No accounts found"}
                                </Typography>
                                <PlaidAddAccount state='connected' onSuccessProp={fetchAndUpdateAccounts} />
                            </Stack>
                        )}
                        {renderAccounts(cashAccounts, "Cash Accounts")}
                        {renderAccounts(creditAccounts, "Credit Accounts")}
                    </Stack>

                ) : renderPlaceholder()}

                {hasAccounts === 'new' && <Stack width={'100%'} alignItems={'center'}>
                    <PlaidAddAccount state='new' onSuccessProp={fetchAndUpdateAccounts} />
                </Stack>}
            </Stack>
            {editModalOpen && (
                <Modal
                    open={editModalOpen}
                    onClose={handleEditAccountOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Stack gap={2}>
                            <Stack gap={0.5}>
                                <Typography id="modal-modal-title" variant="h4">
                                    Edit Account
                                </Typography>
                                <Typography id="modal-modal-desc" variant="body2" color={colorPalette.gray_600}>
                                    Change your account display name. This name will appear with your transactions.
                                </Typography>
                            </Stack>
                            <TextField
                                fullWidth
                                label="Account Name"
                                defaultValue={currentAccount?.accountName}
                                margin="normal"
                            />
                            <Stack direction='row' gap={0.5} justifyContent={'center'}>
                                {/* Other non-editable fields */}
                                <Button variant='contained' size='large' onClick={() => {/* Handle save */ }}>Save</Button>
                                <Button variant='text' size='large' onClick={handleEditAccountClose}>Cancel</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Modal>
            )}
            {deleteModalOpen && (
                <Modal
                    open={deleteModalOpen}
                    onClose={handleDeleteAccountClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Stack gap={2}>
                            <Typography id="modal-modal-title" variant="h4" >
                                Are you sure you want to delete this account?
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox checked={deleteTransactions} onChange={(e) => setDeleteTransactions(e.target.checked)} />}
                                label={
                                    <Typography variant="body2">
                                        Delete all transactions associated with this account
                                    </Typography>
                                }
                            />

                            <Stack direction="row" spacing={1} justifyContent="center">
                                <Button variant='contained' size='large' onClick={() => {/* Handle delete */ }} color="error">
                                    Yes, Delete
                                </Button>
                                <Button variant='text' size='large' onClick={handleDeleteAccountClose}>Cancel</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Modal>

            )}
        </Stack>
    );
}

export default PlaidAccounts;
